import Router from "./routes";
import ThemeProvider from "./theme";
import "./App.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { resetState, setSSOLogout } from "./redux/Login/LoginAction";
import { useDispatch, useSelector } from "react-redux";
import { showToastMessage } from "./redux/Toaster/ToasterAction";
import { getLogout } from "./redux/Login/LoginAction";
import { persistor } from "./app/store";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    const loginUrl = window.location.href;
    const regex = loginUrl
      ? /\/login/
      : userData?.userType !== "student"
      ? /\/lessons\/(\d+)\/scenes\//
      : "";
    const match = window.location.href.match(regex);

    if (match?.length > 0 && match[0] === "/login") {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    startSessionTimer();
  }, []);

  let logoutTimer;
  const sessionTimeout = 1 * 60 * 60 * 1000; // 1 hour in milliseconds = 1 * 60 * 60 * 1000; 10 sec for testing= 10 * 1000;

  function startSessionTimer() {
    if (isLogin) {
      return;
    }
    logoutTimer = setTimeout(handleLogout, sessionTimeout);
  }

  function resetSessionTimer() {
    clearTimeout(logoutTimer);
    startSessionTimer();
  }

  function handleLogout() {
    if (userData?.roster_name === "edlink") {
      dispatch(setSSOLogout(true));
    }
    clearTimeout(logoutTimer);
    localStorage.removeItem("AuthToken");
    persistor.purge();
    dispatch(resetState());
    dispatch(getLogout()).then(async (res) => {
      navigate("/login", { replace: true });
      if (res?.statusCode === 200) {
        dispatch(
          showToastMessage(403, `Session Expired or Something went wrong.`)
        );
      }
    });
  }

  document.addEventListener("mousemove", resetSessionTimer);
  document.addEventListener("keydown", resetSessionTimer);

  return (
    <div>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </div>
  );
}

export default App;
