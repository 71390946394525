import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/addEditTeacher.css";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import "../../styles/components/Account.css";
import "./../../styles/main.css";
import { saveAccountDetails } from "../../redux/Account/AccountAction";
import { getEducatorData } from "../../redux/Login/LoginAction";
import { logoutUser } from "../../utils/authUtils";

const UpdateAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [schoolState, setSchoolState] = useState("");
  const [zip, setZip] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isEditChange, setIsEditChange] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState({});
  const userData = useSelector((state) => state.loginReducer?.userData);
  const accountData = useSelector((state) => state.accountReducer?.accountData);

  useEffect(() => {
    setSchoolName(accountData?.schoolData?.name);
    setStreetAddress(accountData?.schoolData?.address);
    setCity(accountData?.schoolData?.city);
    setSchoolState(accountData?.schoolData?.state);
    setZip(accountData?.schoolData?.zip);
    setPhoneNumber(accountData?.schoolData?.phone);
    setLoading(false);
    if (accountData?.roster_name === "edlink") {
      setIsDisabled(true);
    }
  }, []);

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [
      schoolName,
      streetAddress,
      city,
      schoolState,
      zip,
      phoneNumber,
    ];
    requireFields = [
      "schoolName",
      "streetAddress",
      "city",
      "schoolState",
      "zip",
      "phoneNumber",
    ];

    requireValues.forEach((field, index) => {
      if (
        requireFields[index] !== "zip" &&
        requireFields[index] !== "phoneNumber" &&
        !field.trim() &&
        requireFields[index] == "schoolName"
      ) {
        errors[requireFields[index]] = "This field cannot be blank";
      }

      if (
        !(field !== null && field.length !== 0) &&
        requireFields[index] == "schoolName"
      ) {
        errors[requireFields[index]] = "This field is required";
      }

      if (requireFields[index] == "schoolName" && field.length > 150) {
        errors["schoolName"] =
          "School Name  is too long (maximum is 150 characters)";
      }

      if (requireFields[index] == "streetAddress" && field.length > 90) {
        errors["streetAddress"] =
          "Street Address  is too long (maximum is 90 characters)";
      }

      if (requireFields[index] == "city" && field.length > 30) {
        errors["city"] = "City  is too long (maximum is 30 characters)";
      }

      if (requireFields[index] == "schoolState" && field.length > 30) {
        errors["schoolState"] = "State  is too long (maximum is 30 characters)";
      }

      if (requireFields[index] == "zip" && field.length > 45) {
        errors["zip"] = "Zip  is too long (maximum is 45 characters)";
      }

      if (requireFields[index] == "phoneNumber" && field.length > 45) {
        errors["phoneNumber"] = "Phone Number  is too long (maximum is 45 characters)";
      }

      const alphanumericRegex = /[^a-zA-Z ]/;

      if (alphanumericRegex.test(city)) {
        errors["city"] = "Please enter alphabets only";
      }

      if (alphanumericRegex.test(schoolState)) {
        errors["schoolState"] = "Please enter alphabets only";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsEditChange(true);
    var data =
      name === "schoolName"
        ? setSchoolName(value)
        : name === "streetAddress"
        ? setStreetAddress(value)
        : name === "city"
        ? setCity(value)
        : name === "schoolState"
        ? setSchoolState(value)
        : "";
  };

  const handleChangePhone = (event) => {
    setIsEditChange(true);
    
    var phoneVal=(event.target.value);
    const regex = /^[0-9-().x+ ]+$/;
    if (phoneVal.length > 0  && regex.test(phoneVal)) {
      
        setPhoneNumber(phoneVal);
    } else if (phoneVal == "" && phoneNumber.length == 1) {
      setPhoneNumber("");
    }  
    else {
      setPhoneNumber(phoneNumber);
    }
  };


  const handleChangeZip = (event) => {
    setIsEditChange(true)
    var zipVal=(event.target.value);
    const regex = /^[0-9\b]+$/;
    if (zipVal > 0 && regex.test(zipVal)) {
        setZip(zipVal);
    } else if (zipVal == "" && zip.length == 1) {
      setZip("");
    }  
    else {
      setZip(zip);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!isEditChange) {
      dispatch(showToastMessage(201, "No fields have been changed."));
      return;
    }
    if (validateForm()) return;

    setLoading(true);

    var dataModel = {
      schoolName,
      streetAddress,
      city,
      schoolState,
      zip,
      phoneNumber,
    };
    dispatch(saveAccountDetails(dataModel, userData?.school_id)).then(
      async (res) => {
        if (res?.statusCode === 200) {  
          dispatch(showToastMessage(res?.statusCode, res?.message));
          if (userData?.userType === "school_admin"){
            dispatch(getEducatorData()).then((res) => {
              navigate(`/dashboard/schools`);              
            });
          }
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        } else {
          dispatch(showToastMessage(res?.statusCode, res?.message));
        }

        // setSchoolName("");
        // setStreetAddress("");
        // setCity(null);
        // setSchoolState("");
        // setZip("");
        // setPhoneNumber(true);
        setLoading(false);
      }
    );
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading">Update Account</div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/schools">Account</Link> {" > "}
        Update Account
      </div>

      <form className="form " onSubmit={handleFormSubmit}>
        <div className="schoolDetails mr-2">
          <div className="schoolDetailsHeading"> School Details</div>
          <div className="inputBox f1 mb-2">
            <label className="inputLabel">
              Name <span className="labelReqField">*</span>
            </label>

            <input
              type="text"
              name="schoolName"
              className={`input inputWidth mb0 ${
                errors.schoolName ? "errorInput" : ""
              }`}
              id="schoolName"
              placeholder="Name"
              value={schoolName}
              onChange={handleChange}
              disabled={isDisabled}
            ></input>
            {errors.schoolName && (
              <div className="error">{errors.schoolName}</div>
            )}
          </div>

          <div className="schoolDetailsHeading"> Address</div>
          <div className="inputBox f1 mb-1">
            <label className="inputLabel">Street Address</label>

            <input
              type="text"
              name="streetAddress"
              className={`input inputWidth mb0 ${
                errors.streetAddress ? "errorInput" : ""
              }`}
              id="streetAddress"
              placeholder="Street Address"
              value={streetAddress}
              onChange={handleChange}
              disabled={isDisabled}
            ></input>
            {errors.streetAddress && (
              <div className="error">{errors.streetAddress}</div>
            )}
          </div>

          <div className="detailSection m0">
            <div className="textInputResponsive" style={{ display: "flex" }}>
              <div className="inputBox f1">
                <label className="inputLabel">City</label>
                <input
                  type="text"
                  name="city"
                  className={`input ${errors.city ? "errorInput" : ""}`}
                  id="city"
                  placeholder="City"
                  value={city}
                  onChange={handleChange}
                  disabled={isDisabled}
                ></input>
                {errors.city && <div className="error">{errors.city}</div>}
              </div>
              <div className="inputBox f1">
                <label className="inputLabel">State</label>
                <input
                  type="text"
                  name="schoolState"
                  className={`input ${errors.schoolState ? "errorInput" : ""}`}
                  id="schoolState"
                  placeholder="State"
                  value={schoolState}
                  onChange={handleChange}
                  disabled={isDisabled}
                ></input>
                {errors.schoolState && (
                  <div className="error">{errors.schoolState}</div>
                )}
              </div>
            </div>

            <div className="textInputResponsive" style={{ display: "flex" }}>
              <div className="inputBox f1">
                <label className="inputLabel">Zip</label>
                <input
                  type="text"
                  // min="0"
                  // step="1"
                  name="zip"
                  className={`input ${errors.zip ? "errorInput" : ""}`}
                  id="zip"
                  placeholder="Zip"
                  value={zip}
                  onChange={handleChangeZip}
                  disabled={isDisabled}
                ></input>
                {errors.zip && <div className="error">{errors.zip}</div>}
              </div>
              <div className="inputBox f1">
                <label className="inputLabel">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className={`input ${errors.phoneNumber ? "errorInput" : ""}`}
                  id="phoneNumber"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={handleChangePhone}
                  disabled={isDisabled}
                ></input>
                {errors.phoneNumber && (
                  <div className="error">{errors.phoneNumber}</div>
                )}
              </div>
            </div>

            <Button
              className="btn gradientButtonBlue ml-1 mt-1 mb-2"
              type="submit"
              disabled={isDisabled}
            >
              Save Account
            </Button>
          </div>
        </div>
      </form>

      <CustomToastify />
    </Card>
  );
};

export default UpdateAccount;
