import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setIsEducator,
  getTokenStatus,
  setChangePassword,
  setParentChangePassword
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";

import { Card } from "@material-ui/core";
import { logoutUser } from "../../utils/authUtils";
export default function ResetPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  // const [isEducator, setIsEducator] = React.useState(false);
  const [openForgotPassDialog, setOpenForgotPassDialog] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [confirmTouched, setConfirmTouched] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [token, setToken] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errors, setErrors] = React.useState({});
  const [responseCode, setResponseCode] = React.useState("");
  // const [cookies, setCookie, removeCookie] = useCookies([]);
  const userData = useSelector((state) => state.loginReducer?.userData);


  const isPasswordValid = (password) => {
    // const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return password.length > 7;
    //  && passwordRegex.test(password);
  };

  var passwordErrorText =
    password.length > 0 && touched && !isPasswordValid(password)
      ? "New Password must be minimum 8 characters long."
      : "";

  const isConfirmPasswordValid = (confirmPassword) => {
    // const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return confirmPassword.length > 7;
    //  && passwordRegex.test(password);
  };

  var confirmPasswordErrorText =
    confirmPassword.length > 0 && confirmTouched
      ? !isConfirmPasswordValid(confirmPassword)
        ? "Confirm Password must be minimum 8 characters long."
        : password === confirmPassword
          ? ""
          : "New Password & Confirm Password do not match."
      : "";

  // var passwordErrorText = touched && !isPasswordValid(password)

  // ? 'Password must be minimum 8 characters long and contain only alphanumeric characters.'

  // : password === confirmPassword ? '' : "Password & Confirm Password do not match.";

  const handleResetPassword = (event) => {
    event.preventDefault();

    if (validateForm()) return;

    // if ((passwordErrorText.length > 0 && confirmPasswordErrorText.length > 0)) {
    //     return;
    // }

    // if (!(password.length > 0 && confirmPassword.length > 0)) {
    //     dispatch(showToastMessage(201, "Enter password in both the fields."));

    //     return;
    // }

    setIsLoading(true);
    setResponseCode("");

    var dataModel = {
      oldPassword,
      password,
      confirmPassword,
      email: userData?.email,
    };
    if (userData?.userType === "parent") {
      dispatch(setParentChangePassword(dataModel)).then(async (res) => {
        setIsLoading(false);
        if (res?.statusCode == 204) {
          let errors = {};
          errors["oldPassword"] = "Current Password is not correct.";
          errors["confirmPassword"] = "";
          errors["password"] = "";
          setErrors(errors);
        }
        else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
        else {
          dispatch(showToastMessage(res?.statusCode, res?.message));
          setPassword("");
          setConfirmPassword("");
          setOldPassword("");
        }
      });
    } else {
      dispatch(setChangePassword(dataModel)).then(async (res) => {
        setIsLoading(false);
        if (res?.statusCode == 204) {
          let errors = {};
          errors["oldPassword"] = "Current Password is not correct.";
          errors["confirmPassword"] = "";
          errors["password"] = "";
          setErrors(errors);
        }
        else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
        else {
          dispatch(showToastMessage(res?.statusCode, res?.message));
          setPassword("");
          setConfirmPassword("");
          setOldPassword("");
        }
      });
    }
  };

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [oldPassword, password, confirmPassword];
    requireFields = ["oldPassword", "password", "confirmPassword"];

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }

      if (
        oldPassword?.length !== 0 &&
        oldPassword !== null &&
        !(oldPassword?.length > 7)
      ) {
        errors["oldPassword"] =
          "Current Password must be minimum 8 characters long.";
      }

      if (
        password?.length !== 0 &&
        password !== null &&
        !(password?.length > 7)
      ) {
        errors["password"] = "New Password must be minimum 8 characters long.";
      }
      if (
        confirmPassword?.length !== 0 &&
        confirmPassword !== null &&
        !(confirmPassword?.length > 7)
      ) {
        errors["confirmPassword"] =
          "Confirm Password must be minimum 8 characters long.";
      }
      if (
        password?.length > 7 &&
        confirmPassword?.length > 7 &&
        password !== confirmPassword
      ) {
        errors["confirmPassword"] =
          "New Password & Confirm Password do not match.";
        errors["password"] = "";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  return (
    <>
      <Card>
        {isLoading && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div className="pageHeading"> Change Password</div>

        <div className="pageBreadCrumb">
          {userData?.userType !== "parent" ? <Link to="/dashboard/home">Home</Link> :
            <Link to="/dashboard/lesson_groups">Lessons</Link>} {" > "}
          Change Password
        </div>

        <div className="changePasswordMain">
          <div className="inputLabel">
            Current Password<span className="labelReqField">*</span>
          </div>
          <div className="textFeildWidth">
            <TextField
              // fullWidth
              sx={{
                m: 1,
                "& input": {
                  fontFamily: 'Raleway !important'
                },
              }}
              // required
              id="outlined-required-1"
              placeholder="Current Password"
              size="small"
              value={oldPassword}
              className="mb-0"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              onBlur={() => setTouched(true)}
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      edge="end"
                    >
                      {showOldPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.oldPassword && (
              <div className="error mb-0">{errors.oldPassword}</div>
            )}
          </div>

          <div className="inputLabel">
            New Password<span className="labelReqField">*</span>
          </div>
          <div className="textFeildWidth">
            <TextField
              // fullWidth
              sx={{
                m: 1,
                "& input": {
                  fontFamily: 'Raleway !important'
                },
              }}
              // required
              id="outlined-required-2"
              placeholder="New Password"
              size="small"
              value={password}
              className="mb-0"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onBlur={() => setTouched(true)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <div className="error mb-0">{errors.password}</div>
            )}
          </div>

          <div className="inputLabel">
            Confirm Password<span className="labelReqField">*</span>
          </div>
          <div className="textFeildWidth">
            <TextField
              halfWidth
              sx={{
                m: 1,
                "& input": {
                  fontFamily: 'Raleway !important'
                },
              }}
              // required
              className="inputPadding mb-0"
              id="outlined-required-3"
              placeholder="Confirm Password"
              size="small"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              onBlur={() => setConfirmTouched(true)}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.confirmPassword && (
              <div className="error mb-0">{errors.confirmPassword}</div>
            )}
          </div>

          <div className="itemSpace mr-1">
            <Button
              className="btn gradientButtonBlue"
              onClick={handleResetPassword}
              style={{ width: "fit-content" }}
            >
              Change Password
            </Button>
          </div>
        </div>
      </Card>
      <CustomToastify />
    </>
  );
}
