import React, { useState } from "react";
import navConfig from "./config";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "../../styles/layouts/navbar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setNavbar } from "../../redux/Header/HeaderAction";
import Tooltip from "@material-ui/core/Tooltip";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { setContentFilter } from "../../redux/HelpHub/HelpHubAction";
import {
  SUPPORT_EMAIL,
  SUPPORT_MSG_FOR_STUDENT,
  SUPPORT_MSG_FOR_TEACHER_LINE_1,
  SUPPORT_MSG_FOR_TEACHER_LINE_2,
  USER_ROLE_STUDENT,
} from "../../utils/config";
const Navbar = () => {
  const dispatch = useDispatch();

  const [nav, setNav] = useState({});
  const navState = useSelector((state) => state.headerReducer?.setNavbarState);
  const userData = useSelector((state) => state.loginReducer?.userData);
  const openNavbar = () => {
    dispatch(setNavbar(!navState));
  };
  const handleChangeNavbar = (title) => {
    setNav({ [title]: !nav[title] });
  };
  return (
    <>
      <nav
        className="navbar-container"
        style={{
          minWidth: userData?.userType === "student" && navState && "15rem",
        }}
      >
        <ul
          className="navbar-list"
          style={{
            minWidth: userData?.userType === "student" && navState && "15rem",
          }}
        >
          <div className="navbar-icon">
            <Tooltip title={"Menu"}>
              <FontAwesomeIcon icon={faBars} onClick={openNavbar} />
            </Tooltip>
          </div>

          {navConfig.map((item, index) => {
            return (
              item.role.includes(userData?.userType) &&
              (!item.subMenu ? (
                <NavLink key={index} to={item.path} activeclassname="active">
                  <div
                    className="navbar-row"
                    style={{
                      backgroundColor: item.bgColor,
                    }}
                    onClick={() => {
                      item.title == "Help Hub" &&
                        dispatch(setContentFilter(null));
                    }}
                  >
                    <div className="navbar-icon">
                      <Tooltip title={item.title}>
                        <FontAwesomeIcon icon={item.icon} />
                      </Tooltip>
                    </div>
                    {navState && (
                      <li id={item.title} className="navbar-item">
                        {item.title}
                      </li>
                    )}
                  </div>
                </NavLink>
              ) : (
                <React.Fragment key={index}>
                  <div
                    className="navbar-row"
                    style={{
                      backgroundColor: item.bgColor,
                    }}
                  >
                    <div className="navbar-icon">
                      <Tooltip title={item.title}>
                        <FontAwesomeIcon icon={item.icon} />
                      </Tooltip>
                    </div>
                    {navState && (
                      <li
                        style={{ backgroundColor: item.bgColor }}
                        className={`navbar-item ${
                          nav[item.title] ? "active" : ""
                        }`}
                        onClick={handleChangeNavbar(item?.title)}
                      >
                        {item.title}
                        {nav[item.title] ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </li>
                    )}
                  </div>
                  {nav[item.title] &&
                    item?.subMenu?.map((subItem, subIndex) => {
                      return (
                        <NavLink
                          key={`${index}-${subIndex}`}
                          to={subItem.path}
                          activeclassname="active"
                        >
                          <div
                            className="navbar-row"
                            style={{
                              backgroundColor: item.bgColor,
                            }}
                          >
                            <div className="navbar-icon sub-item">
                              <Tooltip title={subItem.title}>
                                <FontAwesomeIcon icon={item.icon} />
                              </Tooltip>
                            </div>

                            <li
                              id={subItem.title}
                              className={`navbar-item navSubItem`}
                            >
                              {subItem.title}
                            </li>
                          </div>
                        </NavLink>
                      );
                    })}
                </React.Fragment>
              ))
            );
          })}
        </ul>

        <div className="navbar-support-container">
          <div
            className={`navbar-support-text ${navState ? "visible" : "hidden"}`}
          >
            {userData?.userType === USER_ROLE_STUDENT.toLowerCase()
              ? SUPPORT_MSG_FOR_STUDENT
              : SUPPORT_MSG_FOR_TEACHER_LINE_1}
            {userData?.userType !== USER_ROLE_STUDENT.toLowerCase() && (
              <a
                className="support-email"
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_newtab"
              >
                {`${SUPPORT_EMAIL}. `}
              </a>
            )}
            <br />
            {userData?.userType !== USER_ROLE_STUDENT.toLowerCase() &&
              SUPPORT_MSG_FOR_TEACHER_LINE_2}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
