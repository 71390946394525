import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_TEACHER_DATA = "GET_TEACHER_DATA";
export const SAVE_UPDATE_TEACHER_DATA = "SAVE_UPDATE_TEACHER_DATA";
export const DELETE_TEACHER_DATA = "DELETE_TEACHER_DATA";
export const GET_TEACHER_BYID_DATA = "GET_TEACHER_BYID_DATA";
export const SAVE_ACTIVE_INACTIVE_TEACHER = "SAVE_ACTIVE_INACTIVE_TEACHER"


export const getTeacherData = (schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/teachers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getTeacherDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getTeacherDataById = (schoolId,teacherId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/teachers/${teacherId}/getTeacher`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getTeacherDataByIdApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};


export const saveUpdateTeacherData = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      classes: data.right,
      email: data.email,
      firstname: data.firstName,
      lastname: data.lastName,
      oldEmail: data.oldEmail,
      password: data.password,
      welcomeEmail: data.sendWelcomeEmail,
      schoolId: parseInt(data.schoolId)
    };
    axios
      .post(`${BASE_URL}/api/v1/users/saveOrUpdateUser`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(saveUpdateApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};


export const deleteTeacherData = (schoolId, teacherId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/teachers/${teacherId}/deleteTeacher`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(deleteTeacherDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};



export const activeInactiveTeacher = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      operationLevel: data.operationLevel,
      isActive: data.isActive,
      operationId: data.operationId,
    };
    axios
      .put(`${BASE_URL}/api/v1/users/updateUserstatus`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(activeInactiveTeacherApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};


export const activeInactiveTeacherApiSuccess = (value) => {
  return {
    type: SAVE_ACTIVE_INACTIVE_TEACHER,
    data: value,
  };
};


export const getTeacherDataApiSuccess = (value) => {
  return {
    type: GET_TEACHER_DATA,
    data: value,
  };
};

export const getTeacherDataByIdApiSuccess = (value) => {
  return {
    type: GET_TEACHER_BYID_DATA,
    data: value,
  };
};

const deleteTeacherDataApiSuccess = (value) => {
  return {
    type: DELETE_TEACHER_DATA,
    data: value,
  };
};


const saveUpdateApiSuccess = (value) => {
  return {
    type: SAVE_UPDATE_TEACHER_DATA,
    data: value,
  };
};

