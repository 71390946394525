import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_LESSON_DATA = "GET_LESSON_DATA ";
export const SET_STUDENT_PLAY = "SET_STUDENT_PLAY ";



export const getStudentLessonsData = (schoolId, studentID) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios(
            `${BASE_URL}/api/v1/students/schools/${schoolId}/${studentID}/episodesList`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
                },
            }
        )
            .then((res) => {
                dispatch(getStudentLessonsDataApiSuccess(res.data));
                resolve(res.data);
                return res.data;
            })
            .catch((err) => {
                resolve(err.response.data);
                reject(err.response.data);
            });
    });
};


export const getStudentLessonsDataApiSuccess = (value) => {
    return {
        type: GET_LESSON_DATA,
        data: value,
    };
};

export function setStudentPlay(value) {
    return {
      type: SET_STUDENT_PLAY,
      data: value,
    };
  }