import Box from "@mui/material/Box";
import { COPYRIGHT_MSG } from "../../utils/config";
import { useSelector } from "react-redux";

const Footer = () => {
  const navState = useSelector((state) => state.headerReducer?.setNavbarState);

  return (
    <Box
      className={`footer-container ${
        navState ? "footer-open" : "footer-closed"
      }`}
    >
      <div className="footer-content">{COPYRIGHT_MSG}</div>
    </Box>
  );
};

export default Footer;
