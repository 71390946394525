import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const IS_LOGIN = "IS_LOGIN";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";
export const GET_USER_DATA = "GET_USER_DATA";
export const SET_WELCOME_PAGE_DATA = "SET_WELCOME_PAGE_DATA";
export const EMAIL_FORGOT_PASSWORD = "EMAIL_FORGOT_PASSWORD";
export const SET_IS_EDUCATOR = "SET_IS_EDUCATOR";
export const TOKEN_STATUS_FOR_RESET_PASSWORD =
  "TOKEN_STATUS_FOR_RESET_PASSWORD";
export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD";
export const SIGN_IN_WITH_GOOGLE = "SIGN_IN_WITH_GOOGLE";
export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";
export const GET_LOGOUT_SUCCESS = "GET_LOGOUT_SUCCESS";
export const GET_SSO_LOGIN = "GET_SSO_LOGIN";
export const SET_SSO_LOGOUT = "SET_SSO_LOGOUT";
export const SET_SIGN_UP = "SET_SIGN_UP";
export const GET_TOKEN_VERIFICATION = "GET_TOKEN_VERIFICATION";
export const GET_PARENT_VALIDATION = "GET_PARENT_VALIDATION";
export const GET_STUDENT_DROPDOWN = "GET_STUDENT_DROPDOWN";
export const GET_STUDENT_VALIDATION = "GET_STUDENT_VALIDATION";
export const SET_ADD_CHILD = "SET_ADD_CHILD";
export const SET_PARENT_CHANGE_PASSWORD = "SET_PARENT_CHANGE_PASSWORD";
export const SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT";
export const RESET_STATE = "RESET_STATE";

export const getStudentLogin = (schoolCode, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var loginModel = {
      code: schoolCode,
      id: studentId,
    };
    axios
      .post(`${BASE_URL}/api/v1/students/login`, loginModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const AuthToken = res.data.token;
        localStorage.setItem("AuthToken", AuthToken);
        dispatch(getLoginSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        dispatch(getLoginFailure(err));
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};
export const getEducatorLogin = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var loginModel = {
      email: email,
      password: password,
    };
    axios
      .post(`${BASE_URL}/api/v1/users/login`, loginModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const AuthToken = res.data.token;
        localStorage.setItem("AuthToken", AuthToken);
        dispatch(getLoginSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        dispatch(getLoginFailure(err));
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentData = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/students/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getUserDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getEducatorData = (id, role) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getUserDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setWelcomePage = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var data = [];
    axios
      .put(
        `${BASE_URL}/api/v1/users/users/${userId}/updateWelcomeScreenFlag`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(getSetWelcomePageApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const sendEmailForgotPassword = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      email: email,
    };
    axios
      .post(`${BASE_URL}/api/v1/users/forgot-password`, dataModel, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        // },
      })
      .then((res) => {
        dispatch(sendEmailForgotApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};
export const sendParentEmailForgotPassword = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      email: email,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/forgot-password`, dataModel, {
      })
      .then((res) => {
        dispatch(sendEmailForgotApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getTokenStatus = (resetpasswordtoken) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/checktokenstatus/${resetpasswordtoken}`, {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    })
      .then((res) => {
        dispatch(getTokenStatusApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getParentTokenStatus = (resetpasswordtoken) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/parent/checktokenstatus/${resetpasswordtoken}`, {
      method: "GET",
    })
      .then((res) => {
        dispatch(getTokenStatusApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setResetPassword = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      confirmpassword: data.confirmPassword,
      password: data.password,
      resetPasswordToken: data.token,
    };
    axios
      .post(`${BASE_URL}/api/v1/users/resetpassword`, dataModel, {
        // headers: {
        //   "Content-Type": "application/json",
        // },
      })
      .then((res) => {
        dispatch(setResetPasswordApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setParentResetPassword = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      confirmpassword: data.confirmPassword,
      password: data.password,
      resetPasswordToken: data.token,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/resetPassword`, dataModel, {
      })
      .then((res) => {
        dispatch(setResetPasswordApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};


export const signInWithGoogle = (idToken) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${BASE_URL}/api/v1/users/educator/signInWithGoogle?idToken=${idToken}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const AuthToken = res.data.token;
        localStorage.setItem("AuthToken", AuthToken);
        dispatch(signInWithGoogleApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setParentChangePassword = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      confirmpassword: data.confirmPassword,
      email: data.email,
      oldPassword: data.oldPassword,
      password: data.password,
      resetPasswordToken: "",
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/changePassword`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setParentChangePasswordSuccess(res.data));
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setChangePassword = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      confirmpassword: data.confirmPassword,
      email: data.email,
      oldPassword: data.oldPassword,
      password: data.password,
      resetPasswordToken: "",
    };
    axios
      .post(`${BASE_URL}/api/v1/users/changePassword`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          // "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(setChangePasswordApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getLogout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/logout`, {
      method: "GET",

      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getLogoutSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getSSOLogin = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      code: data.code,
      // state: data.state,
    };
    axios
      .get(`${BASE_URL}/login/SSO?appCode=${data.appCode}&code=${data.code}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const AuthToken = res.data.token;
        localStorage.setItem("AuthToken", AuthToken);
        dispatch(getSSOLoginSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setSignUp = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      confirmPassword: data.confirmPassword,
      email: data.email,
      first_name: data.fName,
      last_name: data.lName,
      password: data.password,
      school_code: data.schoolCode,
      student_id: data.studentId,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/SignUp`, dataModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(setSignUpSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getParentValidation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      confirmPassword: data.confirmPassword,
      email: data.email,
      first_name: data.fName,
      last_name: data.lName,
      password: data.password,
      school_code: data.schoolCode,
      student_id: data.studentId,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/parentStudentValidation`, dataModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(getParentValidationSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getSignUpTokenVerification = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = { verificationToken: data };
    axios
      .post(`${BASE_URL}/api/v1/parent/activateParent`, dataModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(getTokenVerificationSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getParentLogin = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var loginModel = {
      email: email,
      password: password,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/login`, loginModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const AuthToken = res.data.token;
        localStorage.setItem("AuthToken", AuthToken);
        dispatch(getLoginSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getParentData = (id, role) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/parent/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getUserDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentDropdown = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/parent/${userId}/dropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getStudentDropdownApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setAddChild = (data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      code: data.schoolCode,
      id: data.studentId,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/parent_id/${id}/addStudent`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setAddChildSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentValidation = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      code: data.schoolCode,
      id: data.studentId,
    };
    axios
      .post(`${BASE_URL}/api/v1/parent/addStudentValidation
      `, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(getStudentValidationSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

const getLogoutSuccess = (token) => {
  return {
    type: GET_LOGOUT_SUCCESS,
    loading: false,
    token: token,
  };
};

export const signInWithGoogleApiSuccess = (value) => {
  return {
    type: SIGN_IN_WITH_GOOGLE,
    data: value,
  };
};

const getLoginSuccess = (token) => {
  return {
    type: GET_LOGIN_SUCCESS,
    loading: false,
    token: token,
  };
};
const getLoginFailure = (error) => {
  return {
    type: GET_LOGIN_FAILURE,
    error: error,
    loading: false,
  };
};

export const getUserDataApiSuccess = (value) => {
  return {
    type: GET_USER_DATA,
    data: value,
  };
};

export function isLogin(value) {
  return {
    type: IS_LOGIN,
    data: value,
  };
}
export const getSetWelcomePageApiSuccess = (value) => {
  return {
    type: SET_WELCOME_PAGE_DATA,
    data: value,
  };
};
export const sendEmailForgotApiSuccess = (value) => {
  return {
    type: EMAIL_FORGOT_PASSWORD,
    data: value,
  };
};

export function setIsEducator(value) {
  return {
    type: SET_IS_EDUCATOR,
    data: value,
  };
}

export const getTokenStatusApiSuccess = (value) => {
  return {
    type: TOKEN_STATUS_FOR_RESET_PASSWORD,
    data: value,
  };
};

export const setResetPasswordApiSuccess = (value) => {
  return {
    type: SET_RESET_PASSWORD,
    data: value,
  };
};

export const setChangePasswordApiSuccess = (value) => {
  return {
    type: SET_CHANGE_PASSWORD,
    data: value,
  };
};

export const getSSOLoginSuccess = (value) => {
  return {
    type: GET_SSO_LOGIN,
    data: value,
  };
};

export const setSSOLogout = (value) => {
  return {
    type: SET_SSO_LOGOUT,
    data: value,
  };
};

export const setSignUpSuccess = (value) => {
  return {
    type: SET_SIGN_UP,
    data: value,
  };
};

export const getTokenVerificationSuccess = (value) => {
  return {
    type: GET_TOKEN_VERIFICATION,
    data: value,
  };
};

export const getParentValidationSuccess = (value) => {
  return {
    type: GET_PARENT_VALIDATION,
    data: value,
  };
};

export const getStudentDropdownApiSuccess = (value) => {
  return {
    type: GET_STUDENT_DROPDOWN,
    data: value,
  };
};

export const setAddChildSuccess = (value) => {
  return {
    type: SET_ADD_CHILD,
    data: value,
  };
};

export const getStudentValidationSuccess = (value) => {
  return {
    type: GET_STUDENT_VALIDATION,
    data: value,
  };
};

export const setParentChangePasswordSuccess = (value) => {
  return {
    type: "SET_PARENT_CHANGE_PASSWORD",
    data: value,
  };
};

export const setSelectedStudent = (value) => {
  return {
    type: SET_SELECTED_STUDENT,
    data: value,
  };
};

export const resetState = (value) => {
  return {
    type: RESET_STATE,
  };
};
