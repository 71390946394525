import React from 'react';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


const DropdownComponent = ({ value, handleChange, options, page, name }) => {
  return (
    <FormControl
      variant="standard"
      sx={{ m: 1, minWidth: 120 }}
      className="centerAlign m0"
    >
      <Select
        name={name}
        value={value}
        displayEmpty
        className="select-dropdown"
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '20rem', width: "auto", // Ensure dropdown options popup width is the same as the dropdown
              maxWidth: "100%",
            }
          }
        }}
      >
        {options?.length > 0 ? (
          options?.map((student) => (
            <MenuItem key={page == "enroll" ? student?.studentId : student?.id} className="dropdownOptions" value={page == "enroll" ? student?.studentId : student?.id}>
              {page == "enroll" ? student?.studentName : student?.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No option to choose</MenuItem>
        )}
      </Select>
    </FormControl>

  );
};

export default DropdownComponent;