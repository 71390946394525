import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/addEditTeacher.css";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import "../../styles/components/Account.css";
import "./../../styles/main.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  purchaseLicense,
  getLicenseData,
} from "../../redux/Account/AccountAction";
import moment from "moment";
import { logoutUser } from "../../utils/authUtils";

const PurchaseLicenses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [licenseNumber, setLicenseNumber] = useState("");
  const [errors, setErrors] = React.useState({});
  const [licenseData, setLicenseDetailsData] = useState([]);

  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    dispatch(getLicenseData(userData?.school_id)).then(async (res) => {
      if (res?.statusCode === 200) {
        setLicenseDetailsData(res?.orderList);
        setLoading(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });
  }, []);

  const handleChange = (event) => {
    var licVal = event.target.value.trim();
    if (licVal > 0) {
      setLicenseNumber(licVal);
    } else if (licVal == "" && licenseNumber.length == 1) {
      setLicenseNumber("");
    } else {
      setLicenseNumber(licenseNumber);
    }
  };

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [licenseNumber];
    requireFields = ["licenseNumber"];

    requireValues.forEach((field, index) => {
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      if (field.length > 10) {
        errors[requireFields[index]] =
          "License Number is too long (maximum is 10 digits)";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleOrderClick = (event) => {
    event.preventDefault();

    if (validateForm()) return;

    setLoading(true);

    if (validateForm()) return;

    dispatch(
      purchaseLicense(licenseNumber, userData?.school_id, userData?.id)
    ).then(async (res) => {
      setLoading(false);
      if (res?.statusCode === 200) {
        dispatch(showToastMessage(res?.statusCode, res?.message));
        dispatch(getLicenseData(userData?.school_id)).then(async (res) => {
          if (res?.statusCode === 200) {
            setLicenseDetailsData(res?.orderList);
            setLoading(false);
          }
        });
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      } else {
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }

      setLicenseNumber("");
    });
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading">Licenses</div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/schools">Account</Link> {" > "}
        Licenses
      </div>

      <div className="licensceMain">
        <div className="inputBox f1 ">
          <label className="inputLabel">
            Enter no. of additional licenses{" "}
            <span className="labelReqField">*</span>
          </label>
          <br />
          <input
            type="text"
            //   min="0"
            // step="1"
            name="licenseNumber"
            className={`input inputWidth mb0 ${
              errors.licenseNumber ? "errorInput" : ""
            }`}
            id="licenseNumber"
            placeholder="License Number"
            value={licenseNumber}
            onChange={handleChange}
          ></input>
          {errors.licenseNumber && (
            <div className="error" style={{ margin: "0rem 1rem 0rem" }}>
              {errors.licenseNumber}
            </div>
          )}
        </div>

        <Button
          className="btn gradientButtonBlue ml-1 mt-1 mb-1"
          onClick={handleOrderClick}
        >
          Order Now
        </Button>
      </div>

      <div className="orderdiv">
        <TableContainer component={Paper}>
          <div className="printTableHeading">Previous Orders</div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableFont" align="left">
                  Order Date
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Quantity
                </TableCell>
                <TableCell className="tableFont" align="left">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenseData?.length > 0 ? (
                licenseData?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {moment(row?.created_at).format("MM/DD/YYYY ")}
                    </TableCell>
                    <TableCell align="left">{row?.licenses}</TableCell>
                    <TableCell align="left">
                      {row?.status === 0 ? "Pending" : "Completed"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    colspan={3}
                    style={{ height: "10rem" }}
                  >
                    {"There are no previous orders."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <CustomToastify />
    </Card>
  );
};

export default PurchaseLicenses;
