import { Card } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "../../styles/main.css";
import "../../styles/components/HelpHub.css";
import { FaTrash, FaEdit, FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  getTeacherData,
  deleteTeacherData,
} from "../../redux/Teachers/TeachersAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { tableIcons, options, Search } from "../../utils/TableMui";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@mui/material/Box";
import {
  getHelpHubData,
  setContentFilter,
} from "../../redux/HelpHub/HelpHubAction";
import { logoutUser } from "../../utils/authUtils";

const HelpHub = (props) => {
  const [loading, setLoading] = useState(true);
  const [helpData, setHelpData] = useState([]);
  const [buttonData, setButtonData] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const contentFilter = useSelector(
    (state) => state.helpHubReducer?.contentFilter
  );

  useEffect(() => {
    dispatch(setContentFilter(null));
    dispatch(getHelpHubData(userData?.school_id)).then(async (res) => {
      if (res?.statusCode === 200) {
        setData(res?.resourceCategoriesList);
        const updatedUserDetails = res?.resourceCategoriesList
          ?.map((user) => ({
            ...user.data,
            title: user?.categoryName,
          }))
          .filter((user) => user.title !== "Contact Support");

        let categoryArray = [];

        res?.resourceCategoriesList.forEach((category) => {
          category?.resourceItemsList.forEach((help) => {
            if (help.title === "Contact Support") return;
            const regex = /<iframe.*?src=["'](.*?)["'].*?>/;

            const match = help.content.match(regex);
            categoryArray.push({
              category: category.categoryName,
              category_id: help.category_id,
              content: match !== null ? match[1] : help.content,
              id: help.id,
              permission: help.permission,
              title: help.title,
              updated_at: help.updated_at,
              check: match !== null ? "video" : "image",
            });
          });
        });
        setHelpData(categoryArray);
        setButtonData(updatedUserDetails);
        setLoading(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });
  }, []);

  // const buttonsData =
  return (
    <>
      <Card sx={{ m: 1, p: 4 }}>
        {loading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}
        <div className="itemSpace">
          <div className="pageHeading">Help Hub</div>
        </div>
        <div className="pageBreadCrumb">
          <Link to="/dashboard/home">Home</Link> {" > "}
          Help Hub
        </div>

        <div>
          {buttonData.map((value, index) => {
            return (
              <Button
                className={`btn ${
                  contentFilter !== null
                    ? "gradientButtonGray"
                    : "gradientButtonBlue"
                } ${
                  contentFilter !== value?.title
                    ? "gradientButtonGray"
                    : "gradientButtonBlue"
                }  ml-2 `}
                onClick={() => dispatch(setContentFilter(value?.title))}
                // style={{ width: "12rem" }}
              >
                {value?.title}
              </Button>
            );
          })}
        </div>
        <div className="helpHubBox helpHubBoxResponsive">
          {/* <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)" }}> */}
          {/* <div className="helpRow"> */}
          {helpData.map((row, index) => {
            if (contentFilter !== null && row?.category !== contentFilter)
              return;
            return (
              <>
                <div className="helpRow mb-3 helpRowResponsive">
                  <div className="card h-100">
                    <div className="cardHeader">{row.title}</div>

                    <div className="cardBody">
                      {row.check == "image" ? (
                        <div
                          className="helpContainer"
                          dangerouslySetInnerHTML={{ __html: row.content }}
                        ></div>
                      ) : (
                        <div className="helpContainer">
                          <iframe
                            src={row.content}
                            // width="640"
                            width="500"
                            height="460"
                            frameborder="0"
                            allow="autoplay; 
                                        fullscreen"
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          {/* </Box> */}
          {/* </div> */}
        </div>

        <CustomToastify />
      </Card>
    </>
  );
};

export default HelpHub;
