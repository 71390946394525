import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGamesData } from "../../redux/LessonsResources/LessonsResourcesAction";
import { BASE_URL } from "../../redux/baseUrl/ApiConstant";
import { logoutUser } from "../../utils/authUtils";

const Games = ({ key, onNextItem, sceneId, gameId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [lessonId, setLessonId] = useState("");
  const userData = useSelector((state) => state.loginReducer?.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const regex = /\/lessons\/(\d+)\//;
    const match = window.location.href.match(regex);
    setLessonId(match[1]);
  }, []);

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async function resizeImageWithTransparency(blob, width, height) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(resolve, "image/png");
      };
      img.src = URL.createObjectURL(blob);
    });
  }

  const handleIframeLoad = (event) => {
    const iframe = event.target;
    dispatch(getGamesData(gameId, userData?.id)).then(async (res) => {
      if (res?.statusCode === 200) {
        const pageTitle = document.querySelector("title");
        if (pageTitle) {
          pageTitle.textContent = res.gameDescription.title;
        }

        let audioFiles = [];
        audioFiles.push(...res?.answersAudioFIleResponse);
        audioFiles.push(...res?.audioFileResponses);
        audioFiles.push(...res?.instructionAudioFileResponses);
        audioFiles.push(...res?.staticAudioFileResponses);

        const convertedAudioFiles = audioFiles.map((response) => {
          const audioBlob = new Blob(
            [base64ToArrayBuffer(response.audioFile)],
            { type: "audio/wav" }
          );
          const audioUrl = URL.createObjectURL(audioBlob);
          return { audio: audioUrl, title: response.title };
        });

        let blobs = {};
        const loadImages = async (imageUrls) => {
          await Promise.all(
            Object.entries(imageUrls).map(async ([elementName, urls]) => {
              const resizedBlobs = await Promise.all(
                urls.map(async (url) => {
                  const response = await fetch(url);
                  const blob = await response.blob();
                  const resizedBlob = await resizeImageWithTransparency(
                    blob,
                    384,
                    356
                  );
                  return URL.createObjectURL(resizedBlob);
                })
              );
              blobs[elementName] = resizedBlobs;
            })
          );
        };
        await loadImages(res.images.spritesheet);
        let gameData;
        let imageData;
        gameData = {
          gameDescription: res.gameDescription,
          muted: res?.muted,
          instructionConfig: res.instructionsConfig,
          levels: res.levels,
          maximumScore: res.maximumScore,
          roundsPerLevel: res.roundsPerLevel,
          tries: res.tries,
        };
        imageData = {
          spritesheet: blobs,
          backgroundImage: res.images.backgroundImage,
        };
        const message = {
          type: "init",
          data: {
            gameData,
            imageData,
            onComplete: "handleGameCompletion",
            studentId: userData.id,
            gameid: gameId,
            lessonId: lessonId,
            sceneId: sceneId,
            save: userData?.userType == "student",
            baseUrl: BASE_URL,
            audioData: convertedAudioFiles,
          },
        };

        iframe?.contentWindow?.postMessage(message, "*");
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });
  };

  const handleGameCompletion = () => {
    const nextIndex = currentIndex + 1;
    onNextItem();
  };
  let counter = 0;
  window.addEventListener("message", (event) => {
    if (event.data === "gameCompleted") {
      handleGameCompletion();
    }
  });

  return (
    <div className="gameContainer">
      <iframe
        key={key}
        title={`PhaserGame-${key}`}
        src="/srcGames/index1.html"
        width="100%"
        height="650px"
        frameBorder="0"
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
};

export default Games;
