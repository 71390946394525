import {
  SET_NAVBAR,
  SET_LAST_VISITED,
  SET_CLASSROOM_ID,
  SET_2LAST_VISITED,
} from "./HeaderAction";
import { RESET_STATE } from "../Login/LoginAction";

const initialState = {
  setNavbarState: true,
  lastVisited: "StudentDashBoard",
  classroomId: 0,
  secondLastVisited: "",
};

function headerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAVBAR:
      return (state = {
        ...state,
        setNavbarState: action.data,
      });
    case SET_LAST_VISITED:
      return (state = {
        ...state,
        lastVisited: action.data,
      });
    case SET_CLASSROOM_ID:
      return (state = {
        ...state,
        classroomId: action.data,
      });
    case SET_2LAST_VISITED:
      return (state = {
        ...state,
        secondLastVisited: action.data,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
export default headerReducer;
