import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import "../../styles/main.css";
import "../../styles/components/myTeachers.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { tableIcons, options } from "../../utils/TableMui";
import {
  getStudentDashboard,
  getStudentDashboardTeacher,
} from "../../redux/Student/StudentAction";
import {
  getLessonsClassData,
  putApplyChanges,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import Checkbox from "@material-ui/core/Checkbox";
import { logoutUser } from "../../utils/authUtils";

const AssignStudents = (props) => {
  const [isStudentLoading, setIsStudentLoading] = useState(true);
  const [isClassLoading, setIsClassLoading] = useState(true);
  const [studentData, setStudentData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedStudentData, setSelectedStudentData] = useState([]);
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [isStudent, setIsStudent] = useState(true);
  const [studentBtnClick, setStudentBtnClick] = useState(true);
  const [classBtnClick, setClassBtnClick] = useState(false);
  const [lessonId, setLessonId] = useState(true);
  const [allStudentsSelected, setAllStudentSelected] = useState(false);
  const [allClassSelected, setAllClassSelected] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const tableRef = useRef();

  const userData = useSelector((state) => state.loginReducer?.userData);
  const lessonName = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonName
  );
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );

  useEffect(() => {
    const url = window.location.pathname;
    // setStudentOfClass(url.endsWith("/new"));
    let match = "";
    if (url.endsWith("/new")) {
      const regex = /\/lesson_groups\/(\d+)\//;
      match = window.location.href.match(regex);
      setLessonId(match[1]);
    }
    if (userData?.school_id !== undefined) {
      userData?.userType === "school_admin"
        ? dispatch(getStudentDashboard(userData?.school_id)).then(
            async (res) => {
              if (res?.statusCode === 200) {
                let newArray = [];
                res?.studentDashboardDTO?.forEach((user) => {
                  let {
                    studentId,
                    studentName,
                    schoolCode,
                    loginCode,
                    lessonGroupName,
                    courseName,
                    studentUpdatedAt,
                  } = user;
                  const updatedCourseName = user?.courseName?.replace(
                    /\s+/g,
                    ""
                  );

                  newArray.push({
                    studentId,
                    studentName,
                    schoolCode,
                    loginCode,
                    lessonGroupName,
                    courseName: updatedCourseName,
                    studentUpdatedAt,
                    courselesson:
                      updatedCourseName === null ||
                      updatedCourseName === undefined
                        ? lessonGroupName
                        : updatedCourseName + "-" + lessonGroupName,
                  });
                });
                setStudentData(newArray);
                setIsStudentLoading(false);
              } else if (res?.statusCode === 403 || res?.statusCode === 401) {
                logoutUser(dispatch, navigate, userData, res?.statusCode);
              }
            }
          )
        : dispatch(
            getStudentDashboardTeacher(userData?.school_id, userData?.id)
          ).then(async (res) => {
            if (res?.statusCode === 200) {
              let newArray = [];
              res?.studentDashboardDTO?.forEach((user) => {
                let {
                  studentId,
                  studentName,
                  schoolCode,
                  loginCode,
                  lessonGroupName,
                  courseName,
                  studentUpdatedAt,
                } = user;
                
                const updatedlessonGroupName= lessonGroupName === null ? "None" : lessonGroupName;
                const updatedCourseName = user?.courseName?.replace(/\s+/g, "");

                newArray.push({
                  studentId,
                  studentName,
                  schoolCode,
                  loginCode,
                  lessonGroupName: updatedlessonGroupName,
                  courseName: updatedCourseName,
                  studentUpdatedAt,
                  courselesson:
                  courseName === null ? "None" :
                    updatedCourseName === null ||
                    updatedCourseName === undefined
                      ? lessonGroupName
                      : updatedCourseName + "-" + lessonGroupName,
                });
              });
              setStudentData(newArray);
              setIsStudentLoading(false);
            } else if (res?.statusCode === 403 || res?.statusCode === 401) {
              logoutUser(dispatch, navigate, userData, res?.statusCode);
            }
          });
    }

    dispatch(getLessonsClassData(userData?.school_id, userData?.id)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          setClassData(res?.classStatusList);
          setIsClassLoading(false);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      }
    );
  }, []);
  const customFilterAndSearch = (term, rowData) => {
    let termArray = [];
    const terms = term.toLowerCase();
    termArray.push(terms);

    return termArray.some((t) =>
      rowData?.courselesson?.toLowerCase().includes(t)
    );
  };

  const studentColumns = [
    {
      title: (
        <Checkbox
          checked={allStudentsSelected}
          onChange={() => handleSelectStudentAllClick()}
        />
      ),
      field: "select",
      headerClassName: "custom-header",
      render: (rowData) => (
        <Checkbox
          checked={selectedStudentData.includes(rowData.studentId)} // Check if the class is selected
          onChange={() => handleStudentCheckboxChange(rowData)}
        />
      ),
      maxWidth: 60,
      headerStyle: {
        maxWidth: "100px!important", // or any other value you prefer
        width: "10%",
        whiteSpace: "nowrap", // to prevent text from wrapping
        overflow: "hidden", // to hide overflow content
        textOverflow: "ellipsis",
      },
      cellStyle: {
        maxWidth: 100,
        width: "10%",
        whiteSpace: "nowrap", // to prevent text from wrapping
        overflow: "hidden", // to hide overflow content
        textOverflow: "ellipsis",
      },

      sorting: false,
      filtering: false,
    },
    {
      title: "Name",
      field: "studentName",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.studentName}</span>
        </div>
      ),
      // headerStyle: { width: "20%" },
      // cellStyle: { width: "20%" },

      maxWidth: 120,
      headerStyle: {
        whiteSpace: "nowrap", // to prevent text from wrapping
        overflow: "hidden", // to hide overflow content
        textOverflow: "ellipsis",
        width: "20%",
      },
      cellStyle: {
        maxWidth: 100,
        whiteSpace: "normal", // to prevent text from wrapping
        wordWrap: "break-word",
        width: "20%",
      },
      sorting: studentData?.length > 0,
    },
    {
      title: "Currently assigned to",
      field: "courselesson",
      render: (rowData) => (
        <div>
          <div style={{ marginLeft: 5 }}>{rowData.courselesson}</div>
        </div>
      ),
      sorting: studentData?.length > 0,
      customFilterAndSearch: (term, rowData) =>
        customFilterAndSearch(term, rowData),
    },
  ];

  const classColumns = [
    {
      title: (
        <Checkbox
          checked={allClassSelected}
          onChange={() => handleSelectAllClassClick()}
        />
      ),
      field: "select",
      headerClassName: "custom-header",
      render: (rowData) => (
        <Checkbox
          disabled={rowData.disable}
          checked={selectedClassData.includes(rowData.id)} // Check if the class is selected
          onChange={() => handleClassCheckboxChange(rowData)}
          // Add more props as needed
        />
      ),
      maxWidth: 60,
      headerStyle: {
        maxWidth: "100px!important", // or any other value you prefer
        width: "10%",
        whiteSpace: "nowrap", // to prevent text from wrapping
        overflow: "hidden", // to hide overflow content
        textOverflow: "ellipsis",
      },
      cellStyle: {
        maxWidth: 100,
        width: "10%",
        whiteSpace: "nowrap", // to prevent text from wrapping
        overflow: "hidden", // to hide overflow content
        textOverflow: "ellipsis",
      },

      sorting: false,
      filtering: false,
    },
    {
      title: "Name",
      field: "className",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.className}</span>
        </div>
      ),

      maxWidth: 120,
      headerStyle: {
        whiteSpace: "nowrap", // to prevent text from wrapping
        overflow: "hidden", // to hide overflow content
        textOverflow: "ellipsis",
        width: "20%",
      },
      cellStyle: {
        maxWidth: 100,
        whiteSpace: "normal", // to prevent text from wrapping
        wordWrap: "break-word",
        width: "20%",
      },
      sorting: classData?.length > 0,
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) =>
        // <div>
        //   <div style={{ marginLeft: 5 }}>{rowData.status}</div>
        // </div>
        !rowData.disable ? (
          <div style={{ marginLeft: 5 }}>{rowData.status}</div>
        ) : (
          <div style={{ marginLeft: 5 }}>
            {rowData.status.slice(0, rowData.status.indexOf(".") + 1)}
            <span style={{ color: "red" }}>
              {" Please assign students to this class."}
            </span>
          </div>
        ),
      sorting: classData?.length > 0,
    },
  ];

  const handleClassCheckboxChange = (rowData) => {
    if (selectedClassData.includes(rowData.id)) {
      // Class is already selected, remove it
      dispatch(showToastMessage(401, rowData.className + " deselected."));
      setSelectedClassData((prevData) =>
        prevData.filter((classId) => classId !== rowData.id)
      );

      setAllClassSelected(false);
    } else {
      dispatch(showToastMessage(200, rowData.className + " selected."));
      // Class is not selected, add it
      setSelectedClassData((prevData) => [...prevData, rowData.id]);
    }
  };
  const handleStudentCheckboxChange = (rowData) => {
    if (selectedStudentData.includes(rowData.studentId)) {
      // Class is already selected, remove it
      dispatch(showToastMessage(401, rowData.studentName + " deselected."));
      setSelectedStudentData((prevData) =>
        prevData.filter((classId) => classId !== rowData.studentId)
      );

      setAllStudentSelected(false);
    } else {
      dispatch(showToastMessage(200, rowData.studentName + " selected."));
      // Class is not selected, add it
      setSelectedStudentData((prevData) => [...prevData, rowData.studentId]);
    }
  };
  const handleStudentSelectionChange = (rows) => {
    let newArray = [];

    rows.map((item, index) => {
      newArray.push(item.studentId);
    });
    // selectedStudentData.push(newArray);
    setSelectedStudentData(newArray);
  };
  const handleSelectStudentAllClick = () => {
    if (allStudentsSelected) {
      setSelectedStudentData([]);
    } else {
      setSelectedStudentData([
        ...studentData.map((student) => student.studentId),
      ]);
    }
    setAllStudentSelected(!allStudentsSelected);
  };

  const handleSelectAllClassClick = () => {
    if (allClassSelected) {
      setSelectedClassData([]);
    } else {
      const enabledStudentIds = classData
        .filter((classD) => !classD.disable)
        .map((classD) => classD.id);
      setSelectedClassData(enabledStudentIds);
    }
    setAllClassSelected(!allClassSelected);
  };

  const handleClassSelectionChange = (rows) => {
    let newClassArray = [];

    rows.map((item, index) => {
      newClassArray.push(item.id);
    });
    // selectedClassData.push(newClassArray);

    setSelectedClassData(newClassArray);
  };

  const handleState = (rows) => {
    setIsStudent(!isStudent);
  };

  const handleStudentClick = () => {
    setStudentBtnClick(true);
    setClassBtnClick(false);
  };

  const handleClassClick = () => {
    setStudentBtnClick(false);
    setClassBtnClick(true);
  };

  const handleApplyChanges = () => {
    if (studentBtnClick) {
      if (selectedStudentData.length < 1) {
        dispatch(showToastMessage(401, "No students selected."));

        return;
      }
    } else {
      if (selectedClassData.length < 1) {
        dispatch(showToastMessage(401, "No Class selected."));
        return;
      }
    }
    var dataModel = studentBtnClick
      ? { selectedStudentData, selectedClassData: [], lessonId }
      : { selectedStudentData: [], selectedClassData, lessonId };

    dispatch(putApplyChanges(dataModel)).then(async (res) => {
      if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
      dispatch(showToastMessage(res?.statusCode, res?.message));
      navigate(`/dashboard/courses/${courseId}/lesson_groups/${lessonGroupId}`);
      // setIsLoading(false);
    });
  };

  const tableTitle = (
    <div className="TableTitle">{studentBtnClick ? "Students" : "Classes"}</div>
  );

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <>
      <Card sx={{ m: 1, p: 4 }}>
        {isClassLoading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}
        <div ref={tableRef}></div>
        <div className="itemSpace">
          <div className="pageHeading">
            Assign Students and Classes to lesson '{lessonName}'
          </div>
        </div>
        <div className="pageBreadCrumb">
          <Link to="/dashboard/home">Home</Link> {" > "}
          <Link to="/dashboard/myLessonsResources">
            My Lessons & Resources
          </Link>{" "}
          {" > "}
          <Link
            to={`/dashboard/courses/${courseId}/lesson_groups/${lessonGroupId}`}
          >
            Lesson Groups
          </Link>{" "}
          {" > "}
          Assign Students
        </div>
        <div className="description">
          <div className="pl-1">
          <div className="AssignStudentsMain">
            Students can only see the lessons you have assigned to them. 
          </div>
          <div className="AssignStudentsMain">
          PLEASE
            NOTE - Students will lose access to their current lessons if you
            change their lesson assignment.
          </div>
          <div className="AssignStudentsMain pt-2.5">
            You can assign lessons to an individual student or to an entire
            class.
          </div>
          </div>
          <div className="itemSpace mt-2">
            <div className="displayF">
              <Button
                className={
                  studentBtnClick
                    ? "btn gradientButtonBlue mr-1"
                    : "btn gradientButtonGray mr-1"
                }
                onClick={handleStudentClick}
              >
                Assign By Student
                {/* {isStudent ? "Assign To Class" : "Assign To Student"} */}
              </Button>

              <Button
                className={
                  classBtnClick
                    ? "btn gradientButtonBlue"
                    : "btn gradientButtonGray mr-1"
                }
                onClick={handleClassClick}
              >
                Assign By Class
                {/* {isStudent ? "Assign To Class" : "Assign To Student"} */}
              </Button>
            </div>
            <Button
              className="btn gradientButtonPink mr-1"
              onClick={handleApplyChanges}
            >
              Apply Changes
            </Button>
          </div>
        </div>
        <div className="MuiTable">
          {studentBtnClick ? (
            <MaterialTable
              title={tableTitle}
              className="myTable"
              icons={tableIcons}
              data={studentData}
              columns={studentColumns}
              options={{
                ...options,
                // selection: true,
              }}
              onChangePage={handlePageChange}
              // isLoading={isStudentLoading}
              onSelectionChange={handleStudentSelectionChange}
            />
          ) : (
            <MaterialTable
              title={tableTitle}
              className="myTable"
              icons={tableIcons}
              data={classData}
              columns={classColumns}
              options={{
                ...options,
                // selection: true,
              }}
              onChangePage={handlePageChange}
              // isLoading={isClassLoading}
              onSelectionChange={handleClassSelectionChange}
            />
          )}
        </div>
        <CustomToastify />
      </Card>
    </>
  );
};

export default AssignStudents;
