import { Card } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "../../styles/main.css";
import "../../styles/components/freeTimeActivities.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { gamesData } from "./GamesConfig";
import TeacherIcon from "../../assests/images/lesson-plan.png";

const Games = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1); // State variable to track the index of the hovered Accordion
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
 

  return (
    <Card sx={{ m: 1, p: 4 }}>
      <div className="itemSpace">
        <div className="pageHeading">Brain Games</div>
      </div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        Brain Games
      </div>
      <div className="m2">
        {gamesData?.map((value, index) => {
          return (
            <Accordion defaultExpanded key={index} className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                className="accordionSummary"
              >
                <Typography>{value?.gameLevel}</Typography>
              </AccordionSummary>
              <AccordionDetails
                onMouseEnter={() => setHoveredIndex(index)} // Set the hovered index on mouse enter
                onMouseLeave={() => setHoveredIndex(-1)} // Reset the hovered index on mouse leave
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  {value?.games?.map((game, gameIndex) => {
                    return (
                      <Card
                        key={gameIndex}
                        style={{
                          flex: "0 0 12rem",
                          height: "-webkit-fill-available", // Adjust the width as per your needs
                          position: "relative", // Required for absolute positioning
                          overflow: "hidden", // Hide any content that exceeds the card
                          filter: hoveredIndex === index ? "blur(2px)" : "none", // Apply blur effect to the hovered Accordion
                        }}
                      >
                        <div className="gameCardHeading">{game?.gameName}</div>
                        <img
                          src={TeacherIcon}
                          alt="Teacher Logo"
                          className="teacherLogo"
                        />
                        {hoveredIndex === index && (
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                            >
                              Play
                            </Button>
                          </div>
                        )}
                      </Card>
                    );
                  })}
                </div>
                <div className="itemEnd">
                  <Button className="btn gradientButtonBlue mr-1">
                    View More
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Card>
  );
};

export default Games;
