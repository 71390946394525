import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA";
export const SAVE_ACCOUNT_DETAILS = "SAVE_ACCOUNT_DETAILS";
export const GET_LICENSE_DATA = "GET_LICENSE_DATA";
export const SAVE_PURCHASE_LICENSE_DATA = "SAVE_PURCHASE_LICENSE_DATA";



export const getAccountsData = (schoolId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios(
        `${BASE_URL}/api/v1/users/schools/${schoolId}/AccountDetails`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
        .then((res) => {
          dispatch(getgetAccountsDataApiSuccess(res.data));
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          resolve(err.response.data);
          reject(err.response.data);
        });
    });
  };


  export const saveAccountDetails = (data, schoolId) => (dispatch) => {
      return new Promise((resolve, reject) => {
        var dataModel = {
          city: data.city,
          name: data.schoolName,
          phone: data.phoneNumber,
          state: data.schoolState,
          street: data.streetAddress,
          zip: data.zip,
        };
        axios
          .post(`${BASE_URL}/api/v1/users/schools/${schoolId}/UpdateAccount`, dataModel, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
            },
          })
          .then((res) => {
            dispatch(saveAccountDetailsApiSuccess(res.data));
            resolve(res.data);
            return res.data;
          })
          .catch((err) => {
            resolve(err.response.data);
            reject(err.response.data);
          });
      });
    };


    
export const getLicenseData = (schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/LicenseDetails`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getLicenseDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};


export const purchaseLicense= (licenseNumber, schoolId, userID) => (dispatch) => {
  var data= {};
  return new Promise((resolve, reject) => {  

       axios
      .post(`${BASE_URL}/api/v1/users/schools/${schoolId}/users/${userID}/${licenseNumber}/purchaseLicense`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(purchaseLicenseApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};


export const purchaseLicenseApiSuccess = (value) => {
  return {
    type: SAVE_PURCHASE_LICENSE_DATA,
    data: value,
  };
};


export const getLicenseDataApiSuccess = (value) => {
  return {
    type: GET_LICENSE_DATA,
    data: value,
  };
};

  export const getgetAccountsDataApiSuccess = (value) => {
    return {
      type: GET_ACCOUNT_DATA,
      data: value,
    };
  };

  export const saveAccountDetailsApiSuccess = (value) => {
    return {
      type: SAVE_ACCOUNT_DETAILS,
      data: value,
    };
  };
  