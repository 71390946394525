const adventuresConfig = [
    {
      id: "1",
      path: "",
      title: "EP 01 - Coming Home",
      icon: "",
      
    },
    {
        id: "2",
        path: "",
        title: "Ep 02 - Ready to Roll",
        icon: "",
        
      },
      {
        id: "3",
        path: "",
        title: "Ep 03 - Parts Unknown",
        icon: "",
        
      },
      {
        id: "4",
        path: "",
        title: "Ep 04 - North America",
        icon: "",
        
      },
      {
        id: "5",
        path: "",
        title: "Ep 05 - A Rich Kneoworld",
        icon: "",
        
      },
      {
        id: "6",
        path: "",
        title: "Ep 06 - Night on Earth",
        icon: "",
        
      },
      {
        id: "7",
        path: "",
        title: "Ep 07 - Strange Things...",
        icon: "",
        
      },
      {
        id: "8",
        path: "",
        title: "Ep 08 - We Are Not Alone",
        icon: "",
        
      },
      {
        id: "9",
        path: "",
        title: "Ep 09 - The Frozen Land",
        icon: "",
        
      },
      {
        id: "10",
        path: "",
        title: "Ep 10 - The Sasquatch",
        icon: "",
        
      },
    ];

    export default adventuresConfig;
    