import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, Link } from "react-router-dom";
import {
    learnosityAssessment
} from "../../redux/Student/StudentAction";
const Assessment = () => {


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [security, setSecurity] = useState([]);
    const [request, setRequest] = useState([]);
    const navigate = useNavigate();
const dispatch = useDispatch();
const userData = useSelector((state) => state.loginReducer?.userData);


  useEffect(() => {


    var domain = "http://localhost:3000"; 

    dispatch(learnosityAssessment(domain)).then(
        async (res) => {        
            setAllData(res);
            // setSecurity(res?.security);
            // setRequest(res?.request);         
        }
      );


    const learnosityOptions = {
      consumer_key: allData?.consumer_key,
      domain: allData?.domain,
      user_id:  userData?.id,
      timestamp: allData?.timestamp,
      signature: allData?.signature,
      items: [],
      activity_id: 'ACTIVITY_ID',
      activity_template_id: 'ACTIVITY_TEMPLATE_ID'
    };
    //   const LearnosityApp = require('./index');

    // Load Learnosity library script
    const script = document.createElement('script');
    script.src = 'https://demos.learnosity.com/assessment/activities.php';
    script.async = true;
    script.onload = () => {
      // Initialize Learnosity
        // const learnosityApp = LearnosityApp.init(learnosityOptions);

      // Render the assessment
    //    learnosityApp.render('learnosity-container', 'assessment', { state: 'initial' });
    };

    document.body.appendChild(script);
  }, []);

  return <div id="learnosity-container"></div>;
};

export default Assessment;