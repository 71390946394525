import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLogout,
  resetState,
  setSSOLogout,
} from "../redux/Login/LoginAction";
import { showToastMessage } from "../redux/Toaster/ToasterAction";
import { persistor } from "../app/store";

export const logoutUser = async (dispatch, navigate, userData, res) => {
  try {
    const res = await dispatch(getLogout());
  } catch (error) {
    console.error("Error during logout:", error);
  } finally {
    if (userData?.roster_name === "edlink") {
      dispatch(setSSOLogout(true));
    }
    localStorage.removeItem("AuthToken");
    persistor.purge();
    dispatch(resetState());
    navigate("/login", { replace: true });
    if (res === 403 || res === 401) {
      dispatch(
        showToastMessage(403, `Session Expired or Something went wrong.`)
      );
    }
  }
};
