import { SET_TOASTER } from "./ToasterAction";
import { RESET_STATE } from "../Login/LoginAction";

const initialState = {
  message: "",
  responseCode: "",
};

function toasterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOASTER:
      return (state = {
        ...state,
        responseCode: action.responseCode,
        message: action.message,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default toasterReducer;
