import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import "../../styles/main.css";
import "../../styles/components/home.css";
import "../../styles/components/studentreport.css";
import Card from "@material-ui/core/Card";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  getStudentReport,
  updateSound,
  getStudentProgressReport,
  getStudentGamesReport,
  getStudentStandardReport,
  setCategoryName,
  setAssessStudent,
} from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { setSecondLastVisited } from "../../redux/Header/HeaderAction";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  FaPlay,
  FaHeadSideVirus,
  FaSpellCheck,
  FaSquareRootAlt,
  FaFlask,
  FaMedapps,
  FaShieldVirus,
  FaShieldAlt,
  FaRegHourglass,
} from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import Chart from "chart.js/auto";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccessYourClass from "../MyClasses/AssessYourClass";
import { logoutUser } from "../../utils/authUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#ededed",
    },
  })
);

const StudentReport = (props) => {
  const classes = useStyles();

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [muted, setMuted] = useState(true);
  const [studentName, setStudentName] = useState("");
  const [progressData, setProgressData] = useState([]);
  const [progressList, setProgressList] = useState([]);
  const [progressPercentageData, setProgressPercentageData] = useState([]);
  const [progressLessonData, setProgressLessonData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryEpisodeData, setCategoryEpisodeData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [gameTime, setGameTime] = useState([]);
  const [gameName, setGameName] = useState([]);
  const [gameColor, setGameColor] = useState([]);
  const [progressColor, setprogressColor] = useState([]);
  const [litrecyAttempted, setLitrecyAttempted] = useState([]);
  const [mathAttempted, setMathAttempted] = useState([]);
  const [litrecyCompleted, setLitrecyCompleted] = useState(0);
  const [mathCompleted, setMathCompleted] = useState(0);
  const [totalInteraction, setTotalInteraction] = useState(0);
  const [hideItem, setHideItem] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const lastVisited = useSelector((state) => state.headerReducer?.lastVisited);
  const classroomId = useSelector((state) => state.headerReducer?.classroomId);
  const categoryName = useSelector(
    (state) => state.studentReducer?.categoryName
  );
  const studentDropdown = useSelector(
    (state) => state.loginReducer?.studentDropdown?.dropDownList
  );
  const selectedStudent = useSelector(
    (state) => state.loginReducer?.selectedStudent
  );

  useEffect(() => {
    dispatch(setAssessStudent(true));
    if (userData?.userType === "parent") return;
    dispatch(setSecondLastVisited("StudentReport"));
    let idStudent;
    if (userData?.userType !== "student" && userData?.userType !== "parent") {
      const containsEditStudent = window.location.href;
      // containsEditStudent ? setIsEditMode(true) : setIsEditMode(false);
      const regex = containsEditStudent
        ? /\/schools\/(\d+)\/students\/(\d+)/
        : /\/schools\/(\d+)\/students\//;
      const match = window.location.href.match(regex);
      idStudent = match[2];
      setStudentId(match[2]);
    } else if (userData?.userType === "student") {
      setStudentId(userData?.id);
    }

    setSchoolId(userData?.school_id);
    handleStudentReportData(
      userData?.school_id,
      userData?.userType !== "student" ? idStudent : userData?.id
    );
  }, [userData]);

  useEffect(() => {
    if (userData?.userType !== "parent") return;
    setStudentId(selectedStudent[0]?.id);
    setStudentName(selectedStudent[0]?.name);
    setSchoolId(selectedStudent[0]?.school_id);
    handleStudentReportData(
      selectedStudent[0]?.school_id,
      selectedStudent[0]?.id
    );
  }, [selectedStudent]);

  const handleStudentReportData = (schoolId, studentId) => {
    setLoading(true);
    dispatch(getStudentReport(schoolId, studentId)).then(async (res) => {
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllData(res);
        setMuted(!res?.muted);
        var availableclasses = [];
        res?.classRoomList?.map((value) => {
          availableclasses.push(value);
        });
        setClassData(availableclasses);
        handlePageScroll();
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });

    dispatch(getStudentProgressReport(schoolId, studentId)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          setProgressData(res);
          setProgressList(res?.episodeProgressList);
          setLoading2(false);
          var ProgressPercentage = [];
          var ProgressLesson = [];
          var progressColor = [];
          res?.episodeProgressList?.map((item, index) => {
            ProgressPercentage.push(item.percentage);
            ProgressLesson.push(item.episodeName);
            progressColor.push(item.color);
          });
          setProgressPercentageData(ProgressPercentage);
          setProgressLessonData(ProgressLesson);
          setprogressColor(progressColor);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      }
    );

    dispatch(getStudentStandardReport(schoolId, studentId)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          setStandardData(res);
          setCategoryList(res?.categoryInteractions);
          setCategoryEpisodeData(res?.categoryEpisodeMap);
          setLoading3(false);
          var litCompleted = 0;
          var matCompleted = 0;
          setLitrecyAttempted(res?.categoryEpisodeMap?.Literacy?.length);
          setMathAttempted(res?.categoryEpisodeMap?.Math?.length);

          res?.categoryEpisodeMap?.Literacy?.map((lesson, index) => {
            // litCompleted.push(lesson.percentage);
            if (lesson.percentage === 100) {
              litCompleted = litCompleted + 1;
            }
            setLitrecyCompleted(litCompleted);
          });

          res?.categoryEpisodeMap?.Math?.map((lesson, index) => {
            // matCompleted.push(item.episodeName);
            if (lesson.percentage === 100) {
              matCompleted = matCompleted + 1;
            }
            setMathCompleted(matCompleted);
          });

          let interactions = 0;
          res?.categoryInteractions?.map((lesson, index) => {
            interactions += lesson?.interactionCount;
          });

          setTotalInteraction(interactions);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      }
    );
    // setLoading1(true);
    dispatch(getStudentGamesReport(schoolId, studentId)).then(async (res) => {
      if (res?.statusCode === 200) {
        setGameData(res);
        setGameList(res?.gamesReportProjectionList);

        var gametime = [];
        var gameLesson = [];
        var gameColor = [];
        res?.gamesReportProjectionList?.map((item1, index) => {
          if (item1?.timeSpent !== 0) {
            gametime?.push(item1.timeSpent / 60000);
            gameLesson?.push(item1.name);
            gameColor?.push(item1.color);
          }
        });
        setGameTime(gametime);
        setGameName(gameLesson);
        setGameColor(gameColor);
        setLoading1(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });
  };

  const handleClick = (event) => {
    const muted = event.target.checked;
    setMuted(muted);
    var dataModel = {
      operationLevel: "student",
      soundMuted: !muted,
      operationId: parseInt(studentId),
    };
    dispatch(updateSound(dataModel)).then(async (res) => {
      if (res?.statusCode === 200) {
        if (!muted === true) {
          dispatch(
            showToastMessage(
              res?.statusCode,
              "You have turned lesson sound OFF for " + allData?.studentName
            )
          );
        } else {
          dispatch(
            showToastMessage(
              res?.statusCode,
              "You have turned lesson sound ON for " + allData?.studentName
            )
          );
        }
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      } else {
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
  };

  const progressOption = {
    // indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
          font: {
            color: "green", // Set the font color
            weight: "bold", // Set font weight (if supported)
          },
        },
      },
      y: {
        beginAtZero: true,
        max: 100,

        ticks: {
          stepSize: 25,
          callback: (value) => `${value}` + "%",
          font: {
            size: 14,
            weight: "bold", // Set font weight (if supported)
          },
        },
        title: {
          display: true,
          text: "Completion Percentage",
        },
      },
    },
    elements: {
      bar: {
        barThickness: 0.2,
      },
    },
    responsive: true,
    barThickness: 20,
    borderWidth: 1,
  };

  const prgData = {
    labels: progressLessonData,
    datasets: [
      {
        label: "Completion Percentage",
        data: progressPercentageData,
        backgroundColor: progressColor,
      },
    ],
  };

  const gamesOption = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            color: "green", // Set the font color
            weight: "bold", // Set font weight (if supported)
          },
        },
        title: {
          display: true,
          text: "Time Played (Minutes)",
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
            weight: "bold", // Set font weight (if supported)
          },
        },
      },
    },
    elements: {
      bar: {
        barThickness: 0.2,
      },
    },
    responsive: true,
    barThickness: 30,
    borderWidth: 1,
  };

  const gamesData = {
    labels: gameName,
    datasets: [
      {
        label: "Time Played (Minutes)",
        data: gameTime,
        backgroundColor: gameColor,
      },
    ],
  };

  const handleViewDetails = (categoryname) => {
    dispatch(setCategoryName(categoryname));
    navigate(`/dashboard/students/${studentId}/lessonreports`);
  };

  const handleHideItem = () => {
    setHideItem(true);
  };

  const handlePageScroll = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Card>
      {(loading || loading1 || loading2 || loading3) && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div ref={tableRef}/>
      <div style={{ display: hideItem ? "none" : "block" }}>
        {userData?.userType === "student" || userData?.userType === "parent" ? (
          <>
            <div className="pageHeading">Progress</div>
            <div className="pageBreadCrumb">
              {userData?.userType === "student" ? (
                <Link to="/dashboard/lessons">Lessons</Link>
              ) : (
                <Link to="/dashboard/lesson_groups">Lessons</Link>
              )}{" "}
              {" > "}
              Progress
            </div>
          </>
        ) : (
          <>
            <div className="pageHeading">Student Activity Report by Lesson</div>
            <div
              className="pageBreadCrumb"
              style={{
                display: lastVisited === "StudentList" ? "block" : "none",
              }}
            >
              <Link to="/dashboard/home">Home</Link> {" > "}
              <Link to="/dashboard/classes">My Classes</Link> {" > "}
              <Link
                to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`}
              >
                Student List
              </Link>{" "}
              {" > "}
              Student Activity Report by Lesson
            </div>

            <div
              className="pageBreadCrumb"
              style={{
                display: lastVisited === "StudentDashBoard" ? "block" : "none",
              }}
            >
              <Link to="/dashboard/home">Home</Link> {" > "}
              <Link to="/dashboard/myStudentsReports">My Students</Link> {" > "}
              Student Activity Report by Lesson
            </div>
          </>
        )}

        {userData?.userType !== "student" && userData?.userType !== "parent" ? (
          <div className="studentDashboard">
            <div className="cardGroup cardBorder">
              <div className="studentCard cardBorderRight studentCardResponsive">
                <div className="cardHeader">Personal Details</div>

                <div className="cardBody">
                  <div className="cardTitle">{allData?.studentName}</div>
                  {userData?.userType !== "student" && (
                    <>
                      {allData?.email !== null && allData?.email !== "" && (
                        <div className="cardTitle">
                          {" "}
                          Google Email: {allData?.email}
                        </div>
                      )}{" "}
                    </>
                  )}

                  <div className="imageMask">
                    <img
                      src={TeacherLogo}
                      alt="Teacher Logo"
                      className="teacherLogo"
                    />
                  </div>
                  <div className="itemCenter">
                    <Link
                      to={`/dashboard/schools/${schoolId}/students/${studentId}/edit`}
                    >
                      <Button className="btn gradientButtonPink">
                        Edit Student
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="studentCard cardBorderLeft studentCardResponsive">
                <div className="cardHeader">Other Details</div>

                <div className="cardBody">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="textWeight m1">Lesson Group</td>
                        <td>
                          {allData?.lessonName === null
                            ? "None"
                            : allData?.lessonName}
                        </td>
                      </tr>
                      <tr>
                        <td className="textWeight m1">School Code</td>
                        <td>{allData?.schoolCode}</td>
                      </tr>
                      <tr>
                        <td className="textWeight m1">Classes</td>
                        <td className="classWidth">
                          {allData?.classRoomList?.map((classList, index) => {
                            return (
                              <Link
                                to={`/dashboard/schools/${schoolId}/classrooms/${classList?.id}`}
                                className="tableList"
                              >
                                {!(allData?.classRoomList?.length - 1 == index)
                                  ? classList?.name + ", "
                                  : classList?.name}
                              </Link>
                            );
                          })}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="textWeight m1">Student ID</td>
                        <td>{allData?.loginCode}</td>
                      </tr>
                      <tr>
                        <td className="textWeight m1">Lesson Sound</td>
                        <td>
                          <div className="switchContainer">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={muted}
                                onClick={handleClick}
                              />
                              <span className="slider"></span>
                              <div className="text on">On</div>
                              <div className="text off">Off</div>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="studentDashboard">
            <div className="cardGroup cardBorder">
              <div className="studentOneCard cardBorderRight">
                <div className="cardHeader">Personal Details</div>

                <div className="cardBody">
                  <div className="cardTitle">
                    {userData?.userType === "parent"
                      ? studentName
                      : userData?.username}
                  </div>

                  <div className="imageMask">
                    <img
                      src={TeacherLogo}
                      alt="Teacher Logo"
                      className="teacherLogo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AccessYourClass
        onHideItem={handleHideItem}
        reportStudent={true}
        classId={allData?.classId}
      />

      <div
        className="standardBasedLessons"
        style={{ display: hideItem ? "none" : "block" }}
      >
        <div className="chartHeading">Standard Based Lessons</div>

        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="ReportFont" align="left" width="20%">
                    Subject
                  </TableCell>
                  <TableCell className="ReportFont" align="left">
                    No. of Lessons Attempted
                  </TableCell>
                  <TableCell className="ReportFont" align="left">
                    No. of Lessons Completed
                  </TableCell>
                  <TableCell className="ReportFont" align="left">
                    No. of Lesson Interactions
                  </TableCell>
                  <TableCell className="ReportFont" align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList?.length > 0 ? (
                  <>
                    {categoryList?.map((category) => (
                      <TableRow
                        key={category.categoryName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          width="20%"
                        >
                          {category?.categoryName === "Literacy" ? (
                            <FaSpellCheck className="RepoIcon litColor" />
                          ) : (
                            <FaSquareRootAlt className="RepoIcon matColor" />
                          )}
                          {category?.categoryName}
                        </TableCell>
                        <TableCell align="left">
                          {category?.categoryName === "Literacy"
                            ? litrecyAttempted
                            : mathAttempted}
                        </TableCell>
                        <TableCell align="left">
                          {category?.categoryName === "Literacy"
                            ? litrecyCompleted
                            : mathCompleted}
                        </TableCell>
                        <TableCell align="left">
                          {category?.interactionCount}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            className="btn gradientButtonBlue btnHeight"
                            onClick={() =>
                              handleViewDetails(category?.categoryName)
                            }
                          >
                            {" "}
                            View Details{" "}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      // key={category.categoryName}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        width="20%"
                        style={{ paddingLeft: "4rem" }}
                      >
                        Total
                      </TableCell>
                      <TableCell align="left">
                        {/* {(categoryEpisodeData?.Literacy !==null?litrecyAttempted :0) + (categoryEpisodeData?.Math !==null? mathAttempted : 0)} */}
                        {(litrecyAttempted !== undefined
                          ? litrecyAttempted
                          : 0) +
                          (mathAttempted !== undefined ? mathAttempted : 0)}
                      </TableCell>
                      <TableCell align="left">
                        {categoryEpisodeData !== null
                          ? litrecyCompleted + mathCompleted
                          : 0}
                      </TableCell>
                      <TableCell align="left">{totalInteraction}</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      colspan={5}
                      style={{ height: "10rem", textAlign: "center" }}
                    >
                      {"There is no lesson in progress for this student."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div
        className="GamesMain"
        style={{ display: hideItem ? "none" : "block" }}
      >
        <div className="chartHeading"> Engagement Activity Time </div>
        <div className="progressBox ">
          <div className="barPadding">
            <div className="barPaddingProgress">
              <Bar options={gamesOption} data={gamesData} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="ProgressChart"
        style={{ display: hideItem ? "none" : "block" }}
      >
        <div className="chartHeading">Progress</div>
        <div className="progressBox">
          <div className="barPaddingProgress">
            <Bar options={progressOption} data={prgData} />
          </div>
        </div>
      </div>

      <CustomToastify />
    </Card>
  );
};

export default StudentReport;
