import { combineReducers } from "redux";
import headerReducer from "./Header/HeaderReducer";
import loginReducer from "./Login/LoginReducer";
import teacherReducer from "./Teachers/TeachersReducer";
import classesReducer from "./Classes/ClassesReducer";
import toasterReducer from "./Toaster/ToasterReducer";
import studentReducer from "./Student/StudentReducer";
import lessonsResourcesReducer from "./LessonsResources/LessonsResourcesReducer";
import accountReducer from "./Account/AccountReducer";
import studentLessonReducer from "./Lessons/LessonsReducer";
import lessonReducer from "./Lessons/LessonsReducer";
import helpHubReducer from "./HelpHub/HelpHubReducer";

const rootReducer = combineReducers({
  headerReducer: headerReducer,
  loginReducer: loginReducer,
  teacherReducer: teacherReducer,
  classesReducer: classesReducer,
  toasterReducer: toasterReducer,
  studentReducer: studentReducer,
  lessonsResourcesReducer: lessonsResourcesReducer,
  accountReducer: accountReducer,
  studentLessonReducer: studentLessonReducer,
  lessonReducer: lessonReducer,
  helpHubReducer: helpHubReducer
});

export default rootReducer;
