import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  getSceneDetails,
  setStudentScene,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import { useDispatch, useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import Arrow from "../../assests/images/next-arrow.png";
import {
  faBars,
  faVolumeXmark as faVolumeOff,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import { getStudentReport } from "../../redux/Student/StudentAction";
import { updateSound } from "../../redux/Student/StudentAction";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import OrientationImage from "../../assests/images/orientation_warning.png";
import { logoutUser } from "../../utils/authUtils";

const Slideshow = ({ onNextItem, sceneId, episode_id }) => {
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [showMute, setShowMute] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [lessonId, setLessonId] = useState("");
  const [scale, setScale] = useState(1);
  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [imageURL, setImageUrl] = useState([]);
  const [imageCache, setImageCache] = useState([]);
  const [audioCache, setAudioCache] = useState([]);
  const [disableNext, setDisableNext] = useState(false);

  const userData = useSelector((state) => state.loginReducer?.userData);
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const currentData = data[currentIndex];
  const navigate = useNavigate();
  useEffect(() => {
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      pageTitle.textContent = "Kneoworld Episode Preview";
    }
    const regex = /\/lessons\/(\d+)\//;
    const match = window.location.href.match(regex);
    setLessonId(match[1]);

    const loadSceneDetails = async () => {
      try {
        const res = await dispatch(getSceneDetails(match[1], sceneId, userData?.id));

        if (res?.statusCode === 200) {
          setData(res?.previewSceneDetailsDTO);
          if (userData?.userType === "student") {
            setShowMute(res?.muted);
          }

          const newArray = res?.previewSceneDetailsDTO.map(({ bgImageLocation }) => ({
            bgImageLocation,
          }));
          const audioArray = res?.previewSceneDetailsDTO.map(({ soundLocation }) => ({
            soundLocation,
          }));

          setImageUrl(newArray);

          const loadImage = async (imageUrl) => {
            const response = await fetch(imageUrl.bgImageLocation);
            if (response.ok) {
              const blob = await response.blob();
              return URL.createObjectURL(blob);
            } else {
              console.error(`Failed to load image: ${response.url}`);
              return null;
            }
          };

          const loadAudio = async (audioData) => {
            const response = await fetch(audioData.soundLocation);
            if (response.ok) {
              const audioData = await response.arrayBuffer();
              const audioBlob = new Blob([audioData], { type: "audio/mpeg" });
              return URL.createObjectURL(audioBlob);
            } else {
              console.error(`Failed to load audio: ${response.url}`);
              return null;
            }
          };

          const throttleLimit = 10;

          const initialLoad = async () => {
            const initialImages = newArray.slice(0, throttleLimit);
            const initialAudios = audioArray.slice(0, throttleLimit);

            const [loadedImages, loadedAudios] = await Promise.all([
              throttlePromises(initialImages, loadImage, throttleLimit),
              throttlePromises(initialAudios, loadAudio, throttleLimit),
            ]);

            setImageCache((prev) => [...prev, ...loadedImages.filter(Boolean)]);
            setAudioCache((prev) => [...prev, ...loadedAudios.filter(Boolean)]);
            setLoading(false);

            const remainingImages = newArray.slice(throttleLimit);
            const remainingAudios = audioArray.slice(throttleLimit);

            await Promise.all([
              throttlePromises(remainingImages, loadImage, throttleLimit),
              throttlePromises(remainingAudios, loadAudio, throttleLimit),
            ])
              .then(([remainingLoadedImages, remainingLoadedAudios]) => {
                setImageCache((prev) => [...prev, ...remainingLoadedImages.filter(Boolean)]);
                setAudioCache((prev) => [...prev, ...remainingLoadedAudios.filter(Boolean)]);
              })
              .catch((error) => {
                console.error(`Error loading remaining images and audios: ${error.message}`);
              });
          };

          await initialLoad();
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      } catch (error) {
        console.error(`Error loading scene details: ${error.message}`);
        setLoading(false);
      }
    };

    loadSceneDetails();
  }, []);

  const throttlePromises = async (items, handler, limit) => {
    const results = [];
    const executing = [];

    for (const item of items) {
      const promise = handler(item).then((result) => {
        executing.splice(executing.indexOf(promise), 1);
        return result;
      });
      results.push(promise);
      executing.push(promise);

      if (executing.length >= limit) {
        await Promise.race(executing);
      }
    }

    return Promise.all(results);
  };


  useEffect(() => {
    updateScale(); // Call the function initially to set the initial scale.

    // Update scale whenever the window is resized.
    window.addEventListener("resize", updateScale);
    window.onresize = () => {
      updateScale();
    };

    // Clean up the event listener when the component unmounts.
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  const updateScale = () => {
    const episodeWidth = document.getElementById("episode")?.offsetWidth;
    const episodeHeight = document.getElementById("episode")?.offsetHeight;

    const episodeRatio = episodeWidth / episodeHeight;
    const windowRatio = window.innerWidth / window.innerHeight;

    const scaleFactor =
      episodeRatio < windowRatio
        ? window.innerHeight / episodeHeight
        : window.innerWidth / episodeWidth;
    setScale(scaleFactor);
  };

  useEffect(() => {
    const closeDropdown = () => {
      setShowDropdown(false);
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!showMute && !audioPlayed && currentData?.soundLocation) {
        const audioUrl = audioCache[currentIndex];
        if (audioUrl) {
          const audioElement = new Audio(audioUrl);
          audioRef.current = audioElement;

          const playAudio = async () => {
            try {
              await audioElement.play();
              setAudioPlayed(true);
            } catch (error) {
              console.log("Autoplay blocked:", error);
              setAudioPlayed(false);
            }
          };

          playAudio();
          setShowButton(true);
          setBgImageLoaded(true);
        }
      } else {
        setShowButton(true);
        setBgImageLoaded(true);
      }
    }
  }, [currentIndex, data, loading, audioCache, audioPlayed, showMute]);
  const handleNext = () => {
    setBgImageLoaded(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (currentIndex == data.length - 2 && userData?.userType === "student") {
      setDisableNext(true);
      dispatch(setStudentScene(userData?.id, lessonId, sceneId)).then(
        async (res) => {
          setDisableNext(false);
        }
      );
    }
    if (currentIndex === data.length - 1) {
      onNextItem();
      setAudioPlayed(true);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      !showMute && setAudioPlayed(false);
    }
  };

  const handlePrevious = () => {
    setBgImageLoaded(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
    !showMute && setAudioPlayed(false);
  };

  const openOptions = () => {
    setShowDropdown(!showDropdown);
  };
  const handleReload = () => {
    !showMute && setAudioPlayed(false);
    setShowDropdown(false);
  };

  const handleMuteEpisode = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setAudioPlayed(true);
    setShowDropdown(false);
    setShowMute(!showMute);
    userData?.userType === "student" && handleSound(!showMute);
  };
  const handleReplayEpisode = () => {
    // handleMuteEpisode();
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    // setCurrentIndex(0);
    navigate(`/lessons/${episode_id}/scenes?currentIndex=0`);
    setShowDropdown(false);
    window.location.reload();
  };

  const handleMuteButton = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setAudioPlayed(true);
    setShowMute(!showMute);
    userData?.userType === "student" && handleSound(!showMute);
  };

  const handleBackToMenu = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    userData?.userType === "parent"
      ? navigate(`/dashboard/lesson_groups`)
      : userData?.userType !== "student"
        ? navigate(
          `/dashboard/courses/${courseId}/lesson_groups/${lessonGroupId}`
        )
        : navigate(`/dashboard/lessons`);
  };

  const handleSound = (value) => {
    // setIsLoading(true)
    var dataModel = {
      operationLevel: "student",
      soundMuted: value,
      operationId: userData.id,
    };
    dispatch(updateSound(dataModel)).then(async (res) => { });
  };
  return (
    <>
      {loading && (
        <div className="loaderContainer" style={{ background: "black" }}>
          <div className="spinner" style={{ marginRight: "0rem" }}></div>
        </div>
      )}
      <div className="preview">
        <img className="orientation-warning" src={OrientationImage} />
        <div
          id="episode"
          className="episode episode-scale"
          style={{ transform: `scale(${scale})`, visibility: "visible" }}
        >
          {data.map((value, key) => {
            return (
              <div
                className="slide"
                data-index={key}
                id="episode-slide-23962"
                style={{
                  zIndex: key,
                  display: currentIndex === key ? "block" : "none",
                }}
              >
                <img className="background" src={imageCache[currentIndex]} />
                <div
                  className="component text"
                  style={{
                    left: value?.episodeComponents?.x,
                    top: value?.episodeComponents?.y,
                    zIndex: value?.episodeComponents?.z,
                    display: "block",
                  }}
                >
                  <div
                    className={`bubble tail-${value?.episodeComponents?.tail_location} text-size-${value?.episodeComponents?.text_size} text-width-${value?.episodeComponents?.text_width}`}
                  >
                    {value?.textData}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="episode-controls episode-scale"
          style={{ transform: `scale(${scale})`, visibility: "visible" }}
        >
          <div
            id="btn-start"
            style={{ display: currentIndex !== 0 ? "none" : "block" }}
            onClick={handleNext}
          >
            Start
          </div>
          <div
            className={
              currentIndex === 0
                ? "btn-episode-control episode-prev hidden"
                : "btn-episode-control episode-prev "
            }
          >
            <img src={Arrow} onClick={handlePrevious} />
          </div>
          <div
            className={
              currentIndex === 0
                ? "btn-episode-control episode-next hidden"
                : "btn-episode-control episode-next"
            }
            style={{ pointerEvents: disableNext ? "none" : "auto" }}
          >
            <img src={Arrow} onClick={handleNext} />
          </div>
          <div
            className="mute-icon"
            onClick={handleMuteButton}
            style={{ display: "block" }}
          >
            {showMute ? (
              <FontAwesomeIcon icon={faVolumeOff} />
            ) : (
              <FontAwesomeIcon icon={faVolumeUp} />
            )}
          </div>
          <div
            className={`episode-menu dropup ${showDropdown ? "show" : ""}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              aria-expanded="false"
              aria-haspopup="true"
              className=" btn-episode-menu"
              data-toggle="dropdown"
              onClick={openOptions}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div className={`dropdown-menu  ${showDropdown ? "show" : ""}`}>
              <a onClick={handleReplayEpisode}>Replay</a>
              <a onClick={handleBackToMenu}>Menu</a>
              <a className="mute-episodeon" onClick={handleMuteEpisode}>
                {!showMute ? "Mute   " : "Unmute   "}
                {!showMute ? (
                  <FontAwesomeIcon icon={faVolumeOff} />
                ) : (
                  <FontAwesomeIcon icon={faVolumeUp} />
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slideshow;
