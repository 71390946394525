import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setIsEducator,
  getTokenStatus,
  setResetPassword,
  getParentTokenStatus,
  setParentResetPassword
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";

export default function ResetPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  // const [isEducator, setIsEducator] = React.useState(false);
  const [openForgotPassDialog, setOpenForgotPassDialog] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  const [touched, setTouched] = useState(false);
  const [confirmTouched, setConfirmTouched] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [token, setToken] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errors, setErrors] = React.useState({});
  const [responseCode, setResponseCode] = React.useState("");

  useEffect(() => {
    dispatch(setIsEducator(true));
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    setToken(token);
    setIsLoading(true);
    if (window.location.href.includes("parent")) {
      dispatch(getParentTokenStatus(token)).then(async (res) => {
        if (res?.statusCode === 200) {
          setIsLoading(false);
          setIsReset(true);
        } else {
          setIsLoading(false);
          setIsExpired(true);
        }
      });
    } else {
      dispatch(getTokenStatus(token)).then(async (res) => {
        if (res?.statusCode === 200) {
          setIsLoading(false);
          setIsReset(true);
        } else {
          setIsLoading(false);
          setIsExpired(true);
        }
      });
    }
  }, []);

  const isPasswordValid = (password) => {
    // const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return password.length > 7;
    //  && passwordRegex.test(password);
  };

  var passwordErrorText =
    password.length > 0 && touched && !isPasswordValid(password)
      ? "New Password must be minimum 8 characters long."
      : "";

  const isConfirmPasswordValid = (confirmPassword) => {
    // const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return confirmPassword.length > 7;
    //  && passwordRegex.test(password);
  };

  var confirmPasswordErrorText =
    confirmPassword.length > 0 && confirmTouched
      ? !isConfirmPasswordValid(confirmPassword)
        ? "Confirm Password must be minimum 8 characters long."
        : password === confirmPassword
          ? ""
          : "New Password & Confirm Password do not match."
      : "";

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [password, confirmPassword];
    requireFields = ["password", "confirmPassword"];

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      if (
        password?.length !== 0 &&
        password !== null &&
        !(password?.length > 7)
      ) {
        errors["password"] = "New Password must be minimum 8 characters long.";
      }
      if (
        confirmPassword?.length !== 0 &&
        confirmPassword !== null &&
        !(confirmPassword?.length > 7)
      ) {
        errors["confirmPassword"] =
          "Confirm Password must be minimum 8 characters long.";
      }
      if (
        password?.length > 7 &&
        confirmPassword?.length > 7 &&
        password !== confirmPassword
      ) {
        errors["confirmPassword"] =
          "New Password & Confirm Password do not match.";
        errors["password"] = "";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleResetPassword = (event) => {
    event.preventDefault();

    if (validateForm()) return;

    setIsLoading(true);
    setResponseCode("");
    var dataModel = {
      password,
      confirmPassword,
      token,
    };
    if (window.location.href.includes("parent")) {
      dispatch(setParentResetPassword(dataModel)).then(async (res) => {
        setIsLoading(false);
        if (res?.statusCode === 200) {
          navigate("/login");
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));
      });
    } else {
      dispatch(setResetPassword(dataModel)).then(async (res) => {
        setIsLoading(false);
        if (res?.statusCode === 200) {
          navigate("/login");
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));

        // }
      });
    }

  };

  return (
    <>
      <div>
        {isLoading === true && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div className="loginBg logoTeacher"></div>
        <div className="loginCard">
          <div className="loginDialog">
            <div className="itemCenter">
              <img className="loginLogo" src={LogoImg} alt="KneoWorld-Logo" />
            </div>
            {isExpired && (
              <div>
                <div className="resetMsg mt-1 mb-1">
                  {" "}
                  Reset password token is expired or not valid.
                </div>
                <div>
                  <Link to={`/login`}>
                    <Button
                      className="btn gradientButtonPink resetBtn"
                      fullWidth
                      sx={{ m: 1 }}
                      variant="contained"
                    >
                      Back To Login
                    </Button>
                  </Link>
                </div>
              </div>
            )}
            {isReset && (
              <>
                <div className="loginTitle">Reset Password</div>

                <>
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      required
                      id="outlined-required-1"
                      label="New Password"
                      size="small"
                      value={password}
                      className="mb-0"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      onBlur={() => setTouched(true)}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {errors.password && (
                    <div className="error mb-0">{errors.password}</div>
                  )}
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                      }}
                      required
                      id="outlined-required"
                      label="Confirm Password"
                      size="small"
                      value={confirmPassword}
                      className="mb-0"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      onBlur={() => setConfirmTouched(true)}
                      type={showConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <div className="error mb-0">{errors.confirmPassword}</div>
                  )}
                </>
                <div className="itemSpace">
                  <div className="mr-1">
                    <Button
                      className="btn gradientButtonBlue"
                      fullWidth
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={handleResetPassword}
                    >
                      UPDATE Password
                    </Button>
                  </div>
                  <div>
                    <Link to={`/login`}>
                      <Button
                        className="btn gradientButtonPink"
                        fullWidth
                        sx={{ m: 1 }}
                        variant="contained"
                      >
                        Back To Login
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomToastify />
    </>
  );
}
