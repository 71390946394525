
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const SET_TOASTER = "SET_TOASTER";

export function setToaster(responseCode, message) {
  return {
    type: SET_TOASTER,
    responseCode: responseCode,
    message: message,
  };
}

export function showToastMessage(responseCode, message) {
  return (dispatch) => {
    dispatch(setToaster(responseCode, message));
    if (responseCode === 200) {
      toast.success( message, {
          position: toast.POSITION.TOP_CENTER
      });
      // dispatch(setToaster("", ""))
    }
    else {
      toast.error(message, {
          position: toast.POSITION.TOP_CENTER
      });
  }
  };
}
