export const LOGOUT_MSG = "You've successfully logged out.";
export const COPYRIGHT_MSG = `© ${new Date().getFullYear()} KneoWorld Inc. All rights reserved.`;
export const USER_ROLE_STUDENT = "Student";
export const USER_ROLE_TEACHER = "Teacher";
export const SUPPORT_MSG_FOR_STUDENT =
  "Please contact your teacher if you have any questions or need assistance.";
export const SUPPORT_MSG_FOR_TEACHER_LINE_1 =
  "If you have any questions or need assistance, please contact ";
export const SUPPORT_MSG_FOR_TEACHER_LINE_2 =
  "Include your name and school name in your request to help us follow up with you.";
export const SUPPORT_EMAIL = "support@kneoworld.com";
