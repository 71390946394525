import {
  GET_LESSONS_DATA,
  GET_LESSONS_RESOURCES_DATA,
  GET_LESSONS_CLASS_DATA,
  SET_COURSE_NAME,
  SET_LESSON_NAME,
  SET_LESSON_DESCRIPTION,
  SET_COURSE_ID,
  SET_LESSON_GROUP_ID,
  PUT_APPLY_CHANGES,
  GET_TEACHERS_GUIDE,
  GET_SCENE_DETAILS,
  GET_POSITION_LIST,
  GET_GAMES_DATA,
  SET_STUDENT_SCENE,
  SET_GAME_SCORE,
} from "./LessonsResourcesAction";
import { RESET_STATE } from "../Login/LoginAction";

const initialState = {
  data: "",
  resourceData: "",
  lessonData: "",
  courseName: "",
  lessonName: "",
  lessonDescription: "",
  courseId: "",
  lessonGroupId: "",
  gamesData: "",
  sceneData: "",
  positionData: "",
};

function lessonsResourcesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LESSONS_DATA:
      return (state = {
        ...state,
        resourceData: action.data,
      });
    case GET_LESSONS_RESOURCES_DATA:
      return (state = {
        ...state,
        lessonData: action.data,
      });
    case GET_LESSONS_CLASS_DATA:
      return (state = {
        ...state,
        lessonData: action.data,
      });
    case SET_COURSE_NAME:
      return (state = {
        ...state,
        courseName: action.data,
      });
    case SET_LESSON_NAME:
      return (state = {
        ...state,
        lessonName: action.data,
      });
    case SET_LESSON_DESCRIPTION:
      return (state = {
        ...state,
        lessonDescription: action.data,
      });
    case SET_COURSE_ID:
      return (state = {
        ...state,
        courseId: action.data,
      });
    case SET_LESSON_GROUP_ID:
      return (state = {
        ...state,
        lessonGroupId: action.data,
      });
    case PUT_APPLY_CHANGES:
      return (state = {
        ...state,
        lessonData: action.data,
      });
    case GET_TEACHERS_GUIDE:
      return (state = {
        ...state,
        lessonData: action.data,
      });
    case GET_SCENE_DETAILS:
      return (state = {
        ...state,
        sceneData: action.data,
      });
    case GET_POSITION_LIST:
      return (state = {
        ...state,
        positionData: action.data,
      });
    case GET_GAMES_DATA:
      return (state = {
        ...state,
        gamesData: action.data,
      });
    case SET_STUDENT_SCENE:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_GAME_SCORE:
      return (state = {
        ...state,
        data: action.data,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default lessonsResourcesReducer;
