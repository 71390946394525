import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/addEditTeacher.css";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getAllClassesData } from "../../redux/Classes/ClassesAction";
import "../../styles/components/Adventures.css";
import "./../../styles/main.css";
import AdventureLogo from "../../assests/images/adv.jpg";
import { FaPlay, FaHeadSideVirus} from "react-icons/fa";
import Adventureconfig from "./configAdventures";

const Adventure = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allData, setAllData] = useState([]);
    const [data, setData] = useState([]);
    const [licenseNumber, setLicenseNumber] = useState("");
     const dispatch = useDispatch();
     const [errors, setErrors] = React.useState({});
    const userData = useSelector((state) => state.loginReducer?.userData);

    useEffect(() => {
    });

    return (
        <Card>
            {loading && (
                <div className="loaderContainer">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="itemSpace">
                <div className="pageHeading">Adventures</div>



            </div>
            <div className="pageBreadCrumb">
                <Link to="/dashboard/home">Home</Link> {" > "}
                Free Time Activities {" > "}
                Adventures
            </div>

            {Adventureconfig.map((item, index) => {
                     return (
                        <>
            <div className="adventuresMain ml-3 mb-2 mt-2">

                <div className="adventuresLeft">
                <img
                src={AdventureLogo}
                alt="Adventure Logo"
                className="adventureLogo episodeCardImage"
              />

                </div>

                <div className="adventuresRight"> 

                
                <div className="adventuresHeading">
                <FaHeadSideVirus  className="brainIcon" />   {item.title}             
                </div>

                    <div className="playBtn">
                        <FaPlay className="btn-icon-play    btn-green-wisteria" />
                        <div className="btnLabel">Start</div>
                    </div>
                    

                </div>



            </div>

            </>
                     );
                })}
            <CustomToastify />
        </Card>
    );
};

export default Adventure;