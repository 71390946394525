export const SET_NAVBAR = "SET_NAVBAR";
export const SET_LAST_VISITED = "SET_LAST_VISITED";
export const SET_CLASSROOM_ID = "SET_CLASSROOM_ID";
export const SET_2LAST_VISITED = "SET_2LAST_VISITED";

export function setNavbar(value) {
  return {
    type: SET_NAVBAR,
    data: value,
  };
}
export function setLastVisited(value) {
  return {
    type: SET_LAST_VISITED,
    data: value,
  };
}
export function setSecondLastVisited(value) {
  return {
    type: SET_2LAST_VISITED,
    data: value,
  };
}

export function setClassroomId(value) {
  return {
    type: SET_CLASSROOM_ID,
    data: value,
  };
}
