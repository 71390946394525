import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import rootReducer from "../redux/rootReducer";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk), compose)
);

export const persistor = persistStore(store);
