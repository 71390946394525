import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "../../styles/layouts/header.css";
import Logo from "../../assests/images/Logo_Kneoworld.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataApiSuccess } from "../../redux/Login/LoginAction";
import CustomDialog from "../../utils/CustomDialog";
import PopUpCustomDialog from "./PopUpCustomDialog";
import InputLabel from "@mui/material/InputLabel";
import { setSelectedStudent } from "../../redux/Login/LoginAction";
import DropdownComponent from "../../utils/Dropdown";

const Header = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [selectedStudentId, setSelectedStudentId] = useState("");

  const userData = useSelector((state) => state.loginReducer?.userData);
  const studentDropdown = useSelector(
    (state) => state.loginReducer?.studentDropdown?.dropDownList
  );
  const selectedStudent = useSelector(
    (state) => state.loginReducer?.selectedStudent
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const str = userData?.username;
    const name = str?.split(" ");
    setFirstName(name[0]);
  }, []);

  useEffect(() => {
    if(userData?.userType !== "parent") {return;}
    selectedStudent?.length !== 0 ? setSelectedStudentId(selectedStudent.length>0 && selectedStudent[0]?.id): setSelectedStudentId(studentDropdown?.length>0 && studentDropdown[0]?.id)
    let selectedArray = [];
    selectedArray?.push(studentDropdown[0]);
    selectedStudent?.length == 0 && dispatch(setSelectedStudent(selectedArray))
  }, [studentDropdown, selectedStudent]);

  const handleChange = (e) => {
    setSelectedStudentId(e.target.value);
    dispatch(setSelectedStudent(studentDropdown.filter((student) => student.id === e.target.value)))
    // Get the current location pathname
    const pathname = window.location.pathname;

    // Define the allowed paths
    const allowedPaths = [
      "/dashboard/schools/*/classrooms/*/students/*/assess_report",
      "/dashboard/students/*/lessonreports",
      "/dashboard/students/*/lessonreports/*"
    ];

    // Check if the current pathname matches any of the allowed paths
    const isAllowedPath = allowedPaths.some((path) => {
      const regex = new RegExp("^" + path.replace(/\*/g, "[0-9]+") + "$");
      return regex.test(pathname);
    });

    // Navigate only if the current pathname matches an allowed path
    if (isAllowedPath) {
      navigate("/dashboard/progress");
    }
  };

  const handleUserIconClick = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleDialogClose = () => {
    setPopupOpen(false);
  };

  return (
    <header>
      <div className="itemSpace w100">
        <div className="headerLogo w50">
          <Link to="https://www.kneoworld.com/">
            <div>
              <img src={Logo} className="logoImage" alt="Kneoworld-logo" />
            </div>
          </Link>
        </div>
        <div
          className={
            userData?.userType !== "parent"
              ? "hide"
              : "classDropdownBox itemCenter"
          }
        >
          <div className="centerAlign ">
            <InputLabel className="headerLabel">Choose a student</InputLabel>
          </div>
          <DropdownComponent options={studentDropdown} value={selectedStudentId} handleChange={handleChange} />
        </div>
        <div className="itemEnd w50">
          <div className="WelcomeMsg itemGridCenter">Welcome {firstName}</div>
          <div
            className="user-icon itemGridCenter"
            onClick={handleUserIconClick}
          >
            <FontAwesomeIcon icon={faUser} />
          </div>
        </div>
      </div>
      <div className={
        userData?.userType !== "parent"
          ? "hide dropdownMobile"
          : "dropdownMobile"
      }>
        <div className="centerAlign ">
          <InputLabel className="headerLabel">Choose a Student</InputLabel>
        </div>
        <DropdownComponent options={studentDropdown} value={selectedStudentId} handleChange={handleChange} />
      </div>
      <PopUpCustomDialog open={isPopupOpen} onClose={handleDialogClose} />
    </header>
  );
};

export default Header;
