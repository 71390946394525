import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getParentValidation,
  setSignUp,
  getSignUpTokenVerification,
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { GoogleLogin } from "@react-oauth/google";
import CustomDialog from "../../utils/CustomDialog";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": {
          color: "red",
        },
      },
    },
  },
});

export default function SignUp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(null);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (window.location.pathname.endsWith("/verification_password")) {
      setPageLoad("reset");
      setIsLoading(true);
      dispatch(getSignUpTokenVerification(token)).then(async (res) => {
        setMessage(res?.message);
        setResponseCode(res?.statusCode);
        setIsLoading(false);
      });
    } else {
      setPageLoad("signUp");
    }
  }, []);

  useEffect(() => {
    touched !== null && validateForm();
  }, [touched]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    var data =
      name === "First Name"
        ? setFName(value)
        : name === "Last Name"
          ? setLName(value)
          : name === "Email"
            ? setEmail(value)
            : name === "Password"
              ? setPassword(value)
              : name === "Confirm Password"
                ? setConfirmPassword(value)
                : name === "School Code"
                  ? setSchoolCode(value)
                  : "";
    if (name === "Student ID") {
      const regex = /^[0-9\b]+$/;
      if (value > 0 && regex.test(value) && value?.length < 5) {
        setStudentId(value);
      } else if (value == "" && studentId?.length == 1) {
        setStudentId("");
      } else {
        setStudentId(studentId);
      }
    }
  };

  const handleDialogClose = () => {
    setFName("");
    setLName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setSchoolCode("");
    setStudentId("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    const dataModel = {
      fName,
      lName,
      email,
      password,
      confirmPassword,
      schoolCode,
      studentId,
    };
    setIsLoading(true);
    dispatch(setSignUp(dataModel)).then(async (res) => {
      setIsLoading(false);
      if (res?.statusCode === 200) {
        dispatch(showToastMessage(res?.statusCode, res?.message));
        navigate("/login", { replace: true });
      } else {
        setFName("");
        setLName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setSchoolCode("");
        setStudentId("");
        setTouched(false);
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
    setIsDialogOpen(false);
  };

  const validateForm = (rValues, rFields) => {
    let newErrors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = rValues;
    requireFields = rFields;
    requireValues?.length > 0 &&
      requireValues.forEach((field, index) => {
        !field.trim()
          ? (newErrors[requireFields[index]] = "This field cannot be blank")
          : delete errors[requireFields[index]];
        !(field !== null && field !== "" && field?.length !== 0)
          ? (newErrors[requireFields[index]] = "This field is required")
          : delete errors[requireFields[index]];

        requireFields[index] == "fName" && field.length > 50
          ? (errors[requireFields[index]] =
            "First Name is too long (maximum is 50 characters)")
          : delete errors[requireFields[index]];

        requireFields[index] == "lName" && field.length > 50
          ? (errors[requireFields[index]] =
            "Last Name is too long (maximum is 50 characters)")
          : delete errors[requireFields[index]];

        requireFields[index] == "password" &&
          (password?.length !== 0 &&
            password !== null &&
            !(password?.length > 7)
            ? (errors["password"] =
              "Password must be minimum 8 characters long.")
            : delete errors[requireFields[index]]);

        requireFields[index] == "confirmPassword" &&
          (confirmPassword?.length !== 0 &&
            confirmPassword !== null &&
            !(confirmPassword?.length > 7)
            ? (errors["confirmPassword"] =
              "Confirm Password must be minimum 8 characters long.")
            : delete errors[requireFields[index]]);

        if (
          (requireFields[index] == "confirmPassword" ||
            requireFields[index] == "password") &&
          password?.length > 7 &&
          confirmPassword?.length > 7 &&
          password !== confirmPassword
        ) {
          (requireFields[index] == "confirmPassword" ||
            requireFields[index] == "password") &&
            (password?.length > 7 &&
              confirmPassword?.length > 7 &&
              password !== confirmPassword
              ? (errors["confirmPassword"] =
                "Password & Confirm Password do not match.")
              : delete errors[requireFields[index]]);
          delete errors["password"];
          // errors["password"] = "";
        }

        requireFields[index] == "email" &&
          (email?.length !== 0 && email?.length > 50
            ? (errors["email"] = "Email is too long (maximum is 50 characters)")
            : delete errors[requireFields[index]]);

        requireFields[index] == "email" &&
          (email?.length !== 0 &&
            email !== null &&
            email !== "" &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
            ? (errors["email"] = "Please enter a valid email address")
            : delete errors[requireFields[index]]);
      });

    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors })); // Merge existing errors with new errors
    const mergedErrors = { ...errors, ...newErrors };
    return Object.keys(mergedErrors).length > 0;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (
      validateForm(
        [fName, lName, email, password, confirmPassword, schoolCode, studentId],
        [
          "fName",
          "lName",
          "email",
          "password",
          "confirmPassword",
          "schoolCode",
          "studentId",
        ]
      )
    )
      return;
    const dataModel = {
      fName,
      lName,
      email,
      password,
      confirmPassword,
      schoolCode,
      studentId,
    };
    setIsLoading(true);
    dispatch(getParentValidation(dataModel)).then(async (res) => {
      setIsLoading(false);
      if (res?.statusCode === 200) {
        setDialogMessage(res?.message);
        setIsDialogOpen(true);
      } else {
        setFName("");
        setLName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setSchoolCode("");
        setStudentId("");
        setTouched(false);
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
  };

  return (
    <>
      <div>
        {isLoading && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}
        <div className={"loginBg logoTeacher"}></div>
        <div className="loginCard">
          <div className="loginDialog">
            <div className="centerAlign">
              <img className="loginLogo" src={LogoImg} alt="KneoWorld-Logo" />
            </div>
            <div
              className={
                pageLoad === "reset"
                  ? responseCode === 200
                    ? "centerAlign classFont"
                    : "resetMsg mt-1 mb-1 centerAlign classFont"
                  : "hide"
              }
            >
              {message}
            </div>

            <div className={pageLoad === "signUp" ? "" : "hide"}>
              <div className="loginTitle">Sign Up</div>
              <div className={"loginDisplay"}>
                <>
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      required
                      label="First Name"
                      name="First Name"
                      size="small"
                      value={fName}
                      onBlur={() => validateForm([fName], ["fName"])}
                      onChange={handleChange}
                      className="mb-0"
                    />
                  </div>
                  {errors.fName && (
                    <div className="error mb-0">{errors.fName}</div>
                  )}
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      required
                      label="Last Name"
                      name="Last Name"
                      size="small"
                      value={lName}
                      onBlur={() => validateForm([lName], ["lName"])}
                      onChange={handleChange}
                      className="mb-0"
                    />
                  </div>
                  {errors.lName && (
                    <div className="error mb-0">{errors.lName}</div>
                  )}
                  <div className="itemGridCenter">
                    <ThemeProvider theme={formLabelsTheme}>
                      <TextField
                        fullWidth
                        sx={{
                          m: 1,
                          "& input": {
                            fontFamily: "Raleway !important",
                          },
                        }}
                        className="mb-0"
                        required
                        label="Email"
                        name="Email"
                        // <span className="labelReqField">*</span>
                        size="small"
                        value={email}
                        onBlur={() => validateForm([email], ["email"])}
                        onChange={handleChange}
                        error={emailError !== ""}
                        helperText={emailError}
                      />
                    </ThemeProvider>
                  </div>
                  {errors.email && (
                    <div className="error mb-0">{errors.email}</div>
                  )}
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      className="mb-0"
                      required
                      label="Password"
                      name="Password"
                      size="small"
                      value={password}
                      onChange={handleChange}
                      onBlur={() => validateForm([password], ["password"])}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {errors.password && (
                    <div className="error mb-0">{errors.password}</div>
                  )}
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      className="mb-0"
                      required
                      label="Confirm Password"
                      name="Confirm Password"
                      size="small"
                      value={confirmPassword}
                      onChange={handleChange}
                      onBlur={() =>
                        validateForm([confirmPassword], ["confirmPassword"])
                      }
                      type={showConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <div className="error mb-0">{errors.confirmPassword}</div>
                  )}
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      required
                      label="School Code"
                      name="School Code"
                      size="small"
                      value={schoolCode}
                      onBlur={() => validateForm([schoolCode], ["schoolCode"])}
                      onChange={handleChange}
                      className="mb-0"
                    />
                  </div>
                  {errors.schoolCode && (
                    <div className="error mb-0">{errors.schoolCode}</div>
                  )}
                  <div className="itemGridCenter">
                    <TextField
                      fullWidth
                      sx={{
                        m: 1,
                        "& input": {
                          fontFamily: "Raleway !important",
                        },
                      }}
                      required
                      label="Student ID"
                      name="Student ID"
                      size="small"
                      value={studentId}
                      onBlur={() => validateForm([studentId], ["studentId"])}
                      onChange={handleChange}
                      className="mb-0"
                    />
                  </div>
                  {errors.studentId && (
                    <div className="error mb-0">{errors.studentId}</div>
                  )}
                </>

                <div className="LoginButton">
                  <Button
                    className="loginBtn"
                    fullWidth
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={handleLogin}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>

            <div className="itemCenter">
              <div className={pageLoad === "reset" ? "" : "hide"}>
                <Link to={`/login`}>
                  <Button
                    className="btn gradientButtonPink"
                    fullWidth
                    sx={{ m: 1 }}
                    variant="contained"
                  >
                    Back To Login
                  </Button>
                </Link>
              </div>
              <div className={pageLoad === "signUp" ? "" : "hide"}>
                Already have an account?&nbsp;
                <Link to="/login">
                  <span className="link">Sign In</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomToastify />

      <CustomDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Sign Up"
        content={dialogMessage}
        learnosity={false}
        signUp={true}
      />
    </>
  );
}
