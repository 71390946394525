import {
  GET_CLASSES_DATA,
  GET_ALL_CLASSES_DATA,
  GET_CLASS_DATA_BY_ID,
  GET_TEACHER_LIST,
  SET_SAVE_CLASS,
  SET_UPDATE_CLASS,
  DELETE_CLASS_DATA,
  GET_ASSESS_YOUR_CLASS_DATA,
  GET_ASSESS_REPORT_STUDENT,
  GET_CLASS_ASSESS_REPORT,
  GET_CLASS_REPORT,
  SET_REPORT_UNIT_NAME,
  SET_REPORT_RESPONSE,
  SET_SCORING_RESPONSE,
  SET_LESSON_GROUP_ID,
  SET_LESSON_INFO,
} from "./ClassesAction";
import { RESET_STATE } from "../Login/LoginAction";

const initialState = {
  data: "",
  studentReport: null,
  loading: false,
  assessClassData: "",
  classAssessReport: "",
  classReport: "",
  reportUnitName: "",
  reportResponse: "",
  scoreResponse: "",
  lessonGroupId: "",
};

function classesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLASSES_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_ALL_CLASSES_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_CLASS_DATA_BY_ID:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_TEACHER_LIST:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_SAVE_CLASS:
      return (state = {
        ...state,
        data: action.data,
      });
    case SET_UPDATE_CLASS:
      return (state = {
        ...state,
        data: action.data,
      });
    case DELETE_CLASS_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case GET_ASSESS_YOUR_CLASS_DATA:
      return (state = {
        ...state,
        assessClassData: action.data,
      });
    case GET_ASSESS_REPORT_STUDENT:
      return (state = {
        ...state,
        studentReport: action.data,
      });
    case GET_CLASS_REPORT:
      return (state = {
        ...state,
        classReport: action.data,
      });

    case GET_CLASS_ASSESS_REPORT:
      return (state = {
        ...state,
        classAssessReport: action.data,
      });
    case SET_REPORT_UNIT_NAME:
      return (state = {
        ...state,
        reportUnitName: action.data,
      });
    case SET_REPORT_RESPONSE:
      return (state = {
        ...state,
        reportResponse: action.data,
      });
    case SET_SCORING_RESPONSE:
      return (state = {
        ...state,
        scoreResponse: action.data,
      });
    case SET_LESSON_GROUP_ID:
      return (state = {
        ...state,
        lessonGroupId: action.data,
      });

    case SET_LESSON_INFO:
      return (state = {
        ...state,
        data: action.data,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default classesReducer;
