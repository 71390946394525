import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header from "../Layouts/Header";
import { Navigate } from "react-router-dom";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer/Footer";
import ScrollToTop from "../utils/ScrollToTop";

const StyledRoot = styled("div")({
  display: "block",
  minHeight: "100vh",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  height: "100vh",
  overflow: "hidden",
  display: "block",
  flexDirection: "column",
}));

export default function DashboardLayout({ children }) {
  const isLoggedIn = () => {
    const data = localStorage.getItem("AuthToken");
    return data != null;
  };
  return (
    <StyledRoot>
      <Main>
        {isLoggedIn() ? (
          <>
            <div>
              <Header />
            </div>
            <div className="dashboard-content-wrapper">
              <Navbar />
              <div className="dashboard-content">
                <ScrollToTop />
                <Outlet />
                <Footer />
              </div>
            </div>
          </>
        ) : (
          <Navigate to={"/login"} />
        )}
      </Main>
    </StyledRoot>
  );
}
