import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_CLASSES_DATA = "GET_CLASSES_DATA";
export const GET_ALL_CLASSES_DATA = "GET_ALL_CLASSES_DATA";
export const GET_CLASS_DATA_BY_ID = "GET_CLASS_DATA_BY_ID";
export const GET_TEACHER_LIST = "GET_TEACHER_LIST";
export const SET_SAVE_CLASS = "SET_SAVE_CLASS";
export const SET_UPDATE_CLASS = "SET_UPDATE_CLASS";
export const DELETE_CLASS_DATA = "DELETE_CLASS_DATA";
export const GET_ASSESS_YOUR_CLASS_DATA = "GET_ASSESS_YOUR_CLASS_DATA";
export const GET_ASSESS_REPORT_STUDENT = "GET_ASSESS_REPORT_STUDENT";
export const GET_CLASS_REPORT = "GET_CLASS_REPORT";
export const GET_CLASS_ASSESS_REPORT = "GET_CLASS_ASSESS_REPORT";
export const SET_REPORT_UNIT_NAME = "SET_REPORT_UNIT_NAME";
export const SET_REPORT_RESPONSE = "SET_REPORT_RESPONSE";
export const SET_SCORING_RESPONSE = "SET_SCORING_RESPONSE";
export const SET_LESSON_GROUP_ID = "SET_LESSON_GROUP_ID";
export const SET_LESSON_INFO = "SET_LESSON_INFO";

export const getClassesData = (schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/classes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getClassesDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getAllClassesData = (schoolId, userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/classrooms/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getAllClassesDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getClassDataById = (schoolId, classId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/classrooms/${classId}/getClassDetails`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getClassDataByIdApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getTeacherList = (schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/schools/${schoolId}/teachersList`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getTeacherListApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setSaveClass = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      class_id: "0",
      grade: data.grade,
      name: data.className,
      schoolId: data.schoolId,
      selectedTeachers: data.right,
    };
    axios
      .post(`${BASE_URL}/api/v1/users/AddClass`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setSaveClassApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const setUpdateClass = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      class_id: parseInt(data.classroomId),
      grade: data.grade,
      name: data.className,
      schoolId: data.schoolId,
      selectedTeachers: data.right,
    };
    axios
      .post(`${BASE_URL}/api/v1/users/EditClass`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setUpdateClassApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const deleteClassData = (schoolId, classId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/classroom/${classId}/deleteClass`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(deleteClassDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getAssessYourClass = (schoolId, classId, flag) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/classroom/${classId}/prePostBoard?flag=${flag}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getAssessYourClassApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getAccessReportForStudents = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      lessonId: data.lessonId,
      postSessionId: data.postSessionId == "" ? null : data.postSessionId,
      preSessionId: data.preSessionId == "" ? null : data.preSessionId,
      referenceId: data.referenceId,
      status: data.status,
      studentId: data.studentId,
      teacherId: data.teacherId,
      type: data.type,
    };

    axios
      .post(
        `${BASE_URL}/api/v1/users/learnosity/viewReportProgress`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(setAccessReportForStudentsApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getClassWiseAssessReport = (classId, lessonId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/learnosity/classroom/${classId}/lessonId/${lessonId}/classReportProgress`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getAssessClassReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getScoringKeyReport = (lessonId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/learnosity/lessonId/${lessonId}/scoringKeyLegend`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getScoringResponse(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getLessonInfo = (lessonId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/users/lessonId/${lessonId}/lessonInfo`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getLessonInfoResponse(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export function getLessonInfoResponse(value) {
  return {
    type: SET_LESSON_INFO,
    data: value,
  };
}

export function getScoringResponse(value) {
  return {
    type: SET_SCORING_RESPONSE,
    data: value,
  };
}

export function setLessonGroupID(value) {
  return {
    type: SET_LESSON_GROUP_ID,
    data: value,
  };
}

export function setReportResponse(value) {
  return {
    type: SET_REPORT_RESPONSE,
    data: value,
  };
}

export function setReportUnitName(value) {
  return {
    type: SET_REPORT_UNIT_NAME,
    data: value,
  };
}
export const setClassWiseAssessReportApiSuccess = (value) => {
  return {
    type: GET_CLASS_ASSESS_REPORT,
    data: value,
  };
};

export const getAssessClassReportApiSuccess = (value) => {
  return {
    type: GET_CLASS_REPORT,
    data: value,
  };
};

export const getAssessYourClassApiSuccess = (value) => {
  return {
    type: GET_ASSESS_YOUR_CLASS_DATA,
    data: value,
  };
};

export const setAccessReportForStudentsApiSuccess = (value) => {
  return {
    type: GET_ASSESS_REPORT_STUDENT,
    data: value,
  };
};

export const getClassesDataApiSuccess = (value) => {
  return {
    type: GET_CLASSES_DATA,
    data: value,
  };
};

export const getAllClassesDataApiSuccess = (value) => {
  return {
    type: GET_ALL_CLASSES_DATA,
    data: value,
  };
};

export const getClassDataByIdApiSuccess = (value) => {
  return {
    type: GET_CLASS_DATA_BY_ID,
    data: value,
  };
};

export const getTeacherListApiSuccess = (value) => {
  return {
    type: GET_TEACHER_LIST,
    data: value,
  };
};

export const setSaveClassApiSuccess = (value) => {
  return {
    type: SET_SAVE_CLASS,
    data: value,
  };
};

export const setUpdateClassApiSuccess = (value) => {
  return {
    type: SET_UPDATE_CLASS,
    data: value,
  };
};

export const deleteClassDataApiSuccess = (value) => {
  return {
    type: DELETE_CLASS_DATA,
    data: value,
  };
};
