import { GET_LESSON_DATA, SET_STUDENT_PLAY } from "./LessonsAction";
import { RESET_STATE } from "../Login/LoginAction";

const initialState = {
  data: "",
  lessonData: "",
  studentPlay: false,
};

function lessonReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LESSON_DATA:
      return (state = {
        ...state,
        lessonData: action.data,
      });
    case SET_STUDENT_PLAY:
      return (state = {
        ...state,
        studentPlay: action.data,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default lessonReducer;
