import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getStudentValidation,
  setAddChild,
  getStudentDropdown,
} from "../../redux/Login/LoginAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import CustomDialog from "../../utils/CustomDialog";

import { Card } from "@material-ui/core";
export default function AddChild(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  const [errors, setErrors] = React.useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const userData = useSelector((state) => state.loginReducer?.userData);

  const handleChange = (event) => {
    const { value, name } = event.target;
    name === "School Code" && setSchoolCode(value);
    if (name === "Student ID") {
      const regex = /^[0-9\b]+$/;
      if (value > 0 && regex.test(value) && value?.length < 5) {
        setStudentId(value);
      } else if (value == "" && studentId?.length == 1) {
        setStudentId("");
      } else {
        setStudentId(studentId);
      }
    }
  };

  const handleDialogClose = () => {
    setSchoolCode("");
    setStudentId("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    const dataModel = {
      schoolCode,
      studentId,
    };
    setIsLoading(true);
    dispatch(setAddChild(dataModel, userData?.id)).then(async (res) => {
      setIsLoading(false);
      if (res?.statusCode === 200) {
        dispatch(showToastMessage(res?.statusCode, res?.message));
        dispatch(getStudentDropdown(userData?.id)).then((res) => {
          if (res?.statusCode === 200) {
            navigate("/dashboard/children");
          }
        });
      } else {
        setSchoolCode("");
        setStudentId("");
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
    setIsDialogOpen(false);
  };

  const validateForm = (rValues, rFields) => {
    let newErrors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = rValues;
    requireFields = rFields;
    requireValues?.length > 0 &&
      requireValues.forEach((field, index) => {
        !field.trim()
          ? (newErrors[requireFields[index]] = "This field cannot be blank")
          : delete errors[requireFields[index]];
        !(field !== null && field !== "" && field?.length !== 0)
          ? (newErrors[requireFields[index]] = "This field is required")
          : delete errors[requireFields[index]];
      });

    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors })); // Merge existing errors with new errors
    const mergedErrors = { ...errors, ...newErrors };
    return Object.keys(mergedErrors).length > 0;
  };

  const handleAddChild = (event) => {
    event.preventDefault();
    if (validateForm([schoolCode, studentId], ["schoolCode", "studentId"]))
      return;
    const dataModel = {
      schoolCode,
      studentId,
    };
    setIsLoading(true);
    dispatch(getStudentValidation(dataModel)).then(async (res) => {
      setIsLoading(false);
      if (res?.statusCode === 200) {
        setDialogMessage(res?.message);
        setIsDialogOpen(true);
      } else {
        setSchoolCode("");
        setStudentId("");
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
  };

  return (
    <>
      <Card>
        {isLoading && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div className="pageHeading"> Add child</div>
        <div className="pageBreadCrumb">
          <Link to="/dashboard/lesson_groups">Lessons</Link> {" > "}
          <Link to="/dashboard/children">Children</Link> {" > "}
          Add Child
        </div>
        <div className="changePasswordMain">
          <div className="inputLabel">
            School Code<span className="labelReqField">*</span>
          </div>
          <div className="textFeildWidth">
            <TextField
              sx={{
                m: 1,
                "& input": {
                  fontFamily: "Raleway !important",
                },
              }}
              required
              id="outlined-required-1"
              placeholder="School Code"
              name="School Code"
              size="small"
              className="mb-0"
              value={schoolCode}
              onBlur={() => validateForm([schoolCode], ["schoolCode"])}
              onChange={handleChange}
            />
            {errors.schoolCode && (
              <div className="error mb-0">{errors.schoolCode}</div>
            )}
          </div>

          <div className="inputLabel">
            Student ID<span className="labelReqField">*</span>
          </div>
          <div className="textFeildWidth">
            <TextField
              sx={{
                m: 1,
                "& input": {
                  fontFamily: "Raleway !important",
                },
              }}
              required
              placeholder="Student ID"
              name="Student ID"
              size="small"
              value={studentId}
              className="mb-0"
              onBlur={() => validateForm([studentId], ["studentId"])}
              onChange={handleChange}
            />
            {errors.studentId && (
              <div className="error mb-0">{errors.studentId}</div>
            )}
          </div>

          <div className="itemSpace mr-1">
            <Button
              className="btn gradientButtonBlue"
              onClick={handleAddChild}
              style={{ width: "fit-content" }}
            >
              Add Child
            </Button>
          </div>
        </div>
      </Card>
      <CustomToastify />
      <CustomDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Sign Up"
        content={dialogMessage}
        learnosity={false}
        signUp={true}
      />
    </>
  );
}
