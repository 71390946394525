import React, { useState, useEffect, useRef } from "react";
// import {
//   Autocomplete,
//   Button,
//   Checkbox,
//   TextField,
//   Card,
//   CardContent,
//   IconButton
// } from "@mui/material";
// import { makeStyles } from "@material-ui/core";
import { useNavigate, Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../../styles/components/addEditTeacher.css";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import {
  saveUpdateTeacherData,
  getTeacherDataById,
  activeInactiveTeacher,
} from "../../redux/Teachers/TeachersAction";
import { getClassesData } from "../../redux/Classes/ClassesAction";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  sendEmailForgotPassword,
  setIsEducator,
} from "../../redux/Login/LoginAction";
import Tooltip from "@material-ui/core/Tooltip";
import { FaInfo, FaInfoCircle } from "react-icons/fa";
import { logoutUser } from "../../utils/authUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#ededed",
    },
  })
);
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const AddEditTeacher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oldEmail, setOldEmail] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [systemPassword, setSystemPassword] = useState(false);
  const [schoolId, setSchoolId] = useState(0);
  const [teacherId, setTeacherId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [errors, setErrors] = React.useState({});

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = React.useState(classList);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [responseCode, setResponseCode] = React.useState("");
  const [muted, setMuted] = useState(true);
  const [mutedState, setMutedState] = useState(0);
  const [isEditChange, setIsEditChange] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  // const [message, setMessage] = React.useState("");
  // const [responseCode, setResponseCode] = React.useState("");
  const fNameRef = useRef(null);
  const lNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const userData = useSelector((state) => state.loginReducer?.userData);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setIsEditChange(true);
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setIsEditChange(true);
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    scrollToError();
  }, [errors]);

  const scrollToError = (event) => {
    if (errors.firstName) {
      fNameRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.lastName) {
      lNameRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.email) {
      emailRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.password) {
      passwordRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!isEditMode) {
      if (validateForm()) return;
    } else {
      if (!isEditChange) {
        dispatch(showToastMessage(201, "No fields have been changed."));
        return;
      } else if (validateEditForm()) return;
    }
    setIsLoading(true);
    // if (!validateForm()) {
    // }
    var dataModel = {
      email,
      firstName,
      lastName,
      password,
      sendWelcomeEmail,
      right,
      oldEmail,
      schoolId,
    };
    dispatch(saveUpdateTeacherData(dataModel)).then(async (res) => {
      setErrorOpen(true);
      setIsLoading(false);
      if (res?.statusCode === 200) {
        dispatch(
          showToastMessage(
            res?.statusCode,
            isEditMode ? res?.message : "Teacher added Successfully"
          )
        );
        navigate(`/dashboard/teachers`);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      } else {
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }

      setFirstName("");
      setLastName("");
      setOldEmail(null);
      setPassword("");
      setEmail("");
      setSendWelcomeEmail(true);
      setRight([]);
      setLeft(classList);
      setSystemPassword(false);
      // }
    });

    //  window.location.href
    // Submit form logic
  };

  useEffect(() => {
    const containsEditTeacher = window.location.href.includes("edit");
    containsEditTeacher ? setIsEditMode(true) : setIsEditMode(false);
    const regex = containsEditTeacher
      ? /\/schools\/(\d+)\/teachers\/(\d+)/
      : /\/schools\/(\d+)\/teachers\//;
    const match = window.location.href.match(regex);
    if (containsEditTeacher) {
      setSchoolId(match[1]);
      setTeacherId(match[2]);
    } else {
      setSchoolId(match[1]);
    }
    if (!containsEditTeacher) {
      dispatch(getClassesData(match[1])).then(async (res) => {
        // if (res?.statusCode === 200) {
        var classOptions = [];
        res?.classRoomList?.map((value) => {
          classOptions.push(value.name);
        });
        setClassList(classOptions);
        setLeft(classOptions);
        // }
      });
    } else {
      dispatch(getTeacherDataById(match[1], match[2])).then(async (res) => {
        if (res?.statusCode === 200) {
          if (res.roster_name === "edlink") {
            setIsDisabled(true);
          }
          setFirstName(res.firstname);
          setLastName(res.lastname);
          setOldEmail(res.email);
          setPassword(null);
          setEmail(res.email);
          if (res.status == 0) {
            setMuted(false);
          } else {
            setMuted(true);
          }

          var selectclasses = [];
          var availableclasses = [];
          res?.availableClasses.map((value) => {
            selectclasses.push(value.name);
          });
          setLeft(selectclasses);
          res?.selectedClasses.map((value) => {
            availableclasses.push(value.name);
          });
          setRight(availableclasses);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      });
    }
  }, []);

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [firstName, lastName, email, password];
    requireFields = ["firstName", "lastName", "email", "password"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }

      if (requireFields[index] == "firstName" && field.length > 50) {
        errors[requireFields[index]] =
          "First Name is too long (maximum is 50 characters)";
      }

      if (requireFields[index] == "lastName" && field.length > 50) {
        errors[requireFields[index]] =
          "Last Name is too long (maximum is 50 characters)";
      }

      if (
        password?.length !== 0 &&
        password !== null &&
        !(password?.length > 7)
      ) {
        errors["password"] = "Password must be minimum 8 characters long.";
      }
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (email?.length !== 0 && email?.length > 50) {
        errors["email"] = "Email is too long (maximum is 50 characters)";
      } else if (
        email?.length !== 0 &&
        email !== null &&
        email !== "" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        // !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
      ) {
        errors["email"] = "Please enter a valid email address";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const validateEditForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [firstName, lastName, email];
    requireFields = ["firstName", "lastName", "email"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      if (requireFields[index] == "firstName" && field.length > 50) {
        errors[requireFields[index]] =
          "First Name  is too long (maximum is 50 characters)";
      }

      if (requireFields[index] == "lastName" && field.length > 50) {
        errors[requireFields[index]] =
          "Last Name  is too long (maximum is 50 characters)";
      }

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (email?.length !== 0 && email?.length > 50) {
        errors["email"] = "Email is too long (maximum is 50 characters)";
      } else if (
        email?.length !== 0 &&
        email !== null &&
        email !== "" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        // !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
      ) {
        errors["email"] = "Please enter a valid email address";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };
  const CardSelector = (title, items, side) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card
        style={{
          display: "contents",
          height: "25rem",
        }}
        component={Paper}
      >
        <CardHeader
          className={"classes.cardHeader stickyHeader"}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
          action={
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        />
        <Collapse in={expanded} className={classes.collapse}>
          <List className={classes.list} dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Collapse>
      </Card>
    );
  };

  const handleChange = (e) => {
    if (isEditMode) {
      setIsEditChange(true);
    }
    const { name, value } = e.target;
    var data =
      name === "email"
        ? setEmail(value)
        : name === "firstName"
        ? setFirstName(value)
        : name === "lastName"
        ? setLastName(value)
        : name === "password"
        ? setPassword(value)
        : name === "sendWelcomeEmail"
        ? setSendWelcomeEmail(value)
        : "";
  };

  const handleCheckBoxChange = (e) => {
    // var checked = (e.target.checked);
    setSystemPassword(e.target.checked);

    const char_set =
      "abcdefghijlkmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    function max_random_number(max) {
      return Math.floor(Math.random() * max);
    }

    function get_random_string(length) {
      let random_string = "";
      for (let i = 0; i < length; i++) {
        random_string += char_set[max_random_number(char_set.length - 1)];
      }

      return random_string;
    }
    e.target.checked ? setPassword(get_random_string(12)) : setPassword("");
  };

  const handleChangePassword = (e) => {
    if (systemPassword === true) {
      setSystemPassword(false);
      setPassword(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleRecoverPassword = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setResponseCode("");
    // setTimeout(() => { setResponseCode(""); }, 3000);
    dispatch(sendEmailForgotPassword(email)).then(async (res) => {
      setIsLoading(false);

      if (res?.statusCode === 200) {
        // navigate(`/login`)
      } else {
      }
      dispatch(showToastMessage(res?.statusCode, res?.message));
    });
  };

  const handleClick = (event) => {
    const muted = event.target.checked;
    setMuted(muted);

    var muteState = 0;
    if (muted === true) {
      muteState = 1;
    } else {
      // alert(muteState)
      // setMutedState(0);
      muteState = 0;
    }
    var dataModel = {
      operationLevel: "Teacher",
      isActive: muteState,
      operationId: parseInt(teacherId),
    };

    dispatch(activeInactiveTeacher(dataModel)).then(async (res) => {
      // setLoading(false);
      if (res?.statusCode === 200) {
        if (!muted === true) {
          dispatch(
            showToastMessage(
              res?.statusCode,
              "You have turned Inactive for the teacher " + firstName
            )
          );
        } else {
          dispatch(
            showToastMessage(
              res?.statusCode,
              "You have turned Active for the teacher " + firstName
            )
          );
        }
      } else {
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    });
  };
  return (
    <Card sx={{ m: 1, p: 4 }}>
      {isLoading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}

      <div className="pageHeading">
        {isEditMode ? "Edit Teacher" : "New Teacher"}
      </div>

      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/teachers">Teachers</Link> {" > "}
        {isEditMode ? "Edit Teacher" : " New Teacher"}
      </div>

      <form className="form " onSubmit={handleFormSubmit}>
        <div className="formContents f-wrap">
          <div className="teacherSection logoContainer">
            <div className="teacherLogoHeading">Teacher Picture</div>
            <div>
              <img
                src={TeacherLogo}
                alt="Teacher Logo"
                className="teacherLogo"
              />
            </div>
          </div>
          <div className="detailSection f1">
            <div>
              <div className="textInputResponsive inputRow">
                <div className="inputBox f1 inputContainer" ref={fNameRef}>
                  <label className="inputLabel">
                    First Name<span className="labelReqField">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className={`input ${errors.firstName ? "errorInput" : ""}`}
                    id="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={handleChange}
                    disabled={isDisabled}
                  ></input>
                  {errors.firstName && (
                    <div className="error">{errors.firstName}</div>
                  )}
                </div>
                <div className="inputBox f1 inputContainer" ref={lNameRef}>
                  <label className="inputLabel">
                    Last Name<span className="labelReqField">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className={`input ${errors.lastName ? "errorInput" : ""}`}
                    id="firstName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={handleChange}
                    disabled={isDisabled}
                  ></input>
                  {errors.lastName && (
                    <div className="error">{errors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="textInputResponsive inputRow">
                <div className="inputBox f1 inputContainer" ref={emailRef}>
                  <label className="inputLabel">
                    Email<span className="labelReqField">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={`input ${errors.email ? "errorInput" : ""}`}
                    id="firstName"
                    placeholder="user@mail.com"
                    value={email}
                    onChange={handleChange}
                    disabled={isDisabled}
                    novalidate
                  ></input>
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>
                {isEditMode ? (
                  <div className="inputBox f1 pt-2 inputContainer">
                    <Button
                      className={
                        isDisabled
                          ? "hide"
                          : "btn gradientButtonPink btnWidth btnFont min-h-3"
                      }
                      onClick={handleRecoverPassword}
                    >
                      Send email for password recovery
                    </Button>
                  </div>
                ) : (
                  <div className="inputBox f1 inputContainer" ref={passwordRef}>
                    <label className="inputLabel">
                      Create a Password<span className="labelReqField">*</span>
                    </label>
                    <input
                      placeholder="Password"
                      className={`input mb-0 ${
                        errors.password ? "errorInput" : ""
                      }`}
                      name="password"
                      id="firstName"
                      // type="password"
                      value={password}
                      onChange={handleChangePassword}
                    />
                    {errors.password && (
                      <div className="error mb-0">{errors.password}</div>
                    )}
                    <div className="checkboxContainer">
                      <Checkbox
                        style={{ color: "#008000 !important" }}
                        checked={systemPassword}
                        onChange={handleCheckBoxChange}
                        // onChange={(e) => setSystemPassword(e.target.checked)}
                      />
                      Use a System Generated Password
                    </div>
                  </div>
                )}{" "}
              </div>
              {/* {isEditMode ?
                  <div className="switchContainer">
                    <label className="inputLabel">Status (Active/Inactive)</label>
                    <br/>
                    <label className="switch ml-1 mb-1">
                      <input type="checkbox" checked={muted} onClick={handleClick} />
                      <span className="slider"></span>
                      <div className="text on"></div>
                      <div className="text off"></div>
                    </label></div>
                  :

                  ""
                } */}
            </div>

            {/* <div> */}
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className="pt-1 "
            >
              <Grid item className="leftGridCard">
                <Paper elevation={3} className="classesList">
                  {CardSelector("Classes Available", left, "left")}
                </Paper>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className="arrowButton"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item className="rightGridCard">
                <Paper elevation={3} className="classesList">
                  {CardSelector("Selected Classes", right, "right")}
                </Paper>
              </Grid>
            </Grid>
            {!isEditMode && (
              <div className="checkboxContainer mt-1">
                <Checkbox
                  style={{ color: "#008000 !important" }}
                  checked={sendWelcomeEmail}
                  onChange={(e) => setSendWelcomeEmail(e.target.checked)}
                />

                <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  Send a welcome email
                </div>

                <Tooltip
                  title={
                    "The password along with some instructions will be sent to the email address associated with this teacher account to help the teacher get started."
                  }
                >
                  <div>
                    <FaInfo className="btn-icon-info   btn-orange-casablanca btn-vertical-align" />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="itemEnd">
          <Button
            className="btn gradientButtonBlue mr-1"
            type="submit"
            formNoValidate
          >
            {isEditMode ? "Update Teacher" : "Save Teacher"}
          </Button>
          <Link to={`/dashboard/teachers`}>
            <Button className="btn gradientButtonOrange">Cancel</Button>
          </Link>
        </div>
      </form>
      <CustomToastify isEditMode={isEditMode} updateTeacher={true} />
    </Card>
  );
};

export default AddEditTeacher;
