import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import "../../styles/main.css";
import "../../styles/components/myTeachers.css";
import { FaTrash, FaEdit, FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  getTeacherData,
  deleteTeacherData,
} from "../../redux/Teachers/TeachersAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { tableIcons, options, Search } from "../../utils/TableMui";
import Tooltip from "@material-ui/core/Tooltip";
import CustomDialog from "../../utils/CustomDialog";
import { logoutUser } from "../../utils/authUtils";

const Teachers = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [noOfTeachers, setNoOfTeachers] = useState(0);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef();
  
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    userData?.school_id !== undefined &&
      dispatch(getTeacherData(userData?.school_id)).then(async (res) => {
        if (res?.statusCode === 200) {
          const updatedUserDetails = res?.userDetails?.map((user) => ({
            ...user,
            className: user?.classes?.map((classObj) => classObj.name),
          }));

          setData(updatedUserDetails);
          setNoOfTeachers(res?.totalNoOfUsers);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
        setIsLoading(false);
      });
  }, [userData]);

  const columns = [
    {
      title: "Name",
      field: "userName",
      render: (rowData) => (
        <div>
          <FaUser style={{ fontSize: 18 }} />
          {/* <img src={rowData.profilePicture} alt={rowData.Name} style={{ width: 30, borderRadius: '50%' }} /> */}
          <span style={{ marginLeft: 5 }}>{rowData?.userName}</span>
        </div>
      ),
      // headerStyle: { width: "40%" },
      // cellStyle: { width: "40%" },
      sorting: data?.length > 0,
    },
    {
      title: "Classes",
      field: "className",
      render: (rowData) => (
        <div>
          {rowData.classes?.map((className, index) => (
            <Link
              to={`/dashboard/schools/${userData?.school_id}/classrooms/${className.id}`}
            >
              <button className="btn gradientButtonBlue btnTable btnResponsive">
                {className.name}
              </button>
            </Link>
          ))}
        </div>
      ),
      sorting: data?.length > 0,
    },
    {
      title: "Actions",
      render: (rowData) => (
        <div>
          <Tooltip title={"Edit"}>
            <Link
              to={`/dashboard/schools/${userData?.school_id}/teachers/${rowData.id}/edit`}
            >
              <div className="btn-action">
                <FaEdit className="btn-icon   btn-purple-wisteria" />
              </div>
            </Link>
          </Tooltip>

          {rowData.roster_name !== "edlink" && (
            <Tooltip title={"Delete"}>
              <div className="btn-action">
                <FaTrash
                  className="btn-icon"
                  onClick={() => handleDelete(rowData.id, rowData.userName)}
                />
              </div>
            </Tooltip>
          )}
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
        // gridTemplateColumns: "1fr 2fr",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
        // gridTemplateColumns: "1fr 2fr",
      },
      // width: 100,
      // maxWidth: 100,
      gridTemplateColumns: "1fr 2fr",
      sorting: false,
    },
  ];

  const handleDelete = (id, name) => {
    setDeleteId(id);
    setMessage("Do you want to delete " + name + " ?");
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    dispatch(deleteTeacherData(userData?.school_id, deleteId)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          dispatch(getTeacherData(userData?.school_id)).then(async (res) => {
            if (res?.statusCode === 200) {
              setData(res?.userDetails);
              setNoOfTeachers(res?.totalNoOfUsers);
              setIsLoading(false);
            }
          });
          setErrorOpen(true);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));
      }
    );
    setIsDialogOpen(false);
  };

  const tableTitle = (
    <div className="TableTitle">No. of teachers : {noOfTeachers}</div>
  );

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Card sx={{ m: 1, p: 4 }}>
        {isLoading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}
        <div ref={tableRef}></div>
        <div className="top-container">
          <div className="heading-container">
            <div className="pageHeading">Teachers</div>

            <div className="pageBreadCrumb">
              <Link to="/dashboard/home">Home</Link> {" > "}
              Teachers
            </div>
          </div>
          <div className="buttons-wrapper">
            <Link to={`/dashboard/schools/${userData?.school_id}/teachers/new`}>
              <Button className="btn gradientButtonPink btnResponsive m1">
                Add Teacher
              </Button>
            </Link>
          </div>
        </div>
        <div className="MuiTable">
          <MaterialTable
            title={tableTitle}
            className="myTable"
            icons={tableIcons}
            data={data}
            columns={columns}
            options={options}
            onChangePage={handlePageChange}
            // isLoading={isLoading}
          />
        </div>
        <CustomToastify />
        <CustomDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleDialogConfirm}
          title="Delete Teacher"
          content={message}
          learnosity={false}
        />
      </Card>
    </>
  );
};

export default Teachers;
