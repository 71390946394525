import { Card } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import "../../styles/main.css";

import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
// import AddEditTeacher from "./AddEditTeacher";
import { useNavigate, Link } from "react-router-dom";
import { learnosityAssessment } from "../../redux/Student/StudentAction";
import { tableIcons, options, localization } from "../../utils/TableMui";

import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import {
  setLastVisited,
  setClassroomId,
  setSecondLastVisited,
} from "../../redux/Header/HeaderAction";
import moment from "moment";

const LearnosityAssessment = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [security, setSecurity] = useState([]);
  const [request, setRequest] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    var domain = window.location.hostname;
    var dataModel = {
      lessonId: "lesId",
      postSessionId: "stId",
      preSessionId: "preId",
      referenceId: "refId",
      status: 0,
      studentId: "stuId",
      teacherId: "userData?.id",
      type: "post",
    };

    dispatch(learnosityAssessment(dataModel)).then(async (res) => {
      setAllData(res);
    });
  }, []);

  const handleClick = () => {
   
    var initializationObject = {
      security: {
        consumer_key: allData?.security?.consumer_key,
        domain: allData?.security?.domain,
        timestamp: allData?.security?.timestamp,
        signature: allData?.security?.signature,
      },
      request: {
        activity_id: "math.c2.u7",
        name: "Math Chapter 2 – Unit 7",
        rendering_type: "assess",
        type: "submit_practice",
        session_id: "0adc8ac1-d71f-494e-860b-378c2d75a926",
        user_id: "abcd",
        activity_template_id: "math.c2.u7",
      },
    };

    var callbacks = {
      errorListener: function (e) {
       
      },

      readyListener: function () {
      
      },
    };

    var itemsApp = window.LearnosityItems.init(initializationObject, callbacks);
    
  };

  return (
    <>
      <Card sx={{ m: 1, p: 4 }}>
        {loading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}
        <div className="itemSpace">
          <div className="pageHeading">Learnosity Assessment</div>
        </div>

        <div className="pageBreadCrumb">
          <Link to="/dashboard/home">Home</Link> {" > "}
          Learnosity Assessment
        </div>
        <div id="learnosity_assess"></div>

        <div>
          <h1>Learnosity Assessment</h1>
          <Button onClick={handleClick}> Start </Button>
        </div>

        <CustomToastify />
      </Card>
    </>
  );
};

export default LearnosityAssessment;
