import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/layouts/Toastify.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { setToaster } from "../redux/Toaster/ToasterAction";

const CustomToastify = ({ responseCode, message }) => {
  return (
    <div>
      {/* <ToastContainer /> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    responseCode: state.toasterReducer.responseCode,
    message: state.toasterReducer.message,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearToast: () => dispatch(setToaster("", "")),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomToastify);
