import axios from "axios";
import { BASE_URL } from "../baseUrl/ApiConstant";
export const GET_STUDENT_DATA_BY_CLASS = "GET_STUDENT_DATA_BY_CLASS";
export const GET_STUDENT_REPORT = "GET_STUDENT_REPORT";
export const DELETE_STUDENT_DATA_FROM_CLASS = "DELETE_STUDENT_DATA_FROM_CLASS";
export const GET_LESSON_GROUP = "GET_LESSON_GROUP";
export const GET_STUDENT_NOT_IN_CLASS = "GET_STUDENT_NOT_IN_CLASS";
export const GET_STUDENT_DASHBOARD = "GET_STUDENT_DASHBOARD";
export const IMPORT_STUDENT_DATA = "IMPORT_STUDENT_DATA";
export const SET_SAVE_STUDENT = "SET_SAVE_STUDENT";
export const SET_UPDATE_STUDENT = "SET_UPDATE_STUDENT";
export const SET_SAVE_STUDENT_IN_SCHOOL = "SET_SAVE_STUDENT_IN_SCHOOL";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const GET_STUDENT_DATA_BY_ID = "GET_STUDENT_DATA_BY_ID";
export const SAVE_UPDATE_STUDENT_DATA = "SAVE_UPDATE_STUDENT_DATA";
export const SET_CLASSROOM_NAME = "SET_CLASSROOM_NAME";
export const GET_SOUND_CLASS = "GET_SOUND_CLASS";
export const GET_STUDENT_DASHBOARD_TEACHER = "GET_STUDENT_DASHBOARD_TEACHER";
export const GET_STUDENT_PROGRESS_REPORT_BY_ID =
  "GET_STUDENT_PROGRESS_REPORT_BY_ID";
export const GET_STUDENT_STANDARD_REPORT = "GET_STUDENT_STANDARD_REPORT";
export const GET_STUDENT_GAMES_REPORT = "GET_STUDENT_GAMES_REPORT";
export const SET_CATEGORY_NAME = "SET_CATEGORY_NAME";
export const SET_EPISODE_NAME = "SET_EPISODE_NAME";
export const GET_PRE_POST_REPORT = "GET_PRE_POST_REPORT";
export const GET_COMPREHENSHION_REPORT = "GET_COMPREHENSHION_REPORT";

export const GET_REVIEW_REPORT = "GET_REVIEW_REPORT";
export const SET_LEARNOSITY_SIGNATURE = "SET_LEARNOSITY_SIGNATURE";
export const GET_ASSESS_STUDENT_DATA = "GET_ASSESS_STUDENT_DATA";
export const SET_LEARNOSITY_TEST_PROGRESS = "SET_LEARNOSITY_TEST_PROGRESS";
export const SET_LEARNOSITY_UPDATE_PROGRESS = "SET_LEARNOSITY_UPDATE_PROGRESS";
export const SET_ASSESS_CLASS = "SET_ASSESS_CLASS";
export const SET_ASSESS_STUDENT = "SET_ASSESS_STUDENT";
export const SET_ASSESS_REPORT = "SET_ASSESS_REPORT";

export function setAssessReport(value1, value2) {
  return {
    type: SET_ASSESS_REPORT,
    data1: value1,
    data2: value2,
  };
}

export function setClassroomName(value) {
  return {
    type: SET_CLASSROOM_NAME,
    data: value,
  };
}

export function setAssessClass(value) {
  return {
    type: SET_ASSESS_CLASS,
    data: value,
  };
}

export function setAssessStudent(value) {
  return {
    type: SET_ASSESS_STUDENT,
    data: value,
  };
}

export const updateSound = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      operationLevel: data.operationLevel,
      soundMuted: data.soundMuted,
      operationId: data.operationId,
    };
    axios
      .put(`${BASE_URL}/api/v1/students/updateSoundMuted`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(updateSoundApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentDataByClass = (schoolId, classroomId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/students/schools/${schoolId}/classrooms/${classroomId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getStudentDataByClassApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};

export const getStudentReport = (schoolId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/students/schools/${schoolId}/student/${studentId}/studentReport`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getStudentReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};

export const getLessonGroup = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/students/lessongrouplist`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getLessonGroupApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};
export const deleteStudentDataFromClass =
  (classRoomId, studentId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios(
        `${BASE_URL}/api/v1/students/Classroom/${classRoomId}/Student/${studentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
        .then((res) => {
          dispatch(deleteStudentDataFromClassApiSuccess(res.data));
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          resolve(err.response.data);
          reject(err.response.data);
        });
    });
  };

export const getStudentNotInClassList =
  (schoolId, classroomId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios(
        `${BASE_URL}/api/v1/students/schools/${schoolId}/classRoom/${classroomId}/studentListNotInClass`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
        .then((res) => {
          dispatch(getStudentNotInClassListApiSuccess(res.data));
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          resolve(err?.response?.data);
          reject(err?.response?.data);
        });
    });
  };

export const getStudentDashboard = (schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/students/schools/${schoolId}/StudentDashBoard`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(getStudentDashboardApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};
export const getStudentDashboardTeacher =
  (schoolId, teacherId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      axios(
        `${BASE_URL}/api/v1/students/schools/${schoolId}/teachers/${teacherId}/StudentDashBoard`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
        .then((res) => {
          dispatch(getStudentDashboardTeacherApiSuccess(res.data));
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          resolve(err.response.data);
          reject(err.response.data);
        });
    });
  };

export const importStudentData = (data, schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("file", data);

    axios
      .post(
        `${BASE_URL}/api/v1/students/schools/${schoolId}/student_import`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(importStudentDataApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};

export const setSaveStudent = (data, schoolId, classroomId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      id: data.id,
      googleEmail: data.email,
      lessonGroup: data.lessonId,
      studentName: data.studentName,
      className: null,
    };
    axios
      .post(
        `${BASE_URL}/api/v1/students/schools/${schoolId}/classrooms/${classroomId}/student_enrollments`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(setSaveStudentApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};

export const setSaveStudentInSchool = (data, schoolId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      id: data.id,
      googleEmail: data.email,
      lessonGroup: data.lessonId,
      studentName: data.studentName,
      className: data.right,
    };
    axios
      .post(
        `${BASE_URL}/api/v1/students/schools/${schoolId}/students/new`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(setSaveStudentInSchoolApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err.response.data);
        reject(err.response.data);
      });
  });
};

export const setUpdateStudent = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      id: data.id,
      googleEmail: data.googleEmail,
      lessonGroup: data.lessonId,
      studentName: data.studentName,
    };
    axios
      .post(`${BASE_URL}/api/v1/users/EditClass`, dataModel, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
      .then((res) => {
        dispatch(setUpdateStudentApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const deleteStudent = (studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(`${BASE_URL}/api/v1/students/Student/${studentId}/deleteStudent`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then((res) => {
        dispatch(deleteStudentApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentDataById = (schoolId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/students/schools/${schoolId}/students/${studentId}/editStudent`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getStudentDataByIdApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const saveUpdateStudentData =
  (data, schoolId, studentId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      var dataModel = {
        name: data.studentName,
        lessonId: data.selectedLesson,
        email: data.googleEmail,
      };
      axios
        .post(
          `${BASE_URL}/api/v1/students/schools/${schoolId}/students/${studentId}/edit`,
          dataModel,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
            },
          }
        )
        .then((res) => {
          dispatch(saveUpdateStudentDataApiSuccess(res.data));
          resolve(res.data);
          return res.data;
        })
        .catch((err) => {
          resolve(err?.response?.data);
          reject(err?.response?.data);
        });
    });
  };

export const getStudentProgressReport = (schoolId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/students/${studentId}/Progress`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getStudentProgressReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentStandardReport = (schoolId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/students/${studentId}/standardLessons`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getStudentStandardReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getStudentGamesReport = (schoolId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/students/${studentId}/gamesReport`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getStudentGamesReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getPrePostReport = (episodeId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/episode/${episodeId}/students/${studentId}/prePostReport`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getPrePostReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getComprehensionReport = (episodeId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/episode/${episodeId}/students/${studentId}/comprehensionReport`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getComprehensionReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getReviewReport = (episodeId, studentId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/students/Episode/${episodeId}/Students/${studentId}/getReviewGameReportDetails`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getReviewReportApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const learnosityAssessment = (data, url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      lessonId: data.lessonId,
      postSessionId: data.postSessionId,
      preSessionId: data.preSessionId,
      referenceId: data.referenceId,
      status: 0,
      studentId: data.studentId,
      teacherId: data.teacherId,
      type: data.type,
    };

    axios
      .post(
        `${BASE_URL}/api/v1/users/learnosity/security-packet?url=${url}`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(learnosityAssessmentApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const learnosityTestProgress = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      lessonId: data.lessonId,
      postSessionId: data.postSessionId,
      preSessionId: data.preSessionId,
      referenceId: data.referenceId,
      status: data.status,
      studentId: data.studentId.toString(),
      teacherId: data.teacherId,
      type: data.type,
    };

    axios
      .post(
        `${BASE_URL}/api/v1/users/learnosity/LearnosityTestProgress`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        const apiResponseJson = JSON.parse(res.body);

        dispatch(learnosityTestProgressApiSuccess(apiResponseJson));
        resolve(apiResponseJson);
        return apiResponseJson;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const learnosityUpdatePrePostStatus = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    var dataModel = {
      lessonId: data.lessonId,
      postSessionId: data.postSessionId,
      preSessionId: data.preSessionId,
      referenceId: data.referenceId,
      status: data.status,
      studentId: data.studentId.toString(),
      teacherId: data.teacherId,
      type: data.type,
    };

    axios
      .post(
        `${BASE_URL}/api/v1/users/learnosity/LearnosityTestProgress`,
        dataModel,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        dispatch(learnosityUpdatePrePostStatusApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getAssessStudent = (schoolId, studentId, flag) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios(
      `${BASE_URL}/api/v1/users/schools/${schoolId}/studentId/${studentId}/prePostBoardForStudent?flag=${flag}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((res) => {
        dispatch(getAssessStudentApiSuccess(res.data));
        resolve(res.data);
        return res.data;
      })
      .catch((err) => {
        resolve(err?.response?.data);
        reject(err?.response?.data);
      });
  });
};

export const getAssessStudentApiSuccess = (value) => {
  return {
    type: GET_ASSESS_STUDENT_DATA,
    data: value,
  };
};

export const learnosityUpdatePrePostStatusApiSuccess = (value) => {
  return {
    type: SET_LEARNOSITY_UPDATE_PROGRESS,
    data: value,
  };
};
export const learnosityTestProgressApiSuccess = (value) => {
  return {
    type: SET_LEARNOSITY_TEST_PROGRESS,
    data: value,
  };
};

export const learnosityAssessmentApiSuccess = (value) => {
  return {
    type: SET_LEARNOSITY_SIGNATURE,
    data: value,
  };
};

export const getReviewReportApiSuccess = (value) => {
  return {
    type: GET_REVIEW_REPORT,
    data: value,
  };
};

export const getComprehensionReportApiSuccess = (value) => {
  return {
    type: GET_COMPREHENSHION_REPORT,
    data: value,
  };
};

export const getPrePostReportApiSuccess = (value) => {
  return {
    type: GET_PRE_POST_REPORT,
    data: value,
  };
};
export const getStudentGamesReportApiSuccess = (value) => {
  return {
    type: GET_STUDENT_GAMES_REPORT,
    data: value,
  };
};

export const getStudentStandardReportApiSuccess = (value) => {
  return {
    type: GET_STUDENT_STANDARD_REPORT,
    data: value,
  };
};

export const getStudentProgressReportApiSuccess = (value) => {
  return {
    type: GET_STUDENT_PROGRESS_REPORT_BY_ID,
    data: value,
  };
};
export const getStudentDataByClassApiSuccess = (value) => {
  return {
    type: GET_STUDENT_DATA_BY_CLASS,
    data: value,
  };
};

export const getStudentReportApiSuccess = (value) => {
  return {
    type: GET_STUDENT_REPORT,
    data: value,
  };
};

export const deleteStudentDataFromClassApiSuccess = (value) => {
  return {
    type: DELETE_STUDENT_DATA_FROM_CLASS,
  };
};

export const getLessonGroupApiSuccess = (value) => {
  return {
    type: GET_LESSON_GROUP,
    data: value,
  };
};
export const getStudentNotInClassListApiSuccess = (value) => {
  return {
    type: GET_STUDENT_NOT_IN_CLASS,
    data: value,
  };
};

export const setSaveStudentApiSuccess = (value) => {
  return {
    type: SET_SAVE_STUDENT,
    data: value,
  };
};

export const updateSoundApiSuccess = (value) => {
  return {
    type: GET_SOUND_CLASS,
    data: value,
  };
};

export const setSaveStudentInSchoolApiSuccess = (value) => {
  return {
    type: SET_SAVE_STUDENT_IN_SCHOOL,
    data: value,
  };
};

export const setUpdateStudentApiSuccess = (value) => {
  return {
    type: SET_UPDATE_STUDENT,
    data: value,
  };
};
export const getStudentDashboardApiSuccess = (value) => {
  return {
    type: GET_STUDENT_DASHBOARD,
    data: value,
  };
};
export const getStudentDashboardTeacherApiSuccess = (value) => {
  return {
    type: GET_STUDENT_DASHBOARD_TEACHER,
    data: value,
  };
};

export const importStudentDataApiSuccess = (value) => {
  return {
    type: IMPORT_STUDENT_DATA,
    data: value,
  };
};
export const deleteStudentApiSuccess = (value) => {
  return {
    type: DELETE_STUDENT,
  };
};
export const getStudentDataByIdApiSuccess = (value) => {
  return {
    type: GET_STUDENT_DATA_BY_ID,
  };
};
export const saveUpdateStudentDataApiSuccess = (value) => {
  return {
    type: SAVE_UPDATE_STUDENT_DATA,
  };
};

export function setCategoryName(value) {
  return {
    type: SET_CATEGORY_NAME,
    data: value,
  };
}
export function setEpisodeName(value) {
  return {
    type: SET_EPISODE_NAME,
    data: value,
  };
}
