import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/LessonGroups.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosition,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import Slideshow from "./SlideShow";
import { setStudentPlay } from "../../redux/Lessons/LessonsAction";
import Games from "./Games";
import { logoutUser } from "../../utils/authUtils";

const Preview = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [lessonId, setLessonId] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const courseId = useSelector(
    (state) => state.lessonsResourcesReducer?.courseId
  );
  const lessonGroupId = useSelector(
    (state) => state.lessonsResourcesReducer?.lessonGroupId
  );

  useEffect(() => {
    const regex = /\/lessons\/(\d+)\//;
    const match = window.location.href.match(regex);
    setLessonId(match[1]);
    dispatch(getPosition(match[1])).then(async (res) => {
      if (res?.statusCode === 200) {
        setData(res?.episodeScenesList);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });

    const urlParam = new URLSearchParams(window.location.search);
    const extractedIndex = parseInt(urlParam.get("currentIndex", 10));

    if (!isNaN(extractedIndex)) {
      setCurrentIndex(extractedIndex);
    }
  }, []);

  const handleNextItem = () => {
    const nextIndex = currentIndex + 1;

    setCurrentIndex((prevIndex) => prevIndex + 1);
    if (currentIndex === data.length - 1) {
      userData?.userType === "parent"
        ? navigate(`/dashboard/lesson_groups`)
        : userData?.userType !== "student"
        ? navigate(
            `/dashboard/courses/${courseId}/lesson_groups/${lessonGroupId}`
          )
        : navigate(`/dashboard/lessons`);
      dispatch(setStudentPlay(false));
    } else {
      navigate(`${window.location.pathname}?currentIndex=${nextIndex}`);
      window.location.reload();
    }
  };

  return (
    <>
      {data.map((episode, index) => {
        if (currentIndex === index && episode?.kind === "slides") {
          return (
            <Slideshow
              key={index}
              sceneId={episode?.id}
              onNextItem={() => handleNextItem(episode?.id)}
              episode_id={episode?.episode_id}
            />
          );
        } else if (currentIndex === index && episode?.kind === "game") {
          // return 1;
          // <Link t0="../../../../games"></Link>
          // return <GameComponent  key={index} onNextItem={handleNextItem} gameId={episode?.resource_id}/>
          return (
            <Games
              key={index}
              onNextItem={handleNextItem}
              sceneId={episode?.id}
              gameId={episode?.resource_id}
            />
          );
          // setGameIndex(episode?.resource_id)
          // return (
          //   <iframe
          //     key={index}
          //     title={`PhaserGame-${index}`}
          //     // src="http://127.0.0.1:5500/src/index1.html"

          //     src="/srcGames/index1.html"
          //     // src="http://127.0.0.1:5501/src/srcGames/index1.html"
          //     width="100%"
          //     height="650px"
          //     // width="1920px"
          //     // height="1485px"
          //     frameBorder="0"
          //     onLoad={handleIframeLoad}
          //   ></iframe>
          // );
        }
      })}
    </>
  );
};

export default Preview;
