import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/components/Resources.css";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import {
  getClassWiseAssessReport,
  setReportUnitName,
  setReportResponse,
  getScoringKeyReport,
  setLessonGroupID,
} from "../../redux/Classes/ClassesAction";
import { Chart } from "react-google-charts";
import Button from "@mui/material/Button";

import moment from "moment";
import { logoutUser } from "../../utils/authUtils";

const PrePostClassReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [episodeId, setEpisodeId] = useState();
  const [reportData, setReportData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [lessonId, setLessonId] = React.useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [classAssessReport, setClassAssessReport] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [preData, setPreData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [preColor, setPreColor] = useState([]);
  const [dropLessonName, setDropLessonName] = useState("");
  const [schoolId, setSchoolId] = useState([]);
  const [classId, setClassId] = useState([]);
  const [scoreData, setScoreData] = useState([]);
  const [rangeData, setRangeData] = useState([]);
  const [analysisPreData, setAnalysisPreData] = useState([]);
  const [analysisPostData, setAnalysisPostData] = useState([]);
  const [lessonChange, setLessonChange] = useState(0);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const resourceData = useSelector(
    (state) => state.lessonsResourcesReducer?.resourceData
  );
  const studentData = useSelector((state) => state.studentReducer?.studentData);
  const classroomName = useSelector(
    (state) => state.studentReducer?.classroomName
  );
  useEffect(() => {
    const regex = /\/schools\/(\d+)\/classrooms\/(\d+)/;
    const match = window.location.href.match(regex);
    setSchoolId(match[1]);
    setClassId(match[2]);

    if (studentData?.lessonDataList?.length > 0) {
      setUnitData(studentData?.lessonDataList);
      setLessonId(
        studentData?.lessonDataList?.length > 0 &&
          studentData?.lessonDataList[0]?.lessonId
      );
      classApiCall(
        match[2],
        studentData?.lessonDataList?.length > 0 &&
          studentData?.lessonDataList[0]?.lessonId
      );
      const object = studentData?.lessonDataList.find(
        (obj) => obj.lessonId == studentData?.lessonDataList[0]?.lessonId
      );
      setDropLessonName(object?.unit);
    }
  }, [studentData]);

  const handleChange = (event) => {
    const object1 = studentData?.lessonDataList.find(
      (obj) => obj.lessonId == event.target.value
    );
    setDropLessonName(object1?.unit);

    // unitData
    const { value, name } = event.target;
    setLessonId(value);
    setIsLoading(true);
    setLessonChange(lessonChange + 1);
  };

  useEffect(() => {
    lessonChange !== 0 && classApiCall(classId, lessonId);
  }, [lessonChange]);

  const classApiCall = (idClass, idLesson) => {
    setIsLoading(true);
    // setLoading(true);
    dispatch(getClassWiseAssessReport(idClass, idLesson)).then(async (res) => {
      if (res?.statusCode === 200 || res?.statusCode === 204) {
        setAllData(res);
        setClassAssessReport(res?.classReportObjectList);
        setTableHeaders(res?.tableHeaders);
        setReportData(res?.reportResponse);
        setLoading(true);

        dispatch(getScoringKeyReport(idLesson)).then(async (resRange) => {
          if (resRange?.statusCode === 200) {
            setScoreData(resRange);
            setRangeData(resRange?.rangeList);
            setLoading(true);
            setIsLoading(false);
            let preArray = [["Task", "Hours per Day"]];
            let preNullArray = [["Task", "Hours per Day"]];
            let postArray = [["Task", "Hours per Day"]];
            let rangeColor = [];
            let indexRange = 2;
            resRange?.rangeList?.length > 0 &&
              resRange?.rangeList.map((range, index) => {
                let preRange = [];
                let postRange = [];
                preRange.push(range?.level);
                preRange.push(
                  res?.classReportObjectList?.objectList[index + 2]?.preValue
                );
                postRange.push(range?.level);
                postRange.push(
                  res?.classReportObjectList?.objectList[index + 2]?.postValue
                );
                preArray.push(preRange);
                postArray.push(postRange);
                indexRange++;
                range.level === "Emerging"
                  ? rangeColor.push("#E79B9B")
                  : range.level === "Approaching"
                  ? rangeColor.push("#f0e68c")
                  : range.level === "Mastery"
                  ? rangeColor.push("#88d8c0")
                  : range.level === "Exceeds Standards" &&
                    rangeColor.push("#87ceeb");
              });
            rangeColor.push("#dcdcdc");
            setPreColor(rangeColor);
            let preRange = [
              res?.classReportObjectList?.objectList[indexRange]?.name,
              res?.classReportObjectList?.objectList[indexRange]?.preValue,
            ];
            preArray.push(preRange);
            let postRange = [
              res?.classReportObjectList?.objectList[indexRange]?.name,
              res?.classReportObjectList?.objectList[indexRange]?.postValue,
            ];
            postArray.push(postRange);

            resRange?.rangeList?.length > 0 &&
              resRange?.rangeList.map((range, index) => {
                let preRange = [];
                preRange.push(range?.level);
                preRange.push(0);
                preNullArray.push(preRange);
              });
            preRange = ["Students Not Tested", res?.totalStudents];
            preNullArray.push(preRange);

            res?.classReportObjectList !== null
              ? setPreData(preArray)
              : setPreData(preNullArray);

            res?.classReportObjectList !== null
              ? setPostData(postArray)
              : setPostData(preNullArray);
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            logoutUser(dispatch, navigate, userData, res?.statusCode);
          }
        });
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
      if (res?.reportResponse == null) {
        setAnalysisPreData([]);
        setAnalysisPostData([]);
      } else {
        const classAverage = calculateClassAverage(res?.reportResponse);
      }
    });
  };
  function calculateClassAverage(reportResponse) {
    const preScoresArrays = reportResponse?.responseList.map(
      (student) => student.preScores
    );
    const postScoresArrays = reportResponse?.responseList.map(
      (student) => student.postScores
    );
    const classAveragesPre = [];
    const classAveragesPost = [];

    for (
      let i = 0;
      i <
      (preScoresArrays[0]?.length > 0
        ? preScoresArrays[0]?.length
        : postScoresArrays[0]?.length);
      i++
    ) {
      const preValuesAtIndex = preScoresArrays.map((preScores) => preScores[i]);
      const postValuesAtIndex = postScoresArrays.map(
        (postScores) => postScores[i]
      );

      const filteredPreValues = preValuesAtIndex.filter(
        (value) => value !== undefined
      );
      const filteredPostValues = postValuesAtIndex.filter(
        (value) => value !== undefined
      );

      const modePre = calculateMode(filteredPreValues);
      const modePost = calculateMode(filteredPostValues);

      if (filteredPreValues?.length === 0) {
        classAveragesPre.push("N/A");
      } else if (modePre === null || modePre?.length > 1) {
        const sumPre = filteredPreValues.reduce((acc, value) => acc + value, 0);
        const firstDecimalPre =
          sumPre / filteredPreValues?.length -
          Math.floor(sumPre / filteredPreValues?.length);

        const meanPre =
          firstDecimalPre < 0.5
            ? Math.floor(sumPre / filteredPreValues?.length)
            : Math.ceil(sumPre / filteredPreValues?.length);
        classAveragesPre.push(meanPre);
      } else {
        classAveragesPre.push(modePre[0]);
      }

      if (filteredPostValues?.length === 0) {
        classAveragesPost.push("N/A");
      } else if (modePost === null || modePost?.length > 1) {
        const sumPost = filteredPostValues.reduce(
          (acc, value) => acc + value,
          0
        );
        const firstDecimalPost =
          sumPost / filteredPostValues.length -
          Math.floor(sumPost / filteredPostValues.length);

        const meanPost =
          firstDecimalPost < 0.5
            ? Math.floor(sumPost / filteredPostValues?.length)
            : Math.ceil(sumPost / filteredPostValues?.length);
        classAveragesPost.push(meanPost);
      } else {
        classAveragesPost.push(modePost[0]);
      }
    }

    let newArray = [];
    let newArray2 = [];
    let secondArray = [];
    reportResponse?.responseList?.forEach((score, index) => {
      const {
        studentName,
        preScores,
        postScores,
        postScoreTime,
        preScoreTime,
      } = score;
      let avgPreScore = 0;
      let avgPostScore = 0;

      preScores?.length > 0 &&
        preScores?.map((row, index) => (avgPreScore += row));
      avgPreScore = preScores?.length <= 0 ? "N/A" : avgPreScore;

      postScores?.length > 0 &&
        postScores?.map((row, index) => (avgPostScore += row));
      avgPostScore = postScores?.length <= 0 ? "N/A" : avgPostScore;
      secondArray.push({
        postScoreTime:
          postScores?.length > 0
            ? postScoreTime !== null &&
              moment(postScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A")
            : "N/A",

        preScoreTime:
          preScores?.length > 0
            ? preScoreTime !== null &&
              moment(preScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A")
            : "N/A",
        avgPreScore: avgPreScore,
        avgPostScore: avgPostScore,
      });

      newArray.push({
        studentName,
        preScores,
        postScores,
        postScoreTime:
          postScoreTime !== null &&
          moment(postScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A"),
        preScoreTime:
          preScoreTime !== null &&
          moment(preScoreTime.split("Z")[0]).format("MM/DD/YYYY HH:mm A"),
        avgPreScore: avgPreScore,
        avgPostScore: avgPostScore,
      });
    });

    const avgPreScoresArrays = newArray.map((student) => student.avgPreScore);
    const avgPostScoresArrays = newArray.map((student) => student.avgPostScore);

    const filteredPreValues = avgPreScoresArrays.filter(
      (value) => value !== "N/A"
    );
    const filteredPostValues = avgPostScoresArrays.filter(
      (value) => value !== "N/A"
    );

    let classTotalPre = [];
    let classTotalPost = [];

    const modePre = calculateMode(filteredPreValues);
    const modePost = calculateMode(filteredPostValues);

    if (filteredPreValues?.length === 0) {
      classTotalPre.push("N/A");
    } else {
      const sumPre = filteredPreValues.reduce((acc, value) => acc + value, 0);
      const firstDecimalPre =
        sumPre / filteredPreValues?.length -
        Math.floor(sumPre / filteredPreValues?.length);

      const meanPre =
        firstDecimalPre < 0.5
          ? Math.floor(sumPre / filteredPreValues?.length)
          : Math.ceil(sumPre / filteredPreValues?.length);
      classTotalPre.push(meanPre);
    }

    if (filteredPostValues?.length === 0) {
      classTotalPost.push("N/A");
    } else {
      const sumPost = filteredPostValues.reduce((acc, value) => acc + value, 0);
      const firstDecimalPost =
        sumPost / filteredPostValues?.length -
        Math.floor(sumPost / filteredPostValues?.length);

      const meanPost =
        firstDecimalPost < 0.5
          ? Math.floor(sumPost / filteredPostValues?.length)
          : Math.ceil(sumPost / filteredPostValues?.length);
      classTotalPost.push(meanPost);
    }

    let counter = 0;
    let anaPre = [];
    let anaPost = [];
    reportResponse?.questionsResponse[0]?.skillData?.forEach((score, index) => {
      const { questions, rationale, skill } = score;
      let quesArray = [];
      let counterArrayPre = "";
      let counterArrayPost = "";
      let pre = false;
      let post = false;
      questions?.forEach((que, index) => {
        const { ques, range, skill } = que;

        quesArray.push({
          ques,
          range,
          skill,
          classAvgPre: classAveragesPre[counter],
          classAvgPost: classAveragesPost[counter],
        });
        if (classAveragesPre[counter] < 3) {
          counterArrayPre += counter + 1 + ",";
          pre = true;
        }
        if (
          classAveragesPost[counter] < 3 ||
          classAveragesPost[counter] < classAveragesPre[counter]
        ) {
          counterArrayPost += counter + 1 + ",";
          post = true;

          // return
        }

        counter++;
      });
      if (counterArrayPre?.length > 0) {
        var counterTrimPre = counterArrayPre.substring(
          0,
          counterArrayPre?.length - 1
        );
      }

      if (counterArrayPost?.length > 0) {
        var counterTrimPost = counterArrayPost.substring(
          0,
          counterArrayPost?.length - 1
        );
      }
      if (pre) {
        anaPre.push({
          skill: score?.skill,
          rationale: score?.rationale.replace(/\n\n/g, ", "),
          counterArrayPre: counterTrimPre,
        });
      }
      if (post) {
        anaPost.push({
          skill: score?.skill,
          rationale: score?.rationale.replace(/\n\n/g, ", "),
          counterArrayPost: counterTrimPost,
        });
      }

      newArray2.push({
        questions: quesArray,
        rationale,
        skill,
      });
    });

    setAnalysisPreData(anaPre);
    setAnalysisPostData(anaPost);
    let reportRes = [];
    reportRes.push(newArray);
    reportRes.push(secondArray);
    reportRes.push(classAveragesPre);
    reportRes.push(classAveragesPost);
    reportRes.push(classTotalPre?.length > 0 ? classTotalPre[0] : 0);
    reportRes.push(classTotalPost?.length > 0 ? classTotalPost[0] : 0);
    reportRes.push(newArray2);
    reportRes.push(reportResponse?.questionsResponse[0]?.scoreScale);
    dispatch(setReportResponse(reportRes));
  }
  function calculateMode(arr) {
    const frequencyMap = new Map();

    arr.forEach((value) => {
      if (value !== undefined) {
        frequencyMap.set(value, (frequencyMap.get(value) || 0) + 1);
      }
    });

    let maxFrequency = 0;
    let mode = [];
    frequencyMap.forEach((frequency, value) => {
      if (frequency > maxFrequency) {
        maxFrequency = frequency;
        mode = [value];
      } else if (frequency === maxFrequency) {
        mode.push(value);
      }
    });

    return mode?.length === arr?.length ? null : mode;
  }

  const preOptions = {
    title: dropLessonName + ": Pre Assessment - Proficiency Level Distribution",
    colors: preColor,
    pieSliceTextStyle: {
      color: "black",
    },
  };

  const postOptions = {
    title:
      dropLessonName + ": Post Assessment - Proficiency Level Distribution",
    colors: preColor,
    pieSliceTextStyle: {
      color: "black",
    },
  };
  const handleIndividualReport = () => {
    dispatch(setLessonGroupID(lessonId));
    dispatch(setReportUnitName(dropLessonName));
    navigate(
      `/dashboard/schools/${schoolId}/classrooms/${classId}/assess_report`
    );
  };
  return (
    <Card>
      {isLoading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="top-container">
        <div className="heading-container">
          <div className="pageHeading">Class Level Report</div>
          <div className="pageBreadCrumb">
            <Link to="/dashboard/home">Home</Link> {" > "}
            <Link to="/dashboard/classes">My Classes</Link> {" > "}
            <Link to={`/dashboard/schools/${schoolId}/classrooms/${classId}`}>
              Student List
            </Link>
            {" > "}
            Class Assess Report
          </div>
        </div>
        <div className="buttons-wrapper">
          <Button
            className={`btn m1 ${
              reportData === null ||
              reportData === undefined ||
              reportData?.length <= 0
                ? "gradientButtonGray"
                : "gradientButtonPink"
            }`}
            onClick={handleIndividualReport}
            disabled={
              reportData === null ||
              reportData === undefined ||
              reportData?.length <= 0
            }
          >
            View Class Level Individual Report
          </Button>
        </div>
      </div>

      <div className="itemSpace mb-2 textInputResponsive flexContainer">
        <div className="ml-2 mt-1 mb-2 unitSelectionContainer">
          <div className="ml-1 mb-1 classFont">
            <b>Class: </b>
            {classroomName}
          </div>

          <InputLabel className="drpLabel">Select Unit</InputLabel>

          <select
            className="input mb0 select-container"
            name="LessonList"
            id="lessonId"
            value={lessonId}
            onChange={handleChange}
          >
            {unitData?.length > 0 &&
              unitData?.map((lesson) => (
                <option
                  className="drpOptions"
                  key={lesson.lessonId}
                  value={lesson.lessonId}
                >
                  {lesson.unit}
                </option>
              ))}
          </select>
        </div>

        <div className="m2 scoringKeyContainer">
          <div className="headinFont bgColor">
            &nbsp;&nbsp;Overall Scoring Key
          </div>
          <TableContainer sx={{ margin: 0 }} component={Paper}>
            <Table
              sx={{ minWidth: 450, borderCollapse: "separate" }}
              aria-label="simple table"
            >
              <TableHead className="tableHeader">
                <TableRow>
                  <TableCell size="small" align="left" className="levelCell">
                    <div className="alignCentre tHeading">Level</div>
                  </TableCell>
                  {rangeData?.map((row, index) => (
                    <TableCell
                      key={index}
                      size="small"
                      align="left"
                      className="levelCell"
                    >
                      <div className="alignCentre">{row?.level}</div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small">Raw Score</TableCell>
                  {rangeData?.map((row1, index) => (
                    <TableCell
                      key={index}
                      size="small"
                      align="left"
                      style={{
                        backgroundColor:
                          row1?.level === "Emerging"
                            ? "#E79B9B"
                            : row1?.level === "Approaching"
                            ? "#f0e68c"
                            : row1?.level === "Mastery"
                            ? "#88d8c0"
                            : row1?.level === "Exceeds Standards"
                            ? "#87ceeb"
                            : "#ffffff",
                      }}
                    >
                      {row1?.min_vale + "-" + row1?.max_value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="displayF">
        <div className="assessmentContainer">
          <div className=" mt-1 ml-2 mr-2 headinFont">
            {" "}
            &nbsp;&nbsp; Class Data Pre-Assessment Summary
          </div>
          <div className="itemSpace preDiv formContents">
            <div
              className="reportTable repoTableWidthResponsive"
              style={{ width: "30%" }}
            >
              <TableContainer
                sx={{
                  margin: 2,
                }}
                component={Paper}
              >
                <Table
                  sx={{
                    borderCollapse: "separate",
                  }}
                  aria-label="simple table"
                >
                  <TableHead
                    className="GridEditHeader frozeTwoColumns frozeThreeColumns "
                    style={{
                      position: "sticky",
                      top: "0px",
                      zIndex: "3",
                      outline: "1px solid #e0e0e0",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        size="small"
                        align="left"
                        style={{ backgroundColor: "rgb(245, 245, 245)" }}
                      >
                        <div className="alignCentre tHeading">
                          Class Data Summary
                        </div>
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        style={{ backgroundColor: "rgb(245, 245, 245)" }}
                      >
                        <div className="alignCentre">Pre Test</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      tableHeaders?.length > 0 &&
                      tableHeaders?.map((row, index) => (
                        <React.Fragment>
                          <TableRow>
                            <TableCell
                              className="tablecellFont"
                              size="small"
                              align="left"
                              style={{
                                backgroundColor:
                                  row === "Emerging"
                                    ? "#E79B9B"
                                    : row === "Approaching"
                                    ? "#f0e68c"
                                    : row === "Mastery"
                                    ? "#88d8c0"
                                    : row === "Exceeds Standards"
                                    ? "#87ceeb"
                                    : row === "Students Not Tested"
                                    ? "#dcdcdc"
                                    : "#ffffff",
                              }}
                            >
                              {row}
                            </TableCell>

                            <TableCell
                              className="tablecellFont"
                              size="small"
                              align="left"
                            >
                              {classAssessReport?.objectList?.length > 0
                                ? row !== "Average Score"
                                  ? row === "Score Scale"
                                    ? allData?.scoreScale
                                    : classAssessReport?.objectList[index]
                                        ?.preValue
                                  : classAssessReport?.preTestAvg.toFixed(2)
                                : row === "Total No. of Students" ||
                                  row === "Students Not Tested"
                                ? allData?.totalStudents
                                : 0}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div
              className="centerAlign mt-2 ml-2Responsive"
              style={{ width: "65%" }}
            >
              {preData?.length > 0 && (
                <Chart
                  chartType="PieChart"
                  data={preData}
                  options={preOptions}
                  width={"30rem"}
                  height={"400px"}
                />
              )}
            </div>
          </div>
        </div>

        <div className="assessmentContainer">
          <div className="mt-1 ml-2 mr-2 headinFont">
            {" "}
            &nbsp; &nbsp;Class Data Post-Assessment Summary
          </div>
          <div className="itemSpace preDiv formContents">
            <div
              className="reportTable repoTableWidthResponsive"
              style={{ width: "30%" }}
            >
              <TableContainer
                sx={{
                  margin: 2,
                }}
                component={Paper}
              >
                <Table
                  sx={{
                    borderCollapse: "separate",
                  }}
                  aria-label="simple table"
                >
                  <TableHead
                    className="GridEditHeader frozeTwoColumns frozeThreeColumns "
                    style={{
                      position: "sticky",
                      top: "0px",
                      zIndex: "3",
                      outline: "1px solid #e0e0e0",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        size="small"
                        align="left"
                        style={{ backgroundColor: "rgb(245, 245, 245)" }}
                      >
                        <div className="alignCentre tHeading">
                          Class Data Summary
                        </div>
                      </TableCell>

                      <TableCell
                        size="small"
                        align="left"
                        style={{ backgroundColor: "rgb(245, 245, 245)" }}
                      >
                        <div className="alignCentre">Post Test</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      tableHeaders?.length > 0 &&
                      tableHeaders?.map((row, index) => (
                        <React.Fragment>
                          <TableRow>
                            <TableCell
                              className="tablecellFont"
                              size="small"
                              align="left"
                              style={{
                                backgroundColor:
                                  row === "Emerging"
                                    ? "#E79B9B"
                                    : row === "Approaching"
                                    ? "#f0e68c"
                                    : row === "Mastery"
                                    ? "#88d8c0"
                                    : row === "Exceeds Standards"
                                    ? "#87ceeb"
                                    : row === "Students Not Tested"
                                    ? "#dcdcdc"
                                    : "#ffffff",
                              }}
                            >
                              {row}
                            </TableCell>

                            <TableCell
                              className="tablecellFont"
                              size="small"
                              align="left"
                            >
                              {classAssessReport?.objectList?.length > 0
                                ? row !== "Average Score"
                                  ? row === "Score Scale"
                                    ? allData?.scoreScale
                                    : classAssessReport?.objectList[index]
                                        ?.postValue
                                  : classAssessReport?.postTestAvg.toFixed(2)
                                : row === "Total No. of Students" ||
                                  row === "Students Not Tested"
                                ? allData?.totalStudents
                                : 0}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div
              className="centerAlign mt-2 ml-2Responsive"
              style={{ width: "65%" }}
            >
              {postData?.length > 0 && (
                <Chart
                  chartType="PieChart"
                  data={postData}
                  options={postOptions}
                  width={"30rem"}
                  height={"400px"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="displayF">
        <div className="assessmentContainer">
          <div className=" mt-1 ml-2 mr-2 headinFont">
            {" "}
            &nbsp;&nbsp; Pre-Assessment Analysis & Recommendations:
          </div>
          <div className="ml-2 mr-2 headinFont" style={{ display: "flex" }}>
            <div style={{ minWidth: "60%", display: "flex" }}>
              {" "}
              &nbsp; &nbsp; Identified Learning Opportunities
            </div>
            <div style={{ minWidth: "40%" }}>
              {" "}
              &nbsp; &nbsp; Content that Targets These Skills
            </div>
          </div>
          <div className="itemSpace preDiv">
            {analysisPreData?.length > 0 ? (
              <div style={{ width: "100%" }}>
                {analysisPreData?.map((row, index) => (
                  <div className="itemSpace">
                    <div style={{ minWidth: "60%", borderRight: "1px solid" }}>
                      <div className="mt-1 ml-2 mr-2 ">
                        <b>{row?.skill}</b>
                      </div>
                      <div className="ml-2 mr-2 mb-2">
                        (Based on the class average score on task(s) #
                        {row?.counterArrayPre} - highlighted in yellow on
                        individual sheet)
                      </div>
                    </div>

                    <div style={{ minWidth: "40%" }}>
                      <div className="mt-1 ml-2 mr-2 ">{row?.rationale}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div
                  className="reportTable centerAlign"
                  style={{
                    minWidth: "70%",
                    fontSize: "1.5rem",
                    margin: "5rem",
                  }}
                >
                  Your class is doing great.
                </div>
              </>
            )}
          </div>
        </div>

        <div className="assessmentContainer">
          <div className="mt-1 ml-2 mr-2 headinFont">
            {" "}
            &nbsp; &nbsp; Post-Assessment Analysis & Recommendations:
          </div>
          <div className="ml-2 mr-2 headinFont" style={{ display: "flex" }}>
            <div style={{ minWidth: "60%" }}>
              {" "}
              &nbsp; &nbsp; Identified Learning Opportunities
            </div>
            <div style={{ minWidth: "40%" }}>
              {" "}
              &nbsp; &nbsp; Content that Targets These Skills
            </div>
          </div>
          <div className="itemSpace preDiv">
            {analysisPostData?.length > 0 ? (
              <div style={{ width: "100%" }}>
                {analysisPostData?.map((row, index) => (
                  <div className="itemSpace">
                    <div style={{ minWidth: "60%", borderRight: "1px solid" }}>
                      <div className="mt-1 ml-2 mr-2 ">
                        <b>{row?.skill}</b>
                      </div>
                      <div className="ml-2 mr-2  mb-2">
                        (Based on the class average score on task(s) #
                        {row?.counterArrayPost} - highlighted in yellow on
                        individual sheet)
                      </div>
                    </div>
                    <div style={{ minWidth: "40%" }}>
                      <div className="mt-1 ml-2 mr-2 ">{row?.rationale}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div
                  className="reportTable centerAlign"
                  style={{
                    minWidth: "70%",
                    fontSize: "1.5rem",
                    margin: "5rem",
                  }}
                >
                  Your class is doing great.
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomToastify />
    </Card>
  );
};

export default PrePostClassReport;
