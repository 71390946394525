import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import "../../styles/main.css";
import "../../styles/components/PrintStudent.css";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CustomToastify from "../../utils/Toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const MyChildren = (props) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const studentDropdown = useSelector(
    (state) => state.loginReducer?.studentDropdown?.dropDownList
  );

  const handleAddChild = () => {
    navigate("/dashboard/children/add_child");
  };
  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="itemSpace">
        <div className="pageHeading">Children</div>
        <div className="m1">
          <Button
            onClick={handleAddChild}
            className="btn gradientButtonPink">
            ADD CHILD
          </Button>
        </div>
      </div>

      <div className="pageBreadCrumb">
        <Link to="/dashboard/lesson_groups">Lessons</Link> {" > "}
        My Children
      </div>

      <div className="printMain" >
        <div className="print-only">
          <TableContainer component={Paper}>
            <div className="printTableHeading">
              Children
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableFont" align="left">
                    Name
                  </TableCell>
                  <TableCell className="tableFont" align="left">
                    School Code
                  </TableCell>
                  <TableCell className="tableFont" align="left">
                    Login Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentDropdown?.length > 0 ? (
                  studentDropdown?.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row?.name}
                      </TableCell>
                      <TableCell align="left">{row?.code}</TableCell>
                      <TableCell align="left">{row?.login_code}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      colspan={3}
                      style={{ height: "10rem" }}
                    >
                      {"There is no child associated with you."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <CustomToastify />
    </Card>
  );
};

export default MyChildren;
