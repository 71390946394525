import {
  GET_ACCOUNT_DATA,
  SAVE_ACCOUNT_DETAILS,
  GET_LICENSE_DATA,
  SAVE_PURCHASE_LICENSE_DATA,
} from "./AccountAction";
import { RESET_STATE } from "../Login/LoginAction";

const initialState = {
  data: "",
  accountData: "",
  licenseData: "",
};

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_DATA:
      return (state = {
        ...state,
        accountData: action.data,
      });
    case SAVE_ACCOUNT_DETAILS:
      return (state = {
        ...state,
        accountData: action.data,
      });
    case GET_LICENSE_DATA:
      return (state = {
        ...state,
        licenseData: action.data,
      });
    case SAVE_PURCHASE_LICENSE_DATA:
      return (state = {
        ...state,
        data: action.data,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default AccountReducer;
