import React, { useState, useEffect } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import "../../styles/main.css";
import "../../styles/components/home.css";
import "../../styles/components/ViewReportProgress.css";
import Card from "@material-ui/core/Card";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  getStudentReport,
  updateSound,
  getReviewReport,
  getComprehensionReport,
  getPrePostReport,
  setEpisodeName,
} from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { setSecondLastVisited } from "../../redux/Header/HeaderAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BarChart } from "@mui/x-charts/BarChart";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";

import moment from "moment";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";
import { FaTrash, FaEdit, FaUser } from "react-icons/fa";
import { logoutUser } from "../../utils/authUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#ededed",
    },
  })
);

const ViewReportProgress = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [episodeId, setEpisodeId] = useState(0);
  const [muted, setMuted] = useState(true);
  const [preReportData, setPreReportData] = useState([]);
  const [reviewReportData, setReviewReportData] = useState([]);
  const [reviewReportList, setReviewReportList] = useState([]);
  const [rubric, setRubric] = useState([]);
  const [rubricStandard, setRubricStandard] = useState([]);
  const [rubricDate, setRubricDate] = useState([]);
  const [reviewArray, setReviewArray] = useState([]);
  const [comprehensionData, setComprehensionData] = useState([]);
  const [compreDate, setCompreDate] = useState([]);
  const [compPercentage, setCompPercentage] = useState([]);
  const [compTries, setCompTries] = useState([]);
  const [compAllData, setCompAllData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const lastVisited = useSelector((state) => state.headerReducer?.lastVisited);
  const classroomId = useSelector((state) => state.headerReducer?.classroomId);
  const categoryName = useSelector(
    (state) => state.studentReducer?.categoryName
  );
  const standardReport = useSelector(
    (state) => state.studentReducer?.standardReport
  );
  const episodeName = useSelector((state) => state.studentReducer?.episodeName);

  useEffect(() => {
    const url = window.location.href;
    const regex = url
      ? /\/students\/(\d+)\/lessonreports\/(\d+)/
      : /\/students\/(\d+)\/lessonreports\//;
    const match = window.location.href.match(regex);
    setStudentId(match[1]);
    setEpisodeId(match[2]);

    setLoading(true);
    dispatch(getPrePostReport(match[2], match[1])).then(async (res) => {
      if (res?.statusCode === 200) {
        setPreReportData(res);
        setLoading(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });

    dispatch(getReviewReport(match[2], match[1])).then(async (res) => {
      if (res?.statusCode === 200) {
        setReviewReportData(res);

        let newArrayList = [];
        res?.studentDashboardDTO?.forEach((obj) => {
          if (
            !newArrayList.some(
              (o) =>
                o.rubricResult === obj.rubricResult &&
                o.updatedAt === obj.updatedAt
            )
          ) {
            newArrayList.push({ ...obj });
          }
        });
        setReviewReportList(newArrayList);
        setLoading(false);
        var reviewRubric = [];
        var reviewStandard = [];
        var reviewDate = [];
        let dataArray = [];

        res?.studentDashboardDTO?.map((item, index) => {
          let reviewTime = moment(item?.updatedAt).format(
            "MM/DD/YYYY HH:mm:ss"
          );
          // item?.updatedAt = reviewTime;

          let dataRow = {
            reviewTime: moment(item?.updatedAt).format("MM/DD/YYYY HH:mm:ss"),
            rubricResult: item?.rubricResult,
            rubricStandard: item?.rubricStandard,
            scoreId: item?.scoreId,
            updatedAt: item?.updatedAt,
          };

          reviewRubric.push(item?.rubricResult);
          reviewStandard.push(item?.rubricStandard);

          reviewDate.push(
            moment(item?.updatedAt).format("MM/DD/YYYY HH:mm:ss")
          );
        });

        setRubric(reviewRubric);
        setRubricStandard(reviewStandard);
        setRubricDate(reviewDate);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });

    dispatch(getComprehensionReport(match[2], match[1])).then(async (res) => {
      if (res?.statusCode === 200) {
        setCompAllData(res);
        setComprehensionData(res?.compData);
        setLoading(false);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      }
    });
  }, []);
  const prePostOption = {
    // indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            // Set the font size
            color: "green", // Set the font color
            weight: "bold", // Set font weight (if supported)
          },
        },
        title: {
          display: true,
          text: episodeName,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (value) => `${value}` + "%",
          font: {
            size: 14,
            weight: "bold", // Set font weight (if supported)
          },
        },
      },
    },
    elements: {
      bar: {
        barThickness: 0.2,
      },
    },
    responsive: true,
    barThickness: 30,
    borderWidth: 1,

    // order: 50,
    // plugins:{
    //   legend:{
    //     position:'right'
    //   },
    //   title:{
    //     display:true,
    //     text:' Horizontal bar chart'
    //   }
    // }
  };

  const prePostData = {
    labels: ["Pre lesson test", "Post lesson test"],
    datasets: [
      {
        label: episodeName,
        data: [preReportData?.prePercentage, preReportData?.postPercentage],

        // data: [50, 75],
        backgroundColor: "#187fc3",
        //  backgroundColor:  gameColor,
      },
    ],
  };

  const reviewOption = {
    // indexAxis: 'y',

    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            // Set the font size
            color: "green", // Set the font color
            weight: "bold", // Set font weight (if supported)
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
            weight: "bold", // Set font weight (if supported)
          },
        },
      },
    },
    elements: {
      bar: {
        barThickness: 0.2,
      },
    },
    responsive: true,
    barThickness: 30,
    borderWidth: 1,

    // order: 50,
    // plugins:{
    //   legend:{
    //     position:'right'
    //   },
    //   title:{
    //     display:true,
    //     text:' Horizontal bar chart'
    //   }
    // }
  };

  const reviewData = {
    labels: ["Pre test", "Post test"],
    datasets: [
      {
        // label: episodeName,
        // data: [preReportData?.prePercentage, preReportData?.postPercentage],

        data: [50, 75],
        backgroundColor: "#187fc3",
        //  backgroundColor:  gameColor,
      },
    ],
  };

  const bubbleData = {
    datasets: [
      {
        label: "Review game results",
        data:
          reviewReportList?.length > 0
            ? reviewReportList?.map((item) => ({
                x: new Date(item.updatedAt).getTime(),
                y: item.rubricResult,
                r: 5, // Adjust bubble size as needed
                label: `${item.rubricStandard} - ${new Date(
                  item.updatedAt
                ).toLocaleDateString()} ${new Date(
                  item.updatedAt
                ).toLocaleTimeString()}`,
              }))
            : null,
        backgroundColor: "#187fc3",
        borderColor: "#187fc3",
        // borderWidth: 2,
        // borderColor:"black",
        // backgroundColor: 'rgba(75, 192, 192, 0.6)',
        // borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const minTime =
    reviewReportList?.length > 0
      ? new Date(
          Math.min(...reviewReportList?.map((item) => new Date(item.updatedAt)))
        )
      : 0;
  const maxTime =
    reviewReportList?.length > 0
      ? new Date(
          Math.max(...reviewReportList?.map((item) => new Date(item.updatedAt)))
        )
      : 0;

  const today = new Date(rubricDate[0]);
  const prevThreeDays = new Date(today.setDate(today.getDate() - 3));

  const tomorrow = new Date();
  const nextThreeDays = new Date(tomorrow.setDate(tomorrow.getDate() + 1));

  const bubbleOptions = {
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      autoPadding: true,
      // left: 20,
      // top: 10,
      // right: -20
    },

    scales: {
      x: {
        gridLines: {
          lineWidth: 20,
          // drawBorder: true
        },
        grace: 5,
        type: "time",
        labels:
          reviewReportList?.length > 0
            ? reviewReportList?.map(
                (item) =>
                  `${new Date(
                    item.updatedAt
                  ).toLocaleDateString()} - ${new Date(
                    item.updatedAt
                  ).toLocaleTimeString()}`
              )
            : null,
        adapters: {
          date: {
            locale: enUS,
          },
        },
        time: {
          unit: "day", // Adjust time unit as needed
          unitStepSize: 10,
        },

        ticks: {
          callback: function (value, index, values) {
            // Convert time value to localized string
            return new Date(value).toLocaleString();
          },
        },
        // min: minTime, // Set the minimum time value
        //  max: maxTime, // Set the maximum time value
        min: new Date(today.setDate(today.getDate() - 10)),
        max: new Date(tomorrow.setDate(tomorrow.getDate() + 1)),
        title: {
          display: true,
          //  text: 'Updated At',
        },
      },
      y: {
        grace: 1,
        beginAtZero: true,
        // max: 10,
        stepSize: 1,
        // callback: (value) => `${value}` + "%",

        title: {
          display: true,
          //   text: 'Rubric Result',
        },
        callback: (value, index, values) => {
          const totalItems = values.length - 1;
          if (index !== totalItems) {
            return value;
          }
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: (context) => context.raw.label,
        },
      },
    },
  };

  const compOption = {
    // indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            // Set the font size
            color: "green", // Set the font color
            weight: "bold", // Set font weight (if supported)
          },
        },
        title: {
          display: true,
          text: "Comprehension game results",
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (value) => `${value}` + "%",
          font: {
            size: 14,
            weight: "bold", // Set font weight (if supported)
          },
        },
      },
    },
    elements: {
      bar: {
        barThickness: 0.2,
      },
    },
    responsive: true,
    barThickness: 30,
    borderWidth: 1,

    // order: 50,
    // plugins:{
    //   legend:{
    //     position:'right'
    //   },
    //   title:{
    //     display:true,
    //     text:' Horizontal bar chart'
    //   }
    // }
  };

  const compData = {
    labels: [
      comprehensionData?.dates !== null
        ? moment(comprehensionData?.dates).format("MM/DD/YYYY HH:mm A")
        : "",
    ],
    datasets: [
      {
        label: "Comprehension game results",
        data: [parseInt(comprehensionData?.percentage)],

        // data: [50, 75],
        backgroundColor: "#187fc3",
        //  backgroundColor:  gameColor,
      },
    ],
  };

  return (
    <Card>
      {loading && (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      )}
      <div className="pageHeading">{episodeName}</div>
      {userData?.userType === "student" || userData?.userType === "parent" ? (
        <div className="pageBreadCrumb">
          {userData?.userType === "student" ? (
            <Link to="/dashboard/lessons">Lessons</Link>
          ) : (
            <Link to="/dashboard/lesson_groups">Lessons</Link>
          )}{" "}
          {" > "}
          <Link to="/dashboard/progress">Progress</Link> {" > "}
          <Link to={`/dashboard/students/${studentId}/lessonreports`}>
            {categoryName} Progress
          </Link>{" "}
          {" > "}
          {episodeName}
        </div>
      ) : (
        <>
          <div
            className="pageBreadCrumb"
            style={{
              display: lastVisited === "StudentList" ? "block" : "none",
            }}
          >
            <Link to="/dashboard/home">Home</Link> {" > "}
            <Link to="/dashboard/classes">My Classes</Link> {" > "}
            <Link
              to={`/dashboard/schools/${userData?.school_id}/classrooms/${classroomId}`}
            >
              Student List
            </Link>{" "}
            {" > "}
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${studentId}`}
            >
              Student Activity Report by Lesson{" "}
            </Link>{" "}
            {" > "}
            <Link to={`/dashboard/students/${studentId}/lessonreports`}>
              {categoryName} Progress
            </Link>{" "}
            {" > "}
            {episodeName}
          </div>
          <div
            className="pageBreadCrumb"
            style={{
              display: lastVisited === "StudentDashBoard" ? "block" : "none",
            }}
          >
            <Link to="/dashboard/home">Home</Link> {" > "}
            <Link to="/dashboard/myStudentsReports">My Students</Link> {" > "}
            <Link
              to={`/dashboard/schools/${userData?.school_id}/students/${studentId}`}
            >
              Student Activity Report by Lesson{" "}
            </Link>{" "}
            {" > "}
            <Link to={`/dashboard/students/${studentId}/lessonreports`}>
              {categoryName} Progress
            </Link>{" "}
            {" > "}
            {episodeName}
          </div>
        </>
      )}
      <div className="ViewProgressMain">
        <div className="viewHeading">Overview</div>
        {categoryName === "Literacy" ? (
          <>
            {standardReport?.categoryEpisodeMap?.Literacy.map((row) => {
              return (
                row?.episodeId == episodeId && (
                  <div className="overviewMain">
                    <>
                      <div className="overviewLeft">
                        <div className="overviewTitle">Learning outcome</div>
                        {row.learningOutcome}
                      </div>

                      <div className="overviewRight">
                        <ul>
                          <li className="overviewrightTitle">
                            Completion percentage:
                          </li>
                          <li>{parseInt(row.percentage)}%</li>
                          <li className="overviewrightTitle">Plays:</li>
                          <li>{reviewReportList?.length}</li>
                        </ul>
                      </div>
                    </>
                  </div>
                )
              );
            })}
          </>
        ) : (
          <>
            {standardReport?.categoryEpisodeMap?.Math.map((row) => {
              return (
                row?.episodeId == episodeId && (
                  <div className="overviewMain">
                    <div className="overviewLeft">
                      <div className="overviewTitle">Learning outcome</div>
                      Students will recognize the first word of a sentence.
                      Grade 1. At Standard NY-1RF1a Above Standard NY-2L3.
                    </div>

                    <div className="overviewRight">
                      <ul>
                        <li className="overviewrightTitle">
                          Completion percentage:
                        </li>
                        <li>{parseInt(row.percentage)}%</li>
                        <li className="overviewrightTitle">Plays:</li>
                        <li>{reviewReportList?.length}</li>
                      </ul>
                    </div>
                  </div>
                )
              );
            })}
          </>
        )}

        <div
          style={{
            display:
              preReportData?.preDate == null && preReportData?.postDate == null
                ? "none"
                : "block",
          }}
        >
          <div className="viewHeading mt-2">
            Pre lesson and post lesson results
          </div>
          <div className="prePostMain">
            <div className="mb-1">
              Results for the pre lesson and post lesson games.{" "}
            </div>
            <div>
              {" "}
              The pre lesson and post lesson game are designed to asses the
              student's knowledge before and after the lesson. Students can only
              do a Pre lesson and Post lesson game once.
            </div>

            <div className="preMain">
              <div className="preLeft">
                <div className="barPadding">
                  <Bar options={prePostOption} data={prePostData} />
                </div>
              </div>

              <div className="preRight">
                {/* {preReportData?.map((pre, index) => {
                           
                           return ( */}

                <div className="overviewRight mt-2">
                  <ul>
                    <li className="overviewrightTitle">
                      Pre lesson game score:
                    </li>
                    <li>
                      <b>
                        {preReportData?.prePercentage == 0
                          ? "Incomplete"
                          : preReportData?.prePercentage + "%"}
                      </b>
                      <span className="pl-2">
                        {preReportData?.prePercentage == 0
                          ? ""
                          : moment(preReportData?.preDate).format(
                              "MM/DD/YYYY HH:mm A"
                            )}
                      </span>
                    </li>
                    <li className="overviewrightTitle">
                      Post lesson game score:
                    </li>
                    <li>
                      <b>
                        {preReportData?.postPercentage == 0
                          ? "Incomplete"
                          : preReportData?.postPercentage + "%"}
                      </b>
                      <span className="pl-2">
                        {preReportData?.postPercentage == 0
                          ? ""
                          : moment(preReportData?.postDate).format(
                              "MM/DD/YYYY HH:mm A"
                            )}
                      </span>
                    </li>
                    <li className="overviewrightTitle">Difference:</li>
                    <li>
                      <b>
                        {/* {preReportData?.prePercentage === 0 &&  preReportData?.postPercentage === 0
                        ? "Incomplete"
                        : "+"+ */}
                        {preReportData?.difference + "%"}
                      </b>
                    </li>
                  </ul>
                </div>
                {/* )})} */}
              </div>
            </div>
          </div>
        </div>
        <div className="viewHeading mt-2">Review game results</div>
        <div className="reviewMainBox">
          <div className="reviewMain">
            <div className="reviewLeft">
              Results of Review game displayed as a rubric level. These results
              are for the students most recent attempt of the review game.
              <br />
              Review games are designed to assess the students knowledge at a
              specific level.
            </div>

            <div className="reviewRight ml-2">
              <table className="reviewTable" cellPadding={4}>
                <thead align="left">
                  <tr className="borderBtm">
                    <th>Rubric</th>
                    <th>Standard/Level</th>
                    <th>Score (%)</th>
                  </tr>
                </thead>
                <tbody align="left">
                  <tr className="borderBtm">
                    <td>1</td>
                    <td>Emerging</td>
                    <td>&lt;= 25</td>
                  </tr>
                  <tr className="borderBtm">
                    <td>2</td>
                    <td>Approaching</td>
                    <td>&gt; 25 &amp; &lt; =50</td>
                  </tr>
                  <tr className="borderBtm">
                    <td>3</td>
                    <td>Mastery</td>
                    <td>&gt; 50 &amp; &lt; =75</td>
                  </tr>
                  <tr className="borderBtm">
                    <td>4</td>
                    <td>Above Mastery</td>
                    <td>&gt; 75</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="reviewMain">
            <div className="reviewGameLeft">
              <div className="barPadding">
                <div className="bartext ml-2">Review game results </div>
                <Bubble data={bubbleData} options={bubbleOptions} />
              </div>
            </div>

            <div className="reviewGameRight mt-2">
              <div className="overviewRight mt-2">
                <ul>
                  <li className="overviewrightTitle">No. of Attempts:</li>
                  <li>
                    {reviewReportList?.length > 0
                      ? reviewReportList?.length
                      : 0}
                  </li>
                  <li className="overviewrightTitle">Mastery Level:</li>
                  <li>
                    {reviewReportData?.rubricValue !== 0
                      ? reviewReportData?.rubricValue
                      : "N/A"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="viewHeading mt-2">Comprehension game results</div>

        <div className="cmpGameBox">
          <div className="mb-1">Results of Comprehension games.</div>

          <div>
            Comprehension games are designed to assess the student's
            understanding of the content of the lesson.
          </div>

          <div className="cmpGameMain">
            <div className="cmpGameLeft">
              <div className="barPadding">
                <Bar options={compOption} data={compData} />
              </div>
            </div>

            <div className="cmpGameRight mt-2">
              <div className="overviewRight mt-2">
                <ul>
                  <li className="overviewrightTitle">Tries:</li>
                  <li>
                    {comprehensionData?.tries === null
                      ? "0"
                      : comprehensionData?.tries}
                  </li>
                  <li className="overviewrightTitle">Average Score:</li>
                  <li>
                    {parseInt(comprehensionData?.tries) === null
                      ? "Incomplete"
                      : parseInt(comprehensionData?.percentage) + "%"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomToastify />
    </Card>
  );
};

export default ViewReportProgress;
