import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getEducatorLogin,
  getEducatorData,
  getStudentLogin,
  getStudentData,
  setWelcomePage,
  setIsEducator,
  signInWithGoogle,
  getSSOLogin,
  setSSOLogout,
  getParentLogin,
  getParentData,
  getStudentDropdown,
  setSelectedStudent,
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { GoogleLogin } from "@react-oauth/google";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": {
          color: "red",
        },
      },
    },
  },
});

export default function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [SSOLoading, setSSOLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentPassword, setParentPassword] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [studentId, setStudentId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [responseCode, setResponseCode] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [gmailToken, setGmailToken] = useState("");
  const [SSoLogin, setSSoLogin] = useState(false);
  const [SSoError, setSSoError] = useState(false);
  const [codeSSO, setCodeSSO] = useState(null);
  const isEducator = useSelector((state) => state.loginReducer?.isEducator);
  const SSOLogout = useSelector((state) => state.loginReducer?.addLinkLogout);
  const userData = useSelector((state) => state.loginReducer?.userData);

  const handleChangeUser = (value) => {
    dispatch(setIsEducator(value));
  };

  const handleStudentId = (event) => {
    var stuIdVal = event.target.value;
    const regex = /^[0-9\b]+$/;
    if (stuIdVal > 0 && regex.test(stuIdVal) && stuIdVal?.length < 5) {
      setStudentId(stuIdVal);
    } else if (stuIdVal == "" && studentId?.length == 1) {
      setStudentId("");
    } else {
      setStudentId(studentId);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (isEducator !== "student") {
      if (validateEducatorForm()) return;
    } else {
      if (validateStudentForm()) return;
    }
    setIsLoading(true);
    isEducator === "educator"
      ? emailError?.length === 0 &&
        passwordErrorText?.length == 0 &&
        dispatch(getEducatorLogin(email, password)).then(async (res) => {
          setIsLoading(false);
          dispatch(setSSOLogout(false));
          if (res?.statusCode === 200) {
            dispatch(showToastMessage(res?.statusCode, res?.message));
            dispatch(getEducatorData()).then((res) => {
              if (res?.statusCode === 200) {
                if (res?.visited_welcome_screen === "1") {
                  navigate("/dashboard/classes", { replace: true });
                } else {
                  navigate("/dashboard/home", { replace: true });
                  dispatch(setWelcomePage(res?.id));
                }
              }
            });
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          } else {
            setPassword("");
            setEmail("");
            setTouched(false);
            passwordErrorText = "";
            setErrorOpen(true);
            dispatch(showToastMessage(res?.statusCode, res?.message));
          }
        })
      : isEducator === "student"
      ? dispatch(getStudentLogin(schoolCode, studentId)).then(async (res) => {
          setIsLoading(false);
          dispatch(setSSOLogout(false));
          if (res?.statusCode === 200) {
            dispatch(showToastMessage(res?.statusCode, res?.message));
            // setTimeout(() => {setResponseCode(""); }, 3000);
            dispatch(getStudentData()).then((res) => {
              res?.statusCode === 200 &&
                navigate("/dashboard/lessons", { replace: true }); //Student nav menu
            });
          } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          } else {
            setSchoolCode("");
            setStudentId("");
            setErrorOpen(true);

            dispatch(
              showToastMessage(res?.statusCode, `Invalid Student Login.`)
            );
          }
        })
      : dispatch(getParentLogin(parentEmail, parentPassword)).then(
          async (res) => {
            setIsLoading(false);
            dispatch(setSSOLogout(false));
            dispatch(setSelectedStudent([]));
            if (res?.statusCode === 200) {
              dispatch(showToastMessage(res?.statusCode, res?.message));
              dispatch(getParentData()).then((res) => {
                if (res?.statusCode === 200) {
                  dispatch(getStudentDropdown(res?.id)).then((res) => {
                    if (res?.statusCode === 200) {
                      navigate("/dashboard/lesson_groups", { replace: true });
                    }
                  });
                }
              });
            } else if (res?.statusCode === 403 || res?.statusCode === 401) {
            } else {
              setParentEmail("");
              setParentPassword("");
              setTouched(false);
              passwordErrorText = "";
              setErrorOpen(true);
              dispatch(showToastMessage(res?.statusCode, res?.message));
            }
          }
        );
  };

  useEffect(() => {
    const url = window.location.href;
    const urlParams = new URLSearchParams(new URL(url).search);

    const code = urlParams.get("code");
    const appCode = new URL(url).pathname.split("/").pop();
    setCodeSSO(code);
    if (code && appCode) {
      setSSoLogin(true);
      setSSOLoading(true);
    }
    if (code !== null && code !== "" && appCode !== null && appCode !== "") {
      dispatch(setSSOLogout(false));
      dispatch(getSSOLogin({ code, appCode })).then(async (res) => {
        if (res?.statusCode === 200) {
          setSSoError(false);
          dispatch(showToastMessage(res?.statusCode, res?.message));
          if (res?.type === "user") {
            dispatch(getEducatorData()).then((res) => {
              setSSOLoading(false);
              if (res?.statusCode === 200) {
                if (res?.visited_welcome_screen === "1") {
                  navigate("/dashboard/classes", { replace: true });
                } else {
                  navigate("/dashboard/home", { replace: true });
                  dispatch(setWelcomePage(res?.id));
                }
                if (userData?.roster_name === "edlink") {
                  dispatch(setSSOLogout(true));
                }
              }
            });
          } else if (res?.type === "student") {
            dispatch(getStudentData()).then((res) => {
              setSSOLoading(false);
              if (res?.statusCode === 200) {
                navigate("/dashboard/lessons", { replace: true });
                if (userData?.roster_name === "edlink") {
                  dispatch(setSSOLogout(true));
                }
              }
            });
          }
        } else {
          setSSoError(true);
          setSSOLoading(false);
          // dispatch(showToastMessage(res?.statusCode, res?.message));
        }
      });
    } else {
      setSSoError(true);
    }
    dispatch(setIsEducator(null));
  }, []);

  const isPasswordValid = (password) => {
    // const passwordRegex = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return password?.length > 7;
    //  && passwordRegex.test(password);
  };

  var passwordErrorText =
    touched && !isPasswordValid(password)
      ? "Password must be minimum 8 characters long."
      : "";

  const handleChange = (event) => {
    const { value, name } = event.target;
    var data =
      name === "Email"
        ? setEmail(value)
        : name === "Password"
        ? setPassword(value)
        : name === "School Code"
        ? setSchoolCode(value)
        : "";
    if (name === "Student ID") {
      const regex = /^[0-9\b]+$/;
      if (value > 0 && regex.test(value) && value?.length < 5) {
        setStudentId(value);
      } else if (value == "" && studentId?.length == 1) {
        setStudentId("");
      } else {
        setStudentId(studentId);
      }
    }
  };

  const validateEducatorForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues =
      isEducator === "educator"
        ? [email, password]
        : [parentEmail, parentPassword];
    requireFields =
      isEducator === "educator"
        ? ["email", "password"]
        : ["parentEmail", "parentPassword"];
    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field?.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
      if (
        (requireFields[index] == "password" ||
          requireFields[index] == "parentPassword") &&
        field?.length !== 0 &&
        field !== null &&
        !(field?.length > 7)
      ) {
        errors[requireFields[index]] =
          "Password must be minimum 8 characters long.";
      }

      if (
        (requireFields[index] == "email" ||
          requireFields[index] == "parentEmail") &&
        field?.length !== 0 &&
        field?.length > 50
      ) {
        errors[requireFields[index]] =
          "Email is too long (maximum is 50 characters)";
      } else if (
        (requireFields[index] == "email" ||
          requireFields[index] == "parentEmail") &&
        field?.length !== 0 &&
        field !== null &&
        field !== "" &&
        // !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(field)
      ) {
        errors[requireFields[index]] = "Please enter a valid email address";
      }
    });

    setErrors(errors);

    return Object.keys(errors)?.length > 0 ? true : false;
  };

  const validateStudentForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [schoolCode, studentId];
    requireFields = ["schoolCode", "studentId"];
    // var i;

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field?.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }
    });

    setErrors(errors);

    return Object.keys(errors)?.length > 0 ? true : false;
  };
  return (
    <>
      <div style={{ display: SSOLoading ? "-webkit-box" : "block" }}>
        {isLoading === true && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div
          className={
            isEducator === "educator"
              ? "loginBg logoTeacher"
              : "loginBg logoStudent"
          }
        ></div>

        {SSOLoading ? (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        ) : (
          <div className="loginCard">
            <div className="loginDialog">
              <div className="itemCenter">
                <img className="loginLogo" src={LogoImg} alt="KneoWorld-Logo" />
              </div>
              {!SSoLogin && !SSOLogout && (
                <div className="loginHeading itemCenter">
                  <div className="itemSpace">
                    <div className="TeacherLoginButton">
                      <Button
                        className={
                          " btn TeacherLoginBtn loginBtnResponsive" +
                          (isEducator === "student" ? " active" : "")
                        }
                        fullWidth
                        sx={{ m: 1 }}
                        variant="contained"
                        onClick={() => handleChangeUser("student")}
                      >
                        Students
                      </Button>
                    </div>
                    <div className="TeacherLoginButton">
                      <Button
                        className={
                          " btn TeacherLoginBtn loginBtnResponsive" +
                          (isEducator === "educator" ? " active" : "")
                        }
                        fullWidth
                        sx={{ m: 1 }}
                        variant="contained"
                        onClick={() => handleChangeUser("educator")}
                      >
                        Educators
                      </Button>
                    </div>
                  </div>
                  {/* <div className="itemCenter">
                    <div className="TeacherLoginButton">
                      <Button
                        className={
                          " btn TeacherLoginBtn loginBtnResponsive" +
                          (isEducator === "parent" ? " active" : "")
                        }
                        fullWidth
                        sx={{ m: 1 }}
                        variant="contained"
                        onClick={() => handleChangeUser("parent")}
                      >
                        Parents
                      </Button>
                    </div>
                  </div> */}
                </div>
              )}
              {SSoLogin && SSoError && (
                <div className="error mt-3">
                  Access Denied: You are not authorized to access this
                  application. Please contact your system administrator for
                  assistance.
                </div>
              )}
              {SSOLogout && !SSoLogin ? (
                <div className="itemCenter m2">You've successfully logout.</div>
              ) : (
                !SSoLogin && (
                  <div
                    className={isEducator !== null ? "loginDisplay" : "hide"}
                  >
                    {isEducator === "educator" && (
                      <>
                        <div className="itemGridCenter">
                          <ThemeProvider theme={formLabelsTheme}>
                            <TextField
                              fullWidth
                              sx={{
                                m: 1,
                                "& input": {
                                  fontFamily: "Raleway !important",
                                },
                              }}
                              className="mb-0"
                              required
                              id="outlined-required-1"
                              label="Email"
                              name="Email"
                              // <span className="labelReqField">*</span>
                              size="small"
                              value={email}
                              onChange={handleChange}
                              error={emailError !== ""}
                              helperText={emailError}
                              // className={"mb-0"}
                            />
                          </ThemeProvider>
                        </div>
                        {errors.email && (
                          <div className="error mb-0">{errors.email}</div>
                        )}
                        <div className="itemGridCenter">
                          <TextField
                            fullWidth
                            sx={{
                              m: 1,
                              "& input": {
                                fontFamily: "Raleway !important",
                              },
                            }}
                            className="mb-0"
                            required
                            label="Password"
                            name="Password"
                            size="small"
                            value={password}
                            // className="mb-0"
                            onChange={handleChange}
                            onBlur={() => setTouched(true)}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // error={passwordErrorText !== ''}
                            // helperText={passwordErrorText}
                          />
                        </div>
                        {errors.password && (
                          <div className="error mb-0">{errors.password}</div>
                        )}
                      </>
                    )}

                    {isEducator === "student" && (
                      <>
                        <div className="itemGridCenter">
                          <TextField
                            fullWidth
                            sx={{
                              m: 1,
                              "& input": {
                                fontFamily: "Raleway !important",
                              },
                            }}
                            className="mb-0"
                            required
                            id="outlined-required-3"
                            label="School Code"
                            size="small"
                            value={schoolCode}
                            // className="mb-0"
                            onChange={(e) => {
                              setSchoolCode(e.target.value);
                            }}
                          />
                        </div>
                        {errors.schoolCode && (
                          <div className="error mb-0">{errors.schoolCode}</div>
                        )}
                        <div className="itemGridCenter">
                          <TextField
                            fullWidth
                            sx={{
                              m: 1,
                              "& input": {
                                fontFamily: "Raleway !important",
                              },
                            }}
                            className="mb-0"
                            required
                            id="outlined-required-4"
                            label="Student ID"
                            size="small"
                            value={studentId}
                            error={error}
                            // className="mb-0"
                            helperText={
                              error ? "Please enter a valid number" : ""
                            }
                            // type="number"
                            // min="0"
                            // step="1"
                            onChange={handleStudentId}
                          />
                        </div>
                        {errors.studentId && (
                          <div className="error mb-0">{errors.studentId}</div>
                        )}
                      </>
                    )}

                    {isEducator === "parent" && (
                      <>
                        <div className="itemGridCenter">
                          <ThemeProvider theme={formLabelsTheme}>
                            <TextField
                              fullWidth
                              sx={{
                                m: 1,
                                "& input": {
                                  fontFamily: "Raleway !important",
                                },
                              }}
                              className="mb-0"
                              required
                              id="outlined-required-1"
                              label="Email"
                              // <span className="labelReqField">*</span>
                              size="small"
                              value={parentEmail}
                              onChange={(e) => setParentEmail(e.target.value)}
                              error={emailError !== ""}
                              helperText={emailError}
                              // className={"mb-0"}
                            />
                          </ThemeProvider>
                        </div>
                        {errors.parentEmail && (
                          <div className="error mb-0">{errors.parentEmail}</div>
                        )}
                        <div className="itemGridCenter">
                          <TextField
                            fullWidth
                            sx={{
                              m: 1,
                              "& input": {
                                fontFamily: "Raleway !important",
                              },
                            }}
                            className="mb-0"
                            required
                            id="outlined-required-2"
                            label="Password"
                            size="small"
                            value={parentPassword}
                            // className="mb-0"
                            onChange={(e) => {
                              setParentPassword(e.target.value);
                            }}
                            onBlur={() => setTouched(true)}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // error={passwordErrorText !== ''}
                            // helperText={passwordErrorText}
                          />
                        </div>
                        {errors.parentPassword && (
                          <div className="error mb-0">
                            {errors.parentPassword}
                          </div>
                        )}
                      </>
                    )}
                    {/* </div> */}
                    <div
                      className={
                        !isEducator ? "loginOptions itemEnd" : "loginOptions "
                      }
                    >
                      {isEducator !== "student" && (
                        <>
                          <div className="loginLeft"></div>

                          <div className="loginRight">
                            <Link
                              to={
                                isEducator === "educator"
                                  ? `/users/forgot-password`
                                  : `/parent/forgot-password`
                              }
                            >
                              <span className="link">Forgot Password?</span>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="LoginButton">
                      <Button
                        className="loginBtn"
                        fullWidth
                        sx={{ m: 1 }}
                        variant="contained"
                        onClick={handleLogin}
                      >
                        Login
                      </Button>
                    </div>

                    <div
                      className={
                        isEducator === "parent" ? "itemCenter" : " hide"
                      }
                    >
                      Don't have an account?&nbsp;
                      <Link to="/sign-up">
                        <span className="link">Sign up</span>
                      </Link>
                    </div>

                    <div
                      className={
                        isEducator !== "parent" ? "GoogleButton" : " hide"
                      }
                    >
                      <link
                        rel="stylesheet"
                        type="text/css"
                        href="//fonts.googleapis.com/css?family=Open+Sans"
                      />

                      <div className="googleBtn">
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            dispatch(
                              signInWithGoogle(credentialResponse.credential)
                            ).then(async (res) => {
                              dispatch(
                                showToastMessage(res?.statusCode, res?.message)
                              );
                              dispatch(setSSOLogout(false));
                              if (res?.statusCode === 200) {
                                res.type == "educator"
                                  ? dispatch(getEducatorData()).then((res) => {
                                      if (res?.statusCode === 200) {
                                        if (
                                          res?.visited_welcome_screen === "1"
                                        ) {
                                          navigate("/dashboard/classes", {
                                            replace: true,
                                          });
                                        } else {
                                          navigate("/dashboard/home", {
                                            replace: true,
                                          });
                                          dispatch(setWelcomePage(res?.id));
                                        }
                                      }
                                    })
                                  : dispatch(getStudentData()).then((res) => {
                                      res?.statusCode === 200 &&
                                        navigate("/dashboard/lessons", {
                                          replace: true,
                                        }); //Student nav menu
                                    });
                              }
                            });
                          }}
                          onError={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
              {/* <div
                className={
                  (SSoLogin ? "contactDialog mt-3" : "contactDialog") +
                  (isEducator !== null ? "" : " hide")
                }
              >
                <a
                  href="https://kneoworld.zendesk.com/hc/en-us/requests/new"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="contactTitle">
                    <FaRegQuestionCircle className="iconAlign" /> Contact
                    Support
                  </div>
                </a>
              </div> */}
              <div className="itemCenter mt-1">Build Version - 21.8</div>
            </div>
          </div>
        )}
      </div>
      <CustomToastify />
    </>
  );
}
