import React, { useState, useEffect } from "react";
import {
  Container,
  Stack,
  Typography,
  TextField,
  CardContent,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import "../../styles/main.css";
import "../../styles/components/home.css";
import "../../styles/components/importStudents.css";
import Card from "@material-ui/core/Card";
import TeacherLogo from "../../assests/images/TeacherIcon.png";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { importStudentData } from "../../redux/Student/StudentAction";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { MuiFileInput } from "mui-file-input";
import StudentCsvTemplate from "../../assests/students_import_template.csv";
import Papa from "papaparse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomDialog from "../../utils/CustomDialog";
import { logoutUser } from "../../utils/authUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    collapse: {
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: "#ededed",
    },
  })
);

const ImportStudent = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [data, setData] = useState([]);
  const [schoolId, setSchoolId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [value, setValue] = React.useState(null);
  const [importErrorData, setImportErrorData] = React.useState();
  const [isError, setIsError] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.loginReducer?.userData);
  const [file, setFile] = useState("");
  const [error, setError] = useState("");
  const fileReader = new FileReader();
  const allowedExtensions = ["csv"];

  useEffect(() => {}, []);

  const handleFileChange = (e) => {
    setError("");

    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        // alert("Please input a csv file"); modal
        setMessage("Please input a csv file");
        setIsDialogOpen(true);
        setFile("");
        e.target.value = null;

        return;
      } else {
        setFile(inputFile);
      }

      //   setIsError(true);
      // } else if (res?.statusCode === 200) {
      //   navigate(`/dashboard/classes`);
      // }
      setIsLoading(false);
    }
  };

  // return (
  //   <Card>
  //     <div className="pageHeading">Import Students & Classrooms</div>
  //     <div className="pageBreadCrumb">
  //       <Link to="/dashboard/home">Home</Link> {" > "}
  //       <Link to="/dashboard/classes">My Classes</Link> {" > "}
  //       Import Students & Classrooms
  //     </div>

  //     <div className="importMain">
  //       <div className="csvHeader"> CSV File</div>

  //       <div className="csvLink">
  //         {" "}
  //         <a href={StudentCsvTemplate}>click here to download an example. </a>
  //       </div>
  const handleParse = () => {
    if (!file) {
      // return  alert("Enter a valid file")
      setMessage("Enter a valid file");
      setIsDialogOpen(true);
      return;

      //  alert("Enter a valid file")
    }

    // setMessage("Enter a valid file");
    // setIsDialogOpen(true);

    setIsLoading(true);
    dispatch(importStudentData(file, userData?.school_id)).then(async (res) => {
      dispatch(showToastMessage(res?.statusCode, res?.message));
      if (res?.statusCode !== 200 && res?.issueList?.length == 0) {
        navigate(`/dashboard/classes`);
      } else if (res?.statusCode === 403 || res?.statusCode === 401) {
        logoutUser(dispatch, navigate, userData, res?.statusCode);
      } else {
        setImportErrorData(res?.issueList);
        setIsError(true);
        setStatusCode(res?.statusCode);
      }
      // if (res?.statusCode !== 200) {
      //     setImportErrorData(res?.issueList);

      //     setIsError(true);

      // }
      // else if (res?.statusCode === 200){

      // navigate(`/dashboard/classes`);
      // }
      setIsLoading(false);
    });
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    setIsDialogOpen(false);
  };

  return (
    <Card>
      <div className="pageHeading">Import Students & Classrooms</div>
      <div className="pageBreadCrumb">
        <Link to="/dashboard/home">Home</Link> {" > "}
        <Link to="/dashboard/classes">My Classes</Link> {" > "}
        Import Students & Classrooms
      </div>

      <div className="importMain">
        <div className="csvHeader"> CSV File</div>

        <div className="csvLink">
          {" "}
          <a href={StudentCsvTemplate}>click here to download an example. </a>
        </div>

        <div className="importBlock">
          <input
            onChange={handleFileChange}
            key={""}
            id="csvInput"
            name="file"
            type="File"
          />
        </div>

        <Button className="btn gradientButtonBlue" onClick={handleParse}>
          Import Students
        </Button>
      </div>

      <div className="importErrorTable">
        {isError === true && (
          <>
            {statusCode !== 200 && (
              <div className="importErrorText">
                {" "}
                Please correct these rows and import the file again.
              </div>
            )}
            {importErrorData.length > 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableFont" align="left">
                        Row No.
                      </TableCell>
                      <TableCell className="tableFont" align="left">
                        Name
                      </TableCell>
                      <TableCell className="tableFont" align="left">
                        Lesson
                      </TableCell>
                      <TableCell className="tableFont" align="left">
                        Class
                      </TableCell>
                      <TableCell className="tableFont" align="left">
                        Email
                      </TableCell>
                      <TableCell className="tableFont" align="left">
                        Remarks
                      </TableCell>
                      <TableCell align="left"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {importErrorData?.map((row) => (
                      <TableRow
                        // key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="left">
                          {row?.rowId}
                        </TableCell>
                        <TableCell align="left">
                          {row?.studentsRow?.name}
                        </TableCell>
                        <TableCell align="left">
                          {row?.studentsRow?.lessongroup}
                        </TableCell>
                        <TableCell align="left">
                          {row?.studentsRow?.classroom}
                        </TableCell>
                        <TableCell align="left">
                          {row?.studentsRow?.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: statusCode === 200 ? "green" : "red",
                          }}
                        >
                          {row?.message}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>

      <CustomDialog
        // className="import-MuiDialog-paper"
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Import Student"
        content={message}
        learnosity={true}
      />
    </Card>
  );
};

export default ImportStudent;
