import { Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import MaterialTable from "material-table";
import "../../styles/main.css";
import "../../styles/components/myTeachers.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  getTeacherData,
  deleteTeacherData,
} from "../../redux/Teachers/TeachersAction";
import CustomToastify from "../../utils/Toastify";
import { tableIcons, options } from "../../utils/TableMui";
import {
  getLessonAndResourcesData,
  setCourseName,
  setLessonName,
  setLessonDescription,
} from "../../redux/LessonsResources/LessonsResourcesAction";
import { logoutUser } from "../../utils/authUtils";

const MyLessonsResources = (props) => {
  const [loading, setLoading] = useState(true);
  const [alldata, seAlltData] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const tableRef = useRef();

  const userData = useSelector((state) => state.loginReducer?.userData);

  const courseName = useSelector(
    (state) => state.LessonsResourcesReducer?.courseName
  );

  useEffect(() => {
    dispatch(getLessonAndResourcesData(userData?.school_id)).then(
      async (res) => {
        if (res?.statusCode === 200) {
          seAlltData(res?.lessonAndResourceResponseList);
          let newArray = [];
          let idCounter = 1;

          res.lessonAndResourceResponseList.forEach((course) => {
            const { courseName, course_id, resourcesDTOList } = course;
            const parentId = idCounter++;
            let courseNameString = courseName.replace(/\s+/g, "");

            newArray.push({
              id: --idCounter,
              courseNameT: courseNameString,
              courseName: courseNameString,
              course_id,
              ...resourcesDTOList[0],
            });

            idCounter++;
            for (let i = 1; i < resourcesDTOList.length; i++) {
              newArray.push({
                id: idCounter++,
                courseNameT: courseNameString,
                courseName: courseNameString,
                course_id,
                parentId,
                ...resourcesDTOList[i],
              });
            }
          });

          setData(newArray);
          setLoading(false);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      }
    );
  }, []);

  const customFilterAndSearch = (term, rowData) => {
    let termArray = [];
    const terms = term.toLowerCase();
    termArray.push(terms);
   
    return termArray.some(
      (t) =>
        rowData.lessonGroup.toLowerCase().includes(t) ||
        rowData.description.toLowerCase().includes(t)
    );
  };


  const customUnitIdentifierFilterAndSearch = (term, rowData) => {
    // Split the search term into words
    let termArray = [];
    const terms = term.toLowerCase();
    termArray.push(terms);
   
    // Check if any of the words in the term matches lessonGroup or description
    return termArray.some(
      (t) =>
        rowData.lessonIdentifier.toLowerCase().includes(t) 
        
    );
  };

  const columns = [
    {
      title: "Courses",
      field: "courseNameT",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.courseNameT}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: data?.length > 0,
    },
    {
      title: "Unit",
      field: "lessonGroup",
      render: (rowData) => (
        <div>
          <div>
            {rowData.lessonGroup}
          </div>
          {/* <div style={{ marginLeft: 5 }}>{rowData.description}</div> */}
        </div>
      ),
      sorting: data?.length > 0,
      customFilterAndSearch: (term, rowData) =>
      customFilterAndSearch(term, rowData),
    },
    // {
    //   title: "Unit Identifier",
    //   field: "lessonIdentifier",
    //   render: (rowData) => (
    //     <div>
    //       <div style={{ marginLeft: 5 }}>{rowData.lessonIdentifier}</div>
    //     </div>
    //   ),
    //   sorting: data?.length > 0,
    //  customFilterAndSearch: (term, rowData) => customUnitIdentifierFilterAndSearch(term, rowData),
    // },
    {
      title: "Lessons",
      field: "noOfLessons",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.noOfLessons}</span>
        </div>
      ),
      headerStyle: { width: "20%" },
      cellStyle: { width: "20%" },
      sorting: false,
      filtering: false,
      searching: true
    },
    {
      title: "Students Assigned",
      field: "noOfStudents",
      render: (rowData) => (
        <div>
          <span style={{ marginLeft: 5 }}>{rowData.noOfStudents}</span>
        </div>
      ),
      sorting: data?.length > 0,
      searching: true,
      filtering: false,
    },

    {
      title: "Actions",
      render: (rowData) => (
        <div>
          {/* <Link
            to={`/dashboard/courses/${rowData?.course_id}/lesson_groups/${rowData?.lesson_id}`}
          >  */}
          <Button
            className="btn gradientButtonBlue btnTable"
            onClick={() => handleExplore(rowData)}
          >
            Explore
          </Button>
          {/* </Link>  */}
        </div>
      ),
      cellStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
        width: "10%",
      },
      headerStyle: {
        padding: "0",
        textAlign: "center",
        justifyContent: "center",
        width: "10%",
      },
      width: 100,
      maxWidth: 100,
      sorting: false,
    },
  ];

  const handleExplore = (data) => {
    dispatch(setCourseName(data.courseName));
    dispatch(setLessonName(data.lessonGroup));
    dispatch(setLessonDescription(data.description));

    navigate(
      `/dashboard/courses/${data?.course_id}/lesson_groups/${data?.lesson_id}`
    );
  };

  const tableTitle = (
    <div className="TableTitle ">No. of courses : {alldata.length}</div>
  );

  const parentChildData = (row, rows) => {
    if (!row.parentId) {
      return null; // No parent row exists
    }
    const parentRow = rows.find((a) => a.id === row.parentId);
    if (parentRow) {
      return parentChildData(parentRow, rows); // Recursive call to find the root parent row
    } else {
      return row; // Found the root parent row
    }
  };

  const handlePageChange = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Card sx={{ m: 1, p: 4 }}>
        {loading && (
          <div className="loaderContainer">
            <div className="spinner"></div>
          </div>
        )}
        <div ref={tableRef}></div>
        <div className="itemSpace">
          <div className="pageHeading">My Lessons & Resources</div>
          {/* <div className="itemEnd m1">
            <Link to={`/dashboard/schools/${userData?.school_id}/teachers/new`}>
              <Button className="btn gradientButtonPink">My Lessons & Resources</Button>
            </Link>
          </div> */}
        </div>
        <div className="pageBreadCrumb">
          <Link to="/dashboard/home">Home</Link> {" > "}
          My Lessons & Resources
        </div>
        <div className="MuiTable">
          <MaterialTable
            title={tableTitle}
            className="myTable"
            icons={tableIcons}
            data={data}
            columns={columns}
            options={{
              ...options,
              pageSize: 20,
            }}
            onChangePage={handlePageChange}
          />
        </div>
        <CustomToastify />
      </Card>
    </>
  );
};

export default MyLessonsResources;
