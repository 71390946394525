import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { getStudentLessonsData } from "../../redux/Lessons/LessonsAction";
import "../../styles/components/Adventures.css";
import "./../../styles/main.css";
import { FaPlay, FaHeadSideVirus, FaRedo, FaLock } from "react-icons/fa";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { setStudentPlay } from "../../redux/Lessons/LessonsAction";
import { logoutUser } from "../../utils/authUtils";

const StudentLesson = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [episodeData, setEpisodeData] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [barData, setBarData] = useState([]);
  const dispatch = useDispatch();
  const [lockLesson, setLockLesson] = React.useState(0);
  const userData = useSelector((state) => state.loginReducer?.userData);

  useEffect(() => {
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      pageTitle.textContent = "KneoWorld";
    }
    dispatch(getStudentLessonsData(userData?.school_id, userData?.id)).then(
      async (res) => {
        if (res?.episodesResponse?.statusCode === 200) {
          setEpisodeData(res?.episodesResponse?.episodesDataList);
          setProgressData(res?.sceneResponseList);
          setBarData(res?.progressResponseList);
          setAllData(res);
          setLoading(false);
          setLockLesson(res?.flag);
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        }
      }
    );
  }, []);

  const handlePreview = (episodeId, resume) => {
    dispatch(setStudentPlay(true));
    const rowData = progressData.find((p) => p.episodeId === episodeId);
    if (resume && rowData?.noOfScenes - rowData?.noOfCompletedScenes !== 0) {
      navigate(
        `/lessons/${episodeId}/scenes?currentIndex=${rowData?.noOfCompletedScenes}`
      );
    } else {
      navigate(`/lessons/${episodeId}/scenes`);
    }
  };

  return (
    <Card>
      {loading ? (
        <div className="loaderContainer">
          <div className="spinner"></div>
        </div>
      ) :
        <>
          <div className="itemSpace">
            <div className="pageHeading">Lessons</div>
          </div>
          <div className="pageBreadCrumb">Lessons</div>

          {lockLesson === 0 && (
            <div className="ml-3">
              Your pre test of this unit is not completed. Upon completion of the
              pre test by your teacher, the lessons will be available to you.
            </div>
          )}

          {episodeData?.length > 0 ? episodeData?.map((item, index) => {
            return (
              <>
                <div className="adventuresMain ml-3  mt-2 mb-1">
                  <div className="adventuresLeft">
                    <img
                      src={item.url}
                      alt="Adventure Logo"
                      className="adventureLogo episodeCardImage"
                    />
                  </div>

                  <div className="adventuresRight">
                    <div className="adventuresHeading">
                      <FaHeadSideVirus className="brainIcon" /> {item.episodeName}
                    </div>
                    {lockLesson === 0 ? (
                      <div className="playBtn">
                        <div>
                          <FaLock className="btn-icon-play  yellowStartButton" />{" "}
                        </div>
                        <div className="barTopMargin"></div>
                      </div>
                    ) : (
                      <div className="playBtn">
                        {(progressData.find((p) => p.episodeId === item.episodeId)
                          ?.noOfCompletedScenes /
                          progressData.find((p) => p.episodeId === item.episodeId)
                            ?.noOfScenes) *
                          100 ===
                          0 && (
                            <div
                              onClick={() => handlePreview(item?.episodeId, false)}
                            >
                              <FaPlay className="btn-icon-play  greenStartButton" />
                              <div className="btnLabel itemCenter">Start</div>
                            </div>
                          )}
                        {(progressData.find((p) => p.episodeId === item.episodeId)
                          ?.noOfCompletedScenes /
                          progressData.find((p) => p.episodeId === item.episodeId)
                            ?.noOfScenes) *
                          100 >
                          0 &&
                          (progressData.find((p) => p.episodeId === item.episodeId)
                            ?.noOfCompletedScenes /
                            progressData.find((p) => p.episodeId === item.episodeId)
                              ?.noOfScenes) *
                          100 <
                          100 && (
                            <div style={{ display: "flex" }}>
                              <div
                                style={{ display: "block" }}
                                className="mr-1"
                                onClick={() => handlePreview(item?.episodeId, true)}
                              >
                                <FaPlay className="btn-icon-play  yellowStartButton " />
                                <div className="btnLabel itemCenter">Start</div>
                              </div>
                              <div
                                style={{ display: "block" }}
                                onClick={() =>
                                  handlePreview(item?.episodeId, false)
                                }
                              >
                                <FaRedo className="btn-icon-play restartButton" />
                                <div className="btnLabel itemCenter">Restart</div>
                              </div>
                            </div>
                          )}
                        {(progressData.find((p) => p.episodeId === item.episodeId)
                          ?.noOfCompletedScenes /
                          progressData.find((p) => p.episodeId === item.episodeId)
                            ?.noOfScenes) *
                          100 ===
                          100 && (
                            <div
                              onClick={() => handlePreview(item?.episodeId, false)}
                            >
                              <FaRedo className="btn-icon-play  restartButton" />
                              <div className="btnLabel itemCenter">Restart</div>
                            </div>
                          )}
                      </div>
                    )}

                    {barData?.map((row, index) => {
                      return (
                        item?.episodeId === row?.episodeId && (
                          <>
                            <div className="progressListDiv">
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{ width: "100%" }}>
                                  <LinearProgress
                                    className="progressLine"
                                    variant="determinate"
                                    value={row.completePercent} // Calculate the progress percentage
                                  />
                                </Box>
                                {/* <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2" color="text.secondary">
                            {`${Math.round((row.noOfCompletedScenes / row.noOfScenes) * 100)}%`}
                          </Typography>
                        </Box> */}
                              </Box>
                            </div>
                          </>
                        )
                      );
                    })}
                  </div>
                </div>
              </>
            );
          }) :
            <div className="m2 noLessons">No lessons are assigned to this student.</div>}
        </>}
      <CustomToastify />
    </Card>
  );
};

export default StudentLesson;
