export const gamesData = [
  {
    gameLevel: "Critical Thinking Level 1 - Beginner",
    games: [
      { gameName: "Rocket Puzzle" },
      { gameName: "Shifting Shapes" },
      { gameName: "Flashlight" },
      { gameName: "Hide and Seek" },
      { gameName: "Square Repair" },
    ],
  },
  {
    gameLevel: "Critical Thinking Level 2 - Intermediate",
    games: [
      { gameName: "Sum Fun" },
      { gameName: "Evens And Odds" },
      { gameName: "Rune Tunes" },
      { gameName: "Rune Stones" },
      { gameName: "Mirror Master" },
    ],
  },
  {
    gameLevel: "Critical Thinking Level 3 - Difficult",
    games: [
      { gameName: "Crosswords" },
      { gameName: "Petal Pointers" },
      { gameName: "Spaced Out" },
      { gameName: "More Or Less" },
      { gameName: "Temple Tiles" },
    ],
  },
];
