import React, { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LogoImg from "../../assests/images/logo.png";
import { Link } from "react-router-dom";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import "../../styles/layouts/login.css";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  sendEmailForgotPassword,
  setIsEducator,
  sendParentEmailForgotPassword
} from "../../redux/Login/LoginAction";
import CustomSnackBar from "../../utils/Snackbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomToastify from "../../utils/Toastify";
import { showToastMessage } from "../../redux/Toaster/ToasterAction";
import { logoutUser } from "../../utils/authUtils";

export default function ForgotPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(false);
  const [touched, setTouched] = useState(false);

  const userData = useSelector((state) => state.loginReducer?.userData);

  const validateForm = () => {
    let errors = {};
    let requireFields = [];
    let requireValues = [];
    requireValues = [email];
    requireFields = ["email"];

    requireValues.forEach((field, index) => {
      if (!field.trim()) {
        errors[requireFields[index]] = "This field cannot be blank";
      }
      if (!(field !== null && field !== "" && field.length !== 0)) {
        errors[requireFields[index]] = "This field is required";
      }

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (email?.length !== 0 && email?.length > 50) {
        errors["email"] = "Email is too long (maximum is 50 characters)";
      } else if (
        email?.length !== 0 &&
        email !== null &&
        email !== "" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        // !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)
      ) {
        errors["email"] = "Please enter a valid email address";
      }
    });

    setErrors(errors);

    return Object.keys(errors).length > 0 ? true : false;
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();

    if (validateForm()) return;
    setIsLoading(true);

    const url = window.location.href;
    if (url.includes("users")) {
      dispatch(sendEmailForgotPassword(email)).then(async (res) => {
        setIsLoading(false);

        if (res?.statusCode === 200) {
          navigate("/login");
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        } else {
          setEmail("");
          setPassword("");
          setTouched(false);

          setErrorOpen(true);
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));
        setEmail("");
      });
    } else if (url.includes("parent")) {
      dispatch(sendParentEmailForgotPassword(email)).then(async (res) => {
        setIsLoading(false);

        if (res?.statusCode === 200) {
          navigate("/login");
        } else if (res?.statusCode === 403 || res?.statusCode === 401) {
          logoutUser(dispatch, navigate, userData, res?.statusCode);
        } else {
          setEmail("");
          setPassword("");
          setTouched(false);

          setErrorOpen(true);
        }
        dispatch(showToastMessage(res?.statusCode, res?.message));
        setEmail("");
      });
    }
  };

  useEffect(() => {
    dispatch(setIsEducator(true));
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <div>
        {isLoading === true && (
          <div className="loaderContainer">
            <div className="spinner loginSpin"></div>
          </div>
        )}

        <div className="loginBg logoTeacher"></div>
        <div className="loginCard">
          <div className="loginDialog">
            <div className="itemCenter">
              <img className="loginLogo" src={LogoImg} alt="KneoWorld-Logo" />
            </div>
            <div className="loginTitle">Forgot Password</div>

            <>
              <div className="itemGridCenter">
                <TextField
                  fullWidth
                  sx={{
                    m: 1,
                    "& input": {
                      fontFamily: "Raleway !important",
                    },
                  }}
                  required
                  id="outlined-required"
                  label="Email"
                  size="small"
                  value={email}
                  onChange={handleEmailChange}
                  className="mb-0"
                />
              </div>
            </>
            {errors.email && <div className="error mb-0">{errors.email}</div>}

            <div className="itemSpace">
              <div className="mr-1">
                <Button
                  className="btn gradientButtonBlue  mr-1 lh1"
                  fullWidth
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleForgotPassword}
                >
                  RESET Password
                </Button>
              </div>
              <div>
                <Link to={`/login`}>
                  <Button
                    className="btn gradientButtonPink lh1"
                    fullWidth
                    sx={{ m: 1 }}
                    variant="contained"
                  >
                    Back To Login
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomToastify />
    </>
  );
}
