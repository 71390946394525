var location = window.location.host;
var BASE_URL;
var FE_URL;

// if (location == "localhost:3000") {
// BASE_URL = "http://bs-local.com:8089";
// FE_URL = "http://localhost:3000";
// }

//    BASE_URL = "https://stage-api.kneoworld.com"
//    FE_URL = "https://stage-ny-app.kneoworld.com";

// BASE_URL = "https://ny-api.kneoworld.com";
// FE_URL = "https://nys.kneoworld.com";

BASE_URL = "https://uat-api.kneoworld.ph";
FE_URL = "https://uat.kneoworld.com";

// BASE_URL = "https://fl-api.kneoworld.com";
// FE_URL = "https://fl.kneoworld.com";

export { BASE_URL, FE_URL };
